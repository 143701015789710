<template>
  <k-auth title="Forgot Password?">
   <template v-slot:description>
      <p>
        Please type in the email address you used to register.
      </p>
    </template>
    <form class="form__items" @submit.prevent="sendResetLink">
      <k-input
       label="Email Address"
       type="email" name="email"
       :error="error.email"
       v-model="user.email" />
      <k-button
       :loading="isLoading" size="full" submit>Submit</k-button>
    </form>
      <k-button @click="$router.push({ name: 'Login' })"
      size="full"
      variant="link">
      Go back to Sign In</k-button>
  </k-auth>
</template>

<script>
import ForgotPassword from './ForgotPassword';

export default ForgotPassword;
</script>

<style lang="scss" scoped></style>
