import DatasetResult from '@/mixins/DatasetResult';
import indicatorList from '@/mixins/IndicatorList';
import CompareMixin from '@/mixins/CompareMixin';
import Analysis from '@/mixins/Analysis';
import GetLatestWithValue from '@/mixins/GetLatestWithValue';
// import { downloadCsv } from '@/api/dataset';

export default {
  name: 'CompareData',
  mixins: [DatasetResult, indicatorList, CompareMixin, Analysis, GetLatestWithValue],
};
