<template>
  <k-auth title="Sign In.">
    <template v-slot:description>
      <p>
        Dont have an account?
        <span><router-link :to="`/signup?next=${next}`">Please, sign up,</router-link></span>
      </p>
      <transition name="grow">
        <div v-if="general_error" class="error-message">{{ general_error }}</div>
      </transition>
    </template>
    <form class="form__items" @submit.prevent="logUserIn()" ref="form">
      <k-input
        v-model="user.email"
        :error="error.email" label="Your email address"
        name="email"
        type="email"/>
      <k-input
        v-model="user.password"
        :error="error.password"
        label="Password"
        name="password"
        type="password"
        variant="password"
      ></k-input>
      <k-button :disabled="formInvalid" :loading="isLoading" size="full" submit>Sign In</k-button>
    </form>
    <k-button size="full" submit variant="link" @click="$router.push({ name: 'ForgotPassword' })">
      Forgot Password?
    </k-button>
  </k-auth>
</template>

<script>
import Login from './Login';

export default Login;
</script>

<style lang="scss" scoped src='./Login.scss'></style>
