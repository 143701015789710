var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'btn',
    ( _obj = {
      'btn--full': _vm.full,
      'btn--short': _vm.short,
      'btn--mobile': _vm.mobile
   }, _obj[("btn--" + _vm.variant)] = _vm.variant, _obj[("btn--" + _vm.size)] = _vm.size, _obj['btn--negative'] = _vm.negative, _obj['btn--sec--negative'] =  _vm.negative && (_vm.variant === 'secondary' || _vm.variant === 'tertiary'), _obj )
  ],attrs:{"type":_vm.submit ? 'submit' : 'button',"disabled":_vm.disabled || _vm.loading || _vm.uploading},on:{"click":_vm.handleClick}},[_vm._t("default"),(_vm.loading)?_c('span',{staticClass:"btn__loader"}):_vm._e(),(_vm.uploading)?_c('span',{staticClass:"btn__uploader"}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }