import { fetchCountrySummary, search } from '@/api';
import { KButton, KInput } from '@/components';

// helpers
import dummyDatabase from '@/utils/dummy-database';
import stringHelpers from '@/utils/string-helpers';
import scrollToTop from '@/utils/scrollToTop';
import { updateClicked } from '@/api/dataset';
import searchSuggestions from '@/utils/searchSuggestions';

export default {
  name: 'Results',
  components: {
    KButton,
    KInput,
  },
  data: () => ({
    database: dummyDatabase,
    value: '',
    relatedResults: [],
    relevantResults: [],
    relatedSearches: [],
    isSearching: false,
    suggestions: [],
    searchId: '',
    loading: true,
    dashboard: {},
  }),
  mounted() {
    this.setValue();
    // this.mockFetch();
    this.fetchResults();
  },
  watch: {
    $route(val) {
      this.search(decodeURIComponent(val.query.q));
    },
    value(val) {
      searchSuggestions.call(this, val);
    },
  },
  computed: {
    sortedSuggestions() {
      const suggestions = this.suggestions.map((s) => s.searchTerm);

      return suggestions.slice(0, 5);
    },
  },
  methods: {
    ...stringHelpers,
    async fetchResults() {
      try {
        this.suggestions = [];
        this.loading = true;
        const response = await search({ q: this.value });
        const { data } = response;
        if (data.error) throw Error(data.error);
        const {
          relatedResults,
          relevantResults,
          relatedSearches: related,
        } = data.data.searchResult;
        // if (this.$refs.searchBtn) this.$refs.searchBtn.focus();
        this.searchId = data.data.searchId;
        this.relatedResults = this.getUniqueResult(relatedResults);
        this.relevantResults = this.getUniqueResult(relevantResults);
        const relatedKeywords = {};
        related.forEach(({ searchTerm }) => {
          relatedKeywords[searchTerm] = (relatedKeywords[searchTerm] || 0) + 1;
        });
        this.relatedSearches = Object.entries(relatedKeywords)
          .sort((a, b) => b[1] - a[1])
          .slice(0, 10);

        const noRelevant = !this.relevantResults.length;
        const noRelated = !this.relatedResults.length;

        // fetch country dashboard if exists
        let country;
        if (!noRelevant) {
          country = this.relevantResults[0].country;
        } else if (!noRelated) {
          country = this.relatedResults[0].country;
        } else {
          country = '';
        }

        if (country) {
          const summaryRes = await fetchCountrySummary({ country });
          if (summaryRes.data.data.length) {
            const [dashboard] = summaryRes.data.data;
            this.dashboard = dashboard;
          } else {
            this.dashboard = {};
          }
        } else {
          this.dashboard = {};
        }
      } catch (e) {
        console.log(e.response?.data.message || 'Something unexpected occurred on the server');
        this.$toast.show({
          message: 'Something unexpected occurred on the server',
        });
      } finally {
        this.loading = false;
        scrollToTop();
      }
    },
    async listenForClicks() {
      const { searchId } = this;
      try {
        const response = await updateClicked({ searchId, body: { clicked: 'TRUE' } });
        if (response.status !== 200) {
          throw Error(response.error);
        }
      } catch (error) {
        console.log(error);
      }
    },
    setValue() {
      this.value = decodeURIComponent(this.$route.query.q);
    },
    datasetId(res) {
      return res.datasetId || res.dataset_id;
    },
    getUniqueResult(results) {
      const uniqueResults = results.reduce((total, cur) => {
        const newTotal = { ...total };
        newTotal[cur.datasetId || cur.dataset_id] = cur;
        return newTotal;
      }, {});
      return Object.values(uniqueResults);
    },
    search(searchValue = null) {
      if (searchValue) this.value = searchValue;
      const { $route, $router, value } = this;

      if (value && value.trim()) {
        this.fetchResults();
      }
      // if value is not equal query value, then change
      if (decodeURIComponent($route.query.q) !== this.value) {
        const query = { query: { q: encodeURIComponent(value.trim()) } };
        if (this.value) {
          this.$router.push({ ...$router, ...query });
        }
      }
    },
  },
};
