<template>
  <k-modal :open="show" @close="show = false">
    <k-card heading="" variant="in-modal">
      <div class="img-container">
        <img alt="" src="@/assets/attention.svg" />
      </div>
      <h1>You don't have an account</h1>
      <p>Please sign in to continue</p>
      <k-button
        size="full"
        @click="
          $router.push({ name: 'Login', query: { next } });
          show = false;
        "
      >
        Sign in
      </k-button>
      <k-button size="full" variant="link" @click="show = false">Cancel</k-button>
    </k-card>
  </k-modal>
</template>

<script>
import KModal from '../Modal/Modal.vue';
import KCard from '../Card/Card.vue';
import KButton from '../Button/Button.vue';
import EventBus from '@/utils/event-bus';
import store from '@/store';

export default {
  name: 'CheckAuth',
  components: { KButton, KModal, KCard },
  data: () => ({
    show: false,
  }),
  created() {
    EventBus.$on('showAuthPrompt', () => {
      this.show = true;
    });
    EventBus.$on('clear', () => {
      store.commit('auth/RESET');
    });
  },
  computed: {
    next() {
      const { location } = window;
      return location.pathname + location.search;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 3.2rem;
  margin: 4rem auto;
  max-width: 56.6rem;

  @media (min-width: 725px) {
    padding: 6.4rem;
  }

  .img-container {
    padding: 2.5rem;
    border-radius: 1.6rem;
    box-shadow: 0.2rem 0.2rem 2.5rem rgba(#808080, 0.15);
    width: max-content;
    margin-bottom: 4rem;

    img {
      width: 4.7rem;
      height: 4.7rem;
    }
  }
}

h1 {
  margin-bottom: 1.6rem;
  font-size: 2.5rem;
  line-height: 2.9rem;
}

p {
  margin-bottom: 4rem;
}
</style>
