<template>
  <div class='blog'>
    <div class='container blog__content'>
      <template v-if='isLoading'>
        <div class='blog__header'>
          <div class='suspense mh-30 w-70 mb-5'></div>
          <div class='suspense mh-30 w-70'></div>
          <div class='subtitle'>
            <div class='suspense mh-30 w-30'></div>
          </div>
          <div class='profile--content'>
            <div class='profile suspense'></div>
            <div class='name suspense fw-15 mh-20'></div>
          </div>
        </div>
        <div class='section'>
          <div class='section--content'>
            <div class='blog-picture-wrapper suspense'></div>
            <div class='text-content'>
              <div class='suspense mh-20'></div>
              <div class='suspense mh-20'></div>
              <div class='suspense mh-20'></div>
              <div class='suspense mh-20 w-50'></div>
            </div>
          </div>
        </div>
      </template>
      <template  v-else>
        <header class='blog__header'>
          <h1>
            {{ post.name }}
          </h1>
          <p class='subtitle'>
            {{ formatDate(post.created_at) }}
          </p>
          <div class='profile--content'>
            <div class='profile'>
              <img :src='post.content.author ? post.content.author.image.filename : null' alt=''
                   class='profile-picture' />
            </div>
            <p class='name'>{{ post.content.author ? post.content.author.name : 'Anonymous' }}</p>
          </div>
        </header>
        <section class='section'>
          <div class='section__content'>
            <div class='blog-picture-wrapper' v-if='post.content'>
              <img
                :src='post.content.image'
                alt='' />
            </div>
            <div class='text-content' ref='content' v-html='post.body.outerHTML'>
            </div>
          </div>
        </section>
      </template>
    </div>
    <k-footer></k-footer>
  </div>
</template>

<script>
import SingleBlog from './SingleBlog';

export default SingleBlog;
</script>

<style lang='scss' scoped src='./SingleBlog.scss'></style>
