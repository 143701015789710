import Vue from 'vue';
import VueHead from 'vue-head';
import Vuelidate from 'vuelidate';
import VueCarousel from 'vue-carousel';
import App from './App.vue';
import router from './router';
import store from './store';
import ToastNotification from './plugins/toast';
import CheckAuth from './plugins/checkAuth';
import debounce from '@/utils/debounce';

Vue.config.productionTip = false;
Vue.prototype.debounce = debounce;

Vue.use(ToastNotification);
Vue.use(CheckAuth);
Vue.use(VueHead);
Vue.use(Vuelidate);
Vue.use(VueCarousel);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
