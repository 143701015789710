import numberOr from '@/utils/numberOr';

export const getYear = (val) => {
  if (val === 0 || val) {
    const match = val
      .toString()
      .trim()
      .match(/(?<year>\d{1,4})$/);
    if (!match) return val;
    const toInt = Number(match.groups.year);
    if (toInt > 1000) return toInt;
    const year = new Date().getFullYear() % 2000;
    if (toInt <= year) {
      return 2000 + toInt;
    }
    return 1900 + toInt;
  }
  return val;
};

const style = `<style>
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600;700&display=swap');
 body {
     padding: 0;
     margin: 0;
     box-sizing: border-box;
     font-family: "Public sans", sans-serif;
   }
   .container {
     width: 100%;
     margin: auto;
     /*padding: 36px;*/
   }
   .header {
     font-weight: 700;
     font-size: 25px;
   }
   .title {
     margin-top: 64px;
     margin-bottom: 24px;
     font-weight: 400;
     font-size: 10px;
   }
   .title--author {
    margin-top: 32px;
   }
   .authors-note-description {
     font-weight: 500;
     font-size: 14px;
   }
   table {
     max-width: 341px;
   }
   table,
   tr,
   td,
   th {
     border-collapse: collapse;
     width: 100%;
   }

    td:first-child,
    th:first-child {
      width: 150px;
    }

   tr{
    display: flex;
   }

   tr > td:first-child {
    flex-basis: 200px;
   }

   thead {
     border-bottom: 0.5px solid black;
     display: flex;
     line-height: 1.4rem;
   }
   .table-container {
     display: flex;
     grid: 1fr / repeat(2, 1fr);
     gap: 64px;
   }
   .table-row-head {
     font-weight: 700;
     font-size: 14px;
     display: flex;
     justify-content: space-between;
   }
   .table-row-head > th {
     padding: 16px 0;
     text-transform: capitalize;
   }
   tbody,
   thead {
     width: 100%;
     /* border: 1px solid red; */
   }
   .table-row-body {
     display: flex;
     width: 100%;
     justify-content: space-between;
   }
   .table-row-body > td {
     padding-top: 16px;
   }
   tbody > tr {
     font-weight: 400;
     font-size: 14px;
     padding-top: 16px;
   }
   .chart {
    margin: 2rem 0 1.6rem;
    // width: 100%;
    display: flex;
    position: relative;
    // display: grid;
    // grid: 1fr / 2rem auto;
  }
    .chart.compare {
      grid: 1fr/ 2rem auto 2rem;
    }
    .chart.compare.metric:nth-of-type(1) {
      left: -74px;
    }
    .chart img {
      width: 85%;
      margin: auto auto 2em;
    }

img {
  // width: 100%;
  width: 85%;
  margin: auto auto 2em;
}


  .metric {
    font-weight: 300;
    font-size: 12px;
    top: 40%;
    transform: rotateZ(-90deg);
    position: relative;
    height: 2.4rem;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .metric:nth-of-type(1) {
    left: -104px;
  }
  .metric:nth-of-type(2) {
    // transform: rotateZ(90deg);
    transform: rotateZ(-270deg);
    right: -74px;
  }

  .metric > div {
    font-size: 1.2rem;
  }

  .metric span {
    text-transform: capitalize;
  }
  
   .chart-footnote {
     font-weight: 400;
     font-size: 10px;
     line-height: 130%;
     color: #000000;
     margin: 16px 0 64px 0;
   }
   .data-analysis {
     width: 100%;
     font-size: 14px;
     line-height: 20px;
     color: #000000;
   }
   .dont-break-inside {
      break-inside: avoid;
      page-break-inside: avoid;
   }
 </style>`;

const head = `<head>
 <meta charset='UTF-8' />
 <meta http-equiv='X-UA-Compatible' content='IE=edge' />
 <meta name='viewport' content='width=device-width, initial-scale=1.0' />
 <link rel='preconnect' href='https://fonts.googleapis.com' />
 <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
 <link
   href='https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800&display=swap'
   rel='stylesheet'
 />
 <title>Pdf</title>
 ${style}
</head>`;

export function pdfTemplate(options) {
  try {
    const {
      showTable,
      showNotes,
      showChart,
      indicator,
      metric,
      currency,
      data,
      title,
      notes,
      image,
      source,
      analysis,
      startYear,
      endYear,
    } = options;
    const valueHeading = `${indicator} (in ${metric} ${currency || ''})`;

    let dataset2 = null;
    if (options.dataset2) dataset2 = options.dataset2;
    const valueHeading2 = dataset2
      ? `${dataset2.indicator} (in ${dataset2.metric} ${dataset2.currency || ''})`
      : '';

    const dataRows = data
      .map(({ period, value }) => ({
        period,
        value: numberOr('N/A')(value),
      }))
      .filter((item) => {
        const { period } = item;
        const intPeriod = getYear(period);
        return intPeriod >= getYear(startYear) && intPeriod <= getYear(endYear);
      });

    const dataRows2 = dataset2
      ? dataset2.data
          .map(({ period, value }) => ({
            period,
            value: numberOr('N/A')(value),
          }))
          .filter((item) => {
            const { period } = item;
            const intPeriod = getYear(period);
            return (
              intPeriod >= getYear(dataset2.startYear) && intPeriod <= getYear(dataset2.endYear)
            );
          })
      : [];

    const rows = dataRows.map(
      (item) =>
        `<tr className='table-row-body'>
          <td>${item.period}</td>
          <td style='text-align: right;'>${item.value?.toLocaleString()}</td>
        </tr>`
    );

    const rows2 = dataRows2.map(
      (item) =>
        `<tr className='table-row-body'>
          <td>${item.period}</td>
          <td style='text-align: right;'>${item.value.toLocaleString()}</td>
        </tr>`
    );

    const tableTemplate = `
     <p class='title'>TABLE</p>
     <div class='table-container'>
     <table>
       <thead>
         <tr class='table-row-head'>
           <th style='text-align: left;'>Period</th>
           <th style='text-align: right;'>${valueHeading}</th>
         </tr>
       </thead>
       <tbody>
        ${rows.join('\n')}
       </tbody>
     </table>

     ${
       dataset2
         ? `
       <table>
         <thead>
           <tr class='table-row-head'>
             <th style='text-align: left;'>Period</th>
             <th style='text-align: right;'>${valueHeading2}</th>
           </tr>
         </thead>
         <tbody>
          ${rows2.join('\n')}
         </tbody>
       </table>
        `
         : ''
     }
     </div>
  `;

    const analysisTemplate = `
      <p class='title'>DATA ANALYSIS</p>
      <p class='data-analysis'>
        ${analysis}
      </p>
      ${
        dataset2
          ? `<p class='data-analysis'>
        ${dataset2.analysis}
      </p>`
          : ''
      }
    `;

    const chartTemplate = `
      <p class='title'>CHART</p>
      <div class='chart ${dataset2 ? 'compare' : ''}' style='break-inside: avoid'>
        <div class='metric'>
          ${metric} ${currency ? `(${currency})` : ''}
        </div>
        <img src='${image}' alt='' />
        ${
          dataset2
            ? `<div class='metric'>
        ${dataset2.metric} ${dataset2.currency ? `(${dataset2.currency})` : ''}
      </div>`
            : ''
        }
    </div>
      <p class='chart-footnote'>
        Source: ${source}
      </p>`;

    return `
      ${head}
      <body>
         <div class='container'>
           <h2 class='header'>${title}</h2>
           ${
             notes
               ? `
              <p class='title title--author'>AUTHOR’S NOTES</p>
              <p class='authors-note-description'>
              ${notes}
              </p>
            `
               : ''
           }
           ${(showTable && tableTemplate) || ''}
         </div>

         ${
           showChart || showNotes
             ? `
            <div class='container dont-break-inside' style='break-inside: avoid'>
           ${(showChart && chartTemplate) || ''}
           ${(showNotes && analysisTemplate) || ''}
        </div>`
             : ''
         }
      </body>
    `;
  } catch (e) {
    console.log(e);
    return e;
  }
}

export function pngTemplate(options) {
  // const pngStyle = `
  // html {
  //   font-size: 20px;
  // }
  //
  // body {
  //   padding: 0;
  //   margin: 0;
  //   box-sizing: border-box;
  //   font-family: "Public sans", sans-serif;
  //   font-size: 18px;
  //   width: 1720px;
  //   height: 963px;
  //   background: white;
  // }
  //
  // h1 {
  //  font-size: 64px;
  // }
  //
  // .container {
  //   padding: 80px;
  // }
  //
  // .title {
  //   font-weight: bold;
  //   font-size: 2.1rem;
  //   line-height: 2.5rem;
  // }
  //
  // .notes {
  //   color: rgba(0,0,0,0.5);
  //   font-size: 1rem;
  //   line-height: 130%;
  //   margin-top: 0.8rem;
  // }
  //
  // .source {
  //   font-size: 18px;
  //   line-height: 130%;
  //   margin-top: 1.6rem;
  // }
  //
  // .chart {
  //   margin: 4.2rem 0 1.6rem;
  //   width: 100%;
  //   position: relative;
  //   display: grid;
  //   grid: 1fr / 1.25rem auto;
  // }
  //
  // .chart.compare {
  //   grid: 1fr/ 1.25rem auto 1.25rem;
  // }
  //
  // img {
  //   width: 100%;
  // }
  //
  // .metric {
  //   font-weight: 300;
  //   top: 50%;
  //   transform: rotateZ(-90deg);
  //   position: relative;
  //   height: 2.4rem;
  //   white-space: nowrap;
  // }
  // .metric:nth-of-type(2) {
  //   transform: rotateZ(90deg);
  //   top: 40%;
  // }`;

  const { title, notes, image, metric, currency, source, position } = options;

  let dataset2 = null;
  if (options.dataset2) {
    dataset2 = options.dataset2;
  }

  //   `
  //     <!DOCTYPE html>
  // <html lang="en">
  // <head>
  //   <meta charset="UTF-8">
  //   <meta http-equiv="X-UA-Compatible" content="IE=edge">
  //   <meta name="viewport" content="width=device-width, initial-scale=1.0">
  //   <link rel='preconnect' href='https://fonts.googleapis.com' />
  //   <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
  //   <link href='https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800&display=swap'
  //     rel='stylesheet' />
  //   <style>
  //    ${pngStyle}
  //    </style>
  // </head>
  //   `
  const finished = `
  <head>
  <style>
    .png-metric {
      font-weight: 300;
      position: absolute;
      height: 2.4rem;
      width: 24px;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .png-metric:nth-of-type(1) {
      top: 60%;
      transform: rotateZ(-90deg);
      left: 0;
    }

    .png-metric:nth-of-type(2) {
      top: 20%;
      transform: rotateZ(-270deg);
      right: 0;
    }

    .png-img {
      width: 90% !important;
      margin-inline: 4em;
    }

    @media (min-width: 990px) {
      .png-img {
        width: 100%;
      }
    }

    .disclaimer {
      font-weight: 500;
    }
  </style>
  </head>
<div class='body' style="border: solid 1px">
  <div class='png-container' style="">
    <div class='title'>${title}</div>
      ${position === 'top' ? `<div class='notes'>${notes}</div>` : ''}
      <div class='chart ${dataset2 ? 'compare' : ''}' style="display: flex; position: relative; ${
    dataset2 ? 'align-items: center;' : ''
  }">
          <div class='png-metric'>
            <div class="">
                <span>
                  ${title}
                </span>
            </div>
            <div>${metric} ${currency ? `(${currency})` : ''}</div>
          </div>
          <img src='${image}' alt='' class='png-img'/>
          ${
            dataset2
              ? `<div class='png-metric'>
              <div class="">
                <span style="text-transform: capitalize;">
                  ${dataset2.country}
                </span>
                ${dataset2.indicator}
          </div>
          <div>${dataset2.metric} ${dataset2.currency ? `(${dataset2.currency})` : ''}</div>
        </div>`
              : ''
          }
      </div>
      ${position === 'bottom' ? `<div class='notes'>${notes}</div>` : ''}
    <div class='source'>Source: ${source} &middot; Kwerty</div>
    <div class='source disclaimer'>Disclaimer: Graph is not up to scale</div>
  </div>
</div>
</html>
  `;
  return finished;
}

export function dashboardTemplate(options) {
  const { selectedIndicators, selectedCharts, resource, title } = options;

  const templateHead = `  <head>
    <meta charset='UTF-8' />
    <meta name='viewport' content='width=device-width, initial-scale=1.0' />
    <meta http-equiv='X-UA-Compatible' content='ie=edge' />
    <style>
    @import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600;700&display=swap');

    body {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      font-family: "Public Sans", sans-serif;
    }

      .heading {
        font-size: 25px;
        line-height: 29px;
        margin-bottom: 45px;
      }

      .title {
        /*font-weight: 600;*/
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 15px;
      }

      .selected-indicators {
        break-after: page;
        border-radius: 8px;
        display: grid;
        grid: auto-flow max-content / repeat(3, 1fr);
        gap: 40px 24px;
        transition: 300ms ease-out;
        justify-content: space-between;
      }

      .item {
        display: flex;
        flex-flow: column;
      }

      .item .value {
        font-weight: 600;
        font-size: 18.2778px;
        line-height: 21px;
      }

      .item .heading {
        font-size: 10.2356px;
        line-height: 12px;
        margin-bottom: 5px;
        text-transform: capitalize;
      }

      .container,
      .dont-break-inside {
        break-after: page;
        page-break-after: always;
      }

      .container {
        /*margin-bottom: 64px;*/
      }

      .chart-list {
        /*display: grid;*/
        /*grid: auto-flow max-content / 432px;*/
        /*gap: 30px;*/
        /*display: flex;*/
        /*flex-flow: column;*/
      }

      .chart-item {
        max-width: 432px;
        border: 1px solid #f2f2f2;
        padding: 24px;
        border-radius: 8px;
        break-inside: avoid;
        page-break-inside: avoid;
        margin-bottom: 30px;
      }

      .chart-name {
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 8px;
      }

      .chart-range {
        font-size: 14px;
        line-height: 16px;
      }

      .chart {
        margin-top: 32px;
        width: 100%;
        position: relative;
        display: grid;
        grid: 1fr / 1rem auto;

        break-inside: avoid;
        page-break-inside: avoid;
      }

      img {
        width: 100%;
      }

      .metric {
        font-weight: 300;
        font-size: 5px;
        top: 35%;
        transform: rotateZ(-90deg);
        position: relative;
        height: 2.4rem;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .chart-footnote {
        font-weight: 400;
        font-size: 10px;
        line-height: 130%;
        color: #000000;
        margin: 16px 0 64px 0;
      }

      .resource-list {
        display: grid;
        grid: auto-flow max-content / minmax(auto, 365px);
      }

      .resource-item {
        width: 100%;
        background-color: #dedede;
        margin-bottom: 8px;
        display: grid;
        grid: 1fr / 32px auto;
        align-items: center;
        padding: 18px 26px;
        text-decoration: underline;
        color: black;
        border: 1px solid #f2f2f2;
      }

      .resource-item span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.001em;
      }

</style>
  </head>`;

  // replace indicator data with only the appropriate period data
  const indicatorsTemplate = ((indicators) => `
    <div class='selected-indicators'>
      ${indicators
        .map(
          (dataset) => `
        <div class='item'>
          <div class='heading'>${dataset.name} - ${dataset.data.period}</div>
          <div class='value'>${dataset.data.value}</div>
        </div>`
        )
        .join('')}
    </div>
`)(selectedIndicators);

  const resourcesTemplate = ((resources) => `
      <div class="resource-list">
      ${Object.entries(resources)
        .map(
          ([name, url]) => `
        <a class="resource-item" href="${url}">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 2.33382H3.33333V13.0005H12.6667V5.00049H10V2.33382ZM2 1.66182C2 1.29649 2.298 1.00049 2.666 1.00049H10.6667L14 4.33382V13.6625C14.0006 13.75 13.984 13.8369 13.951 13.918C13.9181 13.9991 13.8695 14.0729 13.808 14.1353C13.7466 14.1976 13.6734 14.2472 13.5928 14.2813C13.5121 14.3154 13.4255 14.3332 13.338 14.3338H2.662C2.48692 14.3326 2.31934 14.2626 2.19548 14.1388C2.07161 14.0151 2.0014 13.8476 2 13.6725V1.66182Z"
              fill="black"
            />
            <path
              d="M5 10V7H6.13294C6.27561 7 6.40289 7.02292 6.51479 7.06877C6.62669 7.11175 6.7204 7.17479 6.79593 7.25788C6.87425 7.34097 6.933 7.44126 6.97216 7.55874C7.01133 7.67622 7.03091 7.80802 7.03091 7.95415C7.03091 8.10029 7.01133 8.23209 6.97216 8.34957C6.933 8.46705 6.87425 8.56734 6.79593 8.65043C6.7204 8.73352 6.62669 8.79799 6.51479 8.84384C6.40289 8.88682 6.27561 8.90831 6.13294 8.90831H5.63361V10H5ZM5.63361 8.38825H5.97349C6.12176 8.38825 6.22386 8.3596 6.27981 8.30229C6.33855 8.24499 6.36793 8.15043 6.36793 8.01862V7.88968C6.36793 7.75788 6.33855 7.66332 6.27981 7.60602C6.22386 7.54871 6.12176 7.52006 5.97349 7.52006H5.63361V8.38825Z"
              fill="black"
            />
            <path
              d="M7.46986 7H8.41398C8.80841 7 9.10074 7.12894 9.29096 7.38682C9.48119 7.6447 9.5763 8.01576 9.5763 8.5C9.5763 8.98424 9.48119 9.3553 9.29096 9.61318C9.10074 9.87106 8.80841 10 8.41398 10H7.46986V7ZM8.37202 9.49284C8.55944 9.49284 8.69652 9.43123 8.78324 9.30802C8.86996 9.18481 8.91331 9.00573 8.91331 8.77077V8.22493C8.91331 7.99284 8.86996 7.81519 8.78324 7.69198C8.69652 7.56877 8.55944 7.50716 8.37202 7.50716H8.09088V9.49284H8.37202Z"
              fill="black"
            />
            <path
              d="M10.0572 10V7H12V7.52865H10.6866V8.21633H11.8615V8.74499H10.6866V10H10.0572Z"
              fill="black"
            />
          </svg>
          <span>${name}</span>
      </a>`
        )
        .join('')}
    </div>
`)(resource);

  const chartsTemplate = ((charts) => `
     <div class="chart-list">
        ${charts
          .map(
            ({ name, startYear, endYear, metric, image }) => `<div class="chart-item">
          <div class="chart-name">${name}</div>
          <div class="chart-range">${startYear}-${endYear}</div>
          <div class="chart">
            <div class="metric">
              ${metric}
            </div>
            <img
              src="${image}"
              alt=""
            />
          </div>
        </div>`
          )
          .join('')}
      </div>
  `)(selectedCharts);

  return `
    ${templateHead}
    <body>
      <h1 class='heading'>${title}</h1>
      <div class='container'>
        <div class='title'>Selected Indicators</div>
        ${indicatorsTemplate}
      </div>

      <div class="container">
        <div class="title">Selected Charts</div>
        ${chartsTemplate}

        <div class="title" style="margin-top: 64px;">Resources</div>
        ${resourcesTemplate}
      </div>

    </body>
  `;
}
