import { mapActions } from 'vuex';
import { KAuth, KButton, KInput } from '@/components';
import Validators from '@/mixins/Validators';

export default {
  name: 'ResetPassword',
  components: {
    KAuth,
    KButton,
    KInput,
  },
  mixins: [Validators],
  data: () => ({
    user: {},
    isLoading: false,
    error: {},
    touched: {},
    disabled: '',
  }),
  computed: {
    urlToken() {
      return this.$route.query.token;
    },
  },
  methods: {
    ...mapActions({
      resetPassword: 'auth/resetPassword',
    }),
    async changePassword() {
      const {
        user: { password },
        urlToken: token,
      } = this;
      this.isLoading = true;
      try {
        const response = await this.resetPassword({ password, token });
        if (!response.error) {
          this.$toast.show({ message: response });
          this.email = '';
          this.$router.push('./login');
        } else {
          throw Error(response.error);
        }
        this.isLoading = false;
      } catch (error) {
        this.$toast.show({ message: error });
        this.isLoading = false;
      }
    },
    goBack() {
      if (document.referrer === '') {
        this.disabled = 'disabled';
      }
    },
  },
};
