<template>
  <div class="loadingio-spinner-rolling-2by998twmg8" :class="classType">
    <div class="ldio-yzaezf3dcmj">
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classType: {
      type: String,
      default: 'border-black',
    },
  },
};
</script>

<style>
@keyframes ldio-yzaezf3dcmj {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.border-black {
  border-color: #bbb1b2;
}
.border-white {
  border-color: #fff;
}
.ldio-yzaezf3dcmj div {
  position: absolute;
  width: 40px;
  height: 40px;
  border-width: 8px;
  border-style: solid;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-yzaezf3dcmj div {
  animation: ldio-yzaezf3dcmj 1s linear infinite;
  top: 50px;
  left: 50px;
}
.loadingio-spinner-rolling-2by998twmg8 {
  width: 38px;
  height: 38px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-yzaezf3dcmj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.38);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-yzaezf3dcmj div {
  box-sizing: content-box;
}
/* [ldio] generated by https://loading.io */
</style>
