<template>
  <div style="width: 100%">
    <bar-chart :chart-data="chartData" :height="height" />
  </div>
</template>

<script>
import BarChart from './BarChart';

export default {
  name: 'BarChartWrapper',
  components: {
    BarChart,
  },
  props: {
    labels: {
      type: Array,
    },
    datasets: {
      type: Array,
    },
    height: {
      type: Number,
      default: 150,
    },
  },
  computed: {
    chartData() {
      return {
        fill: 'start',
        labels: this.labels,
        datasets: this.datasets,
      };
    },
  },
};
</script>

<style scoped></style>
