<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
  >
    <path :d="d" fill="#999999" />
  </svg>
</template>

<script>
export default {
  name: 'hideIcon',
  data() {
    return {
      d: 'M3.01332 3.95604L0.928656 1.87204L1.87199 0.928711L15.0713 14.1287L14.128 15.0714L11.9213 12.8647C10.7488 13.6084 9.38848 14.0023 7.99999 14C4.40532 14 1.41466 11.4134 0.787323 8.00004C1.07401 6.44719 1.85508 5.02864 3.01399 3.95604H3.01332ZM9.83799 10.7814L8.86199 9.80538C8.48869 9.98399 8.06917 10.0424 7.66128 9.97257C7.25338 9.90273 6.87719 9.70809 6.58457 9.41546C6.29195 9.12284 6.0973 8.74665 6.02747 8.33876C5.95763 7.93086 6.01605 7.51135 6.19466 7.13804L5.21866 6.16204C4.79447 6.80304 4.60484 7.57093 4.68188 8.3357C4.75892 9.10048 5.09789 9.81512 5.6414 10.3586C6.18491 10.9021 6.89956 11.2411 7.66433 11.3182C8.4291 11.3952 9.197 11.2056 9.83799 10.7814V10.7814ZM5.31599 2.50671C6.14732 2.18004 7.05332 2.00004 7.99999 2.00004C11.5947 2.00004 14.5853 4.58671 15.2127 8.00004C15.0084 9.11051 14.5492 10.1584 13.8713 11.0614L11.298 8.48804C11.3744 7.97312 11.3292 7.44745 11.1659 6.95315C11.0027 6.45885 10.7259 6.00965 10.3578 5.64156C9.98972 5.27346 9.54052 4.99669 9.04622 4.83343C8.55192 4.67016 8.02625 4.62494 7.51132 4.70138L5.31599 2.50738V2.50671Z',
    };
  },
};
</script>

<style scoped></style>
