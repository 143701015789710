export default {
  '#E1331B': 'Red',
  '#FFA500': 'Orange',
  '#FFE800': 'Yellow',
  '#62BD69': 'Green',
  '#00CEF1': 'Cyan',
  '#0F2B90': 'Blue',
  '#900F7B': 'Purple',
  '#F06292': 'Pink',
  '#CC00CC': 'Mangenta',
};
