import { VueEditor } from 'vue2-editor';
import { mapGetters } from 'vuex';
import { KModal, KCard } from '@/components';
import { evaluateEconomicStatement, evaluateEconomicValidate } from '../../../api/factCheck';
import Accordion from '@/components/Accordion/Accordion.vue';
import Loader from '@/components/Loader/Loader.vue';
// import { colorList } from '../../../config/library';

export default {
  name: 'FactChecking',
  components: { VueEditor, Accordion, KModal, KCard, Loader },
  data() {
    return {
      name: 'Fact Check',
      text: '',
      renderText: '',
      title: '',
      document_id: '',
      rerun: 0,
      placeholder: 'Start by writing, pasting (Ctrl + V) text.👋',
      originalStatement: [],
      loadingText: 'Fact check',
      matches: [],
      selectedPos: null,
      error: { message: '', isError: false },
      customToolbar: [
        [{ size: [] }, { font: [] }],
        ['bold', 'italic', 'underline'],
        [{ color: [] }, { background: [] }],
        ['direction', { align: 'left' }, { align: 'center' }, { align: 'right' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
      loadingStatement: [],
      loading: false,
      documentLoading: false,
      isRequestTimeout: false,
      showModal: false,
      isAccept: false,
      disableAutoUpdate: true,
      response: {
        matches: [],
      },
      selected: {
        index: null,
        content: '',
      },
      oldData: { text: '', title: '' },
    };
  },
  computed: {
    ...mapGetters({
      token: 'auth/getToken',
      user: 'auth/getUser',
    }),
    loggedIn() {
      return !!this.token;
    },
    userData() {
      return { ...this.user };
    },
    validateWords() {
      const words = this.text
        .replace(/<\/?[^>]+(>|$)/g, '')
        .replaceAll('&nbsp;', ' ')
        .replaceAll('  ', ' ');

      return words.length > 6;
    },
    query() {
      return this.text
        .replace(/<\/?[^>]+(>|$)/g, '')
        .replaceAll('&nbsp;', ' ')
        .replaceAll('  ', ' ');
    },
    calcPercent() {
      const correct = this.matches.filter((data) => data.isError === 'correct');
      const incorrect = this.matches.filter((data) => data.isError === 'incorrect');
      const unverified = this.matches.filter((data) => data.isError === 'unverified');
      const unavailable = this.matches.filter((data) => data.isError === 'unavailable');
      const incomplete = this.matches.filter((data) => data.isError === 'incomplete');

      return {
        correct: (correct.length / this.matches.length) * 100 || 0,
        incorrect: (incorrect.length / this.matches.length) * 100 || 0,
        unverified: (unverified.length / this.matches.length) * 100 || 0,
        incomplete: (incomplete.length / this.matches.length) * 100 || 0,
        unavailable: (unavailable.length / this.matches.length) * 100 || 0,
      };
    },
  },
  mounted() {
    this.documentLoading = true;
    setTimeout(() => {
      this.documentLoading = false;
    }, 2000);
  },

  methods: {
    async handleEvaluation() {
      this.loading = true;
      this.loadingText = 'Kindly wait for a moment...';
      this.matches = [];
      try {
        const res = await evaluateEconomicStatement(this.query);
        const statementKey = Object.keys(res.message);

        const newArr = Object.values(res.message).map((val, i) => ({
          originalText: val,
          validatingText: statementKey[i],
          message: '',
          isError: null,
        }));
        this.loading = false;
        this.loadingText = 'Fact check';
        this.matches = newArr;
        // this.loadingText = 'Validating text...';
        // const validatedStatements = await Promise.all(
        //   newArr.map(async (a) => {
        //     const da = await evaluateEconomicValidate(a.validatingText);

        //     return { ...a, message: da.message, isError: da.results };
        //   })
        // );

        // this.matches = validatedStatements;
        await this.colorHighlight(newArr);
        this.attachEventListeners();
        this.loading = false;
      } catch (err) {
        if (err) {
          // this.handleError(err);
          this.loading = false;
          this.loadingText = 'Fact check';
        }
      }
    },
    async validateStatement(pos) {
      this.selectedPos = pos === this.selectedPos ? null : pos;

      if (!this.loadingStatement[pos]) {
        this.loadingStatement[pos] = true;
        try {
          const data = this.matches[pos];

          if (data.isError === null) {
            const res = await evaluateEconomicValidate(data.validatingText);

            this.matches.splice(pos, 1, {
              ...data,
              ...res,
              isError: res.validation_status,
              source: {
                provider: res.source.provider,
                url: res.source.url,
                source_type: res.source.source_type,
              },
            });
          }
        } catch (err) {
          console.log(err, 'error');
        }

        this.loadingStatement[pos] = false;
      }
    },
    attachEventListeners() {
      const spans = document.querySelectorAll('.unique');

      spans.forEach((span, index) => {
        span.addEventListener('click', ($event) => {
          this.updatePos($event.target.id);
        });
      });
    },
    async colorHighlight(data) {
      let updatedText = this.text;
      for (let i in data) {
        updatedText = updatedText.replace(
          data[i].originalText,
          `<span style="text-decoration:#900F7B solid underline;cursor:pointer;transition: .2s text-decoration;" 
           class="unique"
           id="${i}"
           onmouseover="() => {this.style.textDecoration ='#900F7B wavy underline'}" 
           onmouseout="() => {this.style.textDecoration ='#900F7B wavy underline'}"
           >${data[i].originalText}</span>`
        );
      }
      this.renderText = updatedText;
    },
    handleInput() {
      this.text = this.$refs.textarea.textContent;
    },
    updatePos(i) {
      // console.log('update pos', i);
      if (this.selectedPos !== i) {
        // this.selectedPos = i;
        this.validateStatement(i);
      } else {
        this.selectedPos = null;
      }
    },
    handleError(error = 'Something went wrong!') {
      if (error) {
        this.error.isError = true;
        this.error.message = error;
      }

      setTimeout(() => {
        this.error = { isError: '', message: '' };
      }, 30000);
    },

    copyText() {
      const tempInput = document.createElement('input');
      tempInput.value = this.text.replace(/<\/?[^>]+(>|$)/g, '').replaceAll('&nbsp;', ' ');
      document.body.appendChild(tempInput);

      tempInput.select();

      document.execCommand('copy');
      alert('Text copied to clipboard!');
    },

    promptUserLogin() {
      this.disableAutoUpdate = false;
      if (!this.loggedIn) {
        this.showModal = true;
      }
    },
  },
  watch: {
    text: function () {
      if (this.text.length > 0) {
        this.placeholder = '';
      } else {
        this.placeholder = 'Start by writing, pasting (Ctrl + V) text. 👋';
      }
    },
  },
};
