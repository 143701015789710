<template>
  <!-- continent for desktop view -->
  <div class="continent-wrapper">
    <div class="continent">
      <h3>{{ continent }}</h3>
      <div class="countries-wrapper">
        <div class="countries">
          <!-- loading animation for the countries -->
          <div v-if="isLoading" class="loading-countries">
            <div class="skeleton"></div>
            <div class="skeleton"></div>
            <div class="skeleton"></div>
            <div class="skeleton"></div>
            <div class="skeleton"></div>
            <div class="skeleton"></div>
            <div class="skeleton"></div>
            <div class="skeleton"></div>
            <div class="skeleton"></div>
          </div>
          <!-- render the countries -->
          <div v-else v-for="country in filteredCountries" :key="country.name.common">
            <router-link
              :to="`/countries/indicator/${country.name.common.toLowerCase().split(' ').join('-')}`"
            >
              <p>{{ country.name.common }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- continent for mobile view -->
    <div class="continent-mobile">
      <h3 @click="showModal = !showModal" :id="continent">
        {{ continent }}
      </h3>
      <!-- the gray background for the modal -->
      <div class="backdrop" v-if="showModal" @click="showModal = false"></div>
      <!--  -->
      <div class="countries-wrapper-mobile" v-if="showModal">
        <input type="text" placeholder="Search Country" v-model="search" />
        <!-- if filteredCountries is not empty, render this -->
        <div class="countries" v-if="filteredCountries.length">
          <div v-for="country in filteredCountries" :key="country.name.common">
            <router-link
              :to="`/countries/indicator/${country.name.common.toLowerCase().split(' ').join('-')}`"
            >
              <p>{{ country.name.common }}</p>
            </router-link>
          </div>
        </div>
        <!-- else render this -->
        <div class="countries" v-else>
          <p>No search result found</p>
        </div>
        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Continents',
  props: {
    continent: String,
  },
  data() {
    return {
      countries: [],
      placeholder: `Search country in ${this.continent}`,
      showModal: false,
      search: '',
      isLoading: true,
    };
  },
  methods: {
    getCountries(region) {
      axios
        .get(`https://restcountries.com/v3.1/region/${region}`)
        .then((response) => {
          response.data.sort((a, b) => {
            if (a.name.common < b.name.common) {
              return -1;
            }
            if (a.name.common > b.name.common) {
              return 1;
            }
            return 0;
          });
          this.countries = response.data;
          if (this.countries.length) {
            this.isLoading = false;
            console.log(this.isLoading);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    // filteredCountries returns countries filtered from search
    // eslint-disable-next-line
    filteredCountries: function () {
      return this.countries.filter(
        (country) =>
          // eslint-disable-next-line
          country.name.common.trim().toLowerCase().match(this.search.trim().toLowerCase())
        // eslint-disable-next-line
      );
    },
  },

  created() {},

  mounted() {
    // this gets the countries based on the continent
    this.getCountries(this.continent);
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: lightgrey;
}

::-webkit-scrollbar-thumb {
  background: #900f7b;
  border-radius: 2px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #900f7b lightgrey;
}

.continent-wrapper {
  width: 17%;
  height: 120%;
}

.continent {
  height: 100%;
  padding: 1em 0.6em;
  border: 1px solid #900f7b;
  border-radius: 8px;
  background-color: #fff;

  & h3 {
    text-transform: capitalize;
    font-size: 1.8rem;
    color: #900f7b;
    padding-bottom: 0.3em;
    border-bottom: 1px solid #900f7b;
  }

  & p {
    padding: 0.5em 0 0.5em 0.3em;
    transition: linear 0.2s;

    &:hover {
      background-color: #f2f2f2;
      color: #e1331b;
      font-size: 1.5rem;
    }
  }
}

.continent-mobile {
  display: none;
}

.countries-wrapper {
  height: 86%;
  overflow-x: hidden;
  margin-top: 1.4em;
}

.countries {
  height: 90%;

  & > div {
    cursor: pointer;
  }
}

.loading-countries {
  height: 111%;
}

.skeleton {
  width: 100%;
  padding: 1.2em;
  margin-bottom: 0.5em;
  background-color: #ededed;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 3.8rem;
    top: 0;
    left: -32px;
    background: rgba(255, 255, 255, 0.9);
    filter: blur(16px);
    animation: suspense 1.5s 0.05s ease infinite;
  }
}

@media screen and (max-width: 821px) {
  .continent {
    display: none;
  }

  .continent-wrapper {
    width: 100%;
  }

  .continent-mobile {
    display: block;
    padding: 14px 20px;
    margin-inline: 14px;
    background-color: #fff;
    border: solid 1px #900f7b;
    border-radius: 8px;

    & h3 {
      color: #900f7b;
      text-transform: capitalize;
      font-size: 18px;
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(128, 128, 128, 0.5);
    backdrop-filter: blur(2px);
    height: 100%;
    width: 100vw;
    z-index: 9002;
  }

  .countries-wrapper-mobile {
    height: 31em;
    width: 85%;
    background-color: #fff;
    padding: 1em 1em 0 1em;
    margin-inline: auto;
    position: fixed;
    top: 110px;
    left: 20px;
    right: 20px;
    z-index: 9005;
    border-radius: 8px;
    overflow: hidden;

    & input {
      width: 100%;
      height: 50px;
      padding: 5px 10px;
      margin: 3px 0px 13px 0px;
      border: 1px solid #d9d9d9;
      border-radius: 5px;

      &:focus-visible {
        outline: 1px solid #900f7b;
      }
    }
  }

  .padding {
    height: 87%;
    overflow-x: hidden;
  }

  .countries {
    height: 83%;
    overflow-y: scroll;

    & p {
      padding: 10px;
    }
  }
}
</style>
