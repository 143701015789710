import ContactUs from '../../../mixins/ContactUs';

import {
  KButton,
  KModal,
  KCard,
  KInput,
} from '@/components';
import WebsiteFooter from '../WebsiteFooter.vue';

export default {
  name: 'Contact',
  components: {
    KInput,
    WebsiteFooter,
    KButton,
    KModal,
    KCard,
  },
  mixins: [ContactUs],
};
