<template>
  <div class="page_builder">
    <div
      :class="`fact__message fact__message-${!error.isError ? 'success' : 'error'}`"
      v-if="error.message"
    >
      <p>{{ error.message }}</p>
      <div @click="error = {}" class="fact__message-close">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    </div>

    <!-- <div v-if="loggedIn">
      <input
        type="text"
        v-model="title"
        @blur="updateRecord"
        placeholder="Untitled document"
        class="fact__input"
      />
    </div> -->
    <div class="fact-title_wrapper">
      <h2 class="fact-title">Fact Check</h2>
      <p class="fact-desc">Validate and Refine Economic Data; Publish Confidently</p>
    </div>
    <div class="fact__conten flex">
      <!-- Sidebar -->
      <!-- <div class="fact__sidebar">
        <div class="text-4xl bg-orange-400 fact__sidebar-container">
          <div v-if="!documentLoading">
            <div class="mb-20">
              <p class="fact__sidebar-title">Previous 7 days</p>
              <div class="fact__sidebar-item-wrapper">
                <router-link to="/" tag="p"> Curtain size </router-link>
                <router-link to="/" tag="p"> Tech role summary </router-link>
                <router-link to="/" tag="p"> Driving with UK Provisonal </router-link>
              </div>
            </div>

            <div>
              <p class="fact__sidebar-title">Previous 7 days</p>
              <div class="fact__sidebar-item-wrapper">
                <router-link to="/" tag="p"> Frontend</router-link>
                <router-link to="/" tag="p"> Bank draft </router-link>
                <router-link to="/" tag="p"> End of life care </router-link>
              </div>
            </div>
          </div>
          <div class="fact__sidebar-loader" v-else>
            <loader />
          </div>
        </div>
      </div> -->

      <div class="flex" style="padding: 0 20px">
        <div :class="`fact__col1 ${matches.length > 0 ? 'fact__col1-w' : 'fact__col1-wF'}`">
          <button v-show="isAccept" class="fact__button fact__button-copy" @click="copyText()">
            <svg
              width="17"
              height="20"
              viewBox="0 0 17 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V4H2V18H13V20H2ZM6 16C5.45 16 4.97917 15.8042 4.5875 15.4125C4.19583 15.0208 4 14.55 4 14V2C4 1.45 4.19583 0.979167 4.5875 0.5875C4.97917 0.195833 5.45 0 6 0H15C15.55 0 16.0208 0.195833 16.4125 0.5875C16.8042 0.979167 17 1.45 17 2V14C17 14.55 16.8042 15.0208 16.4125 15.4125C16.0208 15.8042 15.55 16 15 16H6Z"
                fill="white"
              />
            </svg>
            Copy
          </button>
          <!-- <div v-html="text"></div> -->

          <div
            v-html="renderText"
            class="custom-textarea"
            contenteditable="true"
            @input="handleInput"
            :placeholder="placeholder"
            ref="textarea"
          />

          <div class="">
            <button
              class="fact__button fact__button-action"
              :disabled="!text || !validateWords || loading"
              @click="handleEvaluation()"
            >
              <Loader v-show="loading" />
              {{ loadingText }}
            </button>
          </div>
        </div>
        <div class="fact__col2">
          <div v-if="matches.length > 0 && text">
            <div class="fact__content-wrapper">
              <p style="">Review suggestions</p>
              <div class="fact__content-reviews">
                {{ matches.length }}
              </div>
            </div>

            <div class="stat__wrapper">
              <div title="The values in the user query are correct.">
                <div class="stat__bar" style="--color: #fc2f2f">
                  <div
                    class="stat__bar-indicator"
                    :style="`--indicator:${calcPercent.incorrect}%; --color: #fc2f2f`"
                  ></div>
                </div>
                <p class="stat__title">Incorrect</p>
              </div>
              <div
                title="The internet values in the response could not be verified as correct. It may be correct or incorrect or contain multiple figures, which makes it difficult to ascertain that it is correct"
              >
                <div class="stat__bar" style="--color: #ee7811e9">
                  <div
                    class="stat__bar-indicator"
                    :style="`--indicator:${calcPercent.unverified}%; --color: #ee7811e9`"
                  ></div>
                </div>
                <p class="stat__title">Unverified</p>
              </div>
              <div title="The values in the user query are correct">
                <div class="stat__bar" style="--color: #03af33">
                  <div
                    class="stat__bar-indicator"
                    :style="`--indicator:${calcPercent.correct}%; --color: #03af33`"
                  ></div>
                </div>
                <p class="stat__title">Correctness</p>
              </div>
              <div
                title="This means that the economic statement is not complete and is missing either year, country or value."
              >
                <div class="stat__bar" style="--color: #5aa9e6">
                  <div
                    class="stat__bar-indicator"
                    style="--indicator: 0%; --color: #5aa9e6"
                    :style="`--indicator:${calcPercent.incomplete}%; --color: #5aa9e6`"
                  ></div>
                </div>
                <p class="stat__title">Incomplete</p>
              </div>
              <div
                title="This means that we could not find data for verification on our db or the internet."
              >
                <div class="stat__bar" style="--color: #808080">
                  <div
                    class="stat__bar-indicator"
                    :style="`--indicator:${calcPercent.unavailable}%; --color: #808080`"
                  ></div>
                </div>
                <p class="stat__title">Unavailable</p>
              </div>
            </div>
            <div class="flex-col fact__content-list">
              <div v-for="(content, index) in matches" :key="content.id">
                <accordion
                  :content="content"
                  :index="String(index)"
                  :selected="selectedPos"
                  :loadingStatement="loadingStatement"
                  @handleAccept="() => {}"
                  @validate="validateStatement"
                />
              </div>
            </div>
          </div>
          <div
            style="
              padding: 20px;
              border-left: 0.5px solid #f1f1f1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              height: 100%;
              align-items: center;
            "
            v-else
          >
            <div style="display: flex; justify-content: center">
              <img src="@/assets/business.png" alt="icon" width="100" />
            </div>
            <p style="text-align: center">
              Please provide your statement below, and the review suggestions will appear here
              shortly.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="fixed-button">
      <button
        class="fact__button fact__button-action"
        :disabled="!text || loading || validateWords"
        @click="loggedIn ? handleEvaluation() : handleSampleEvaluation(selected.id)"
      >
        {{ loading ? 'Kindly wait for a moment...' : 'Check fact' }}
      </button>
    </div> -->
    <!-- Not Found MODAL -->
    <k-modal :open="showModal" middle @close="showModal = false">
      <k-card heading="" padInside smaller variant="in-modal">
        <div class="img-container">
          <img alt="" src="@/assets/attention.svg" />
        </div>
        <h1 class="modal__heading">Sorry!</h1>
        <p>
          Kindly <router-link to="/signup" style="color: rgb(0, 136, 255)">signup</router-link> to
          use the editor.
        </p>
      </k-card>
    </k-modal>
    <!-- Not Found MODAL end -->
  </div>
</template>

<script>
import FactChecking from './FactChecking';

export default FactChecking;
</script>

<style lang="scss" src="./FactChecking.scss"></style>
