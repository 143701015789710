import { mapGetters } from 'vuex';
import { getSingleData } from '@/api';
import currencies from '@/utils/currencies';

// helpers
import calculateFrequency from '@/utils/frequency';
import { getYear as utilGetYear } from '@/views/Search/Save/downloadTemplate';
import numberOr from '@/utils/numberOr';

export default {
  computed: {
    ...mapGetters({ token: 'auth/getToken' }),
    currencies() {
      return currencies;
    },
    frequencyOptions() {
      if (!this.compoundData) return {};
      const frequencies = Object.keys(this.compoundData);
      const filteredOptions = {};

      frequencies.forEach((f) => {
        filteredOptions[f] = this.capitalize(f);
      });
      return filteredOptions;
    },
    yearsGreaterThan() {
      const { dataset, toOptions, yearsOver } = this;
      if (!dataset.yearsArray) return {};

      const { startYear, yearsArray } = dataset;
      return toOptions(yearsOver(this.getYear(startYear), yearsArray));
    },
    yearsLessThan() {
      const { dataset, toOptions, yearsUnder } = this;
      if (!dataset.yearsArray) return {};

      const { endYear, yearsArray } = dataset;
      return toOptions(yearsUnder(this.getYear(endYear), yearsArray));
    },
    values() {
      const {
        dataset: {
          startYear, endYear, data,
        }, getYear,
      } = this;
      if ((data && !data.length) || !data) return [];
      return data.filter((item) => {
        const year = getYear(item.period);
        const start = parseFloat(startYear);
        const end = parseFloat(endYear);
        return year >= start && year <= end;
      });
    },
    chartData() {
      const { dataset, values } = this;
      return {
        labels: values && values.map((item) => item.period),
        datasets: [
          {
            data: values && values.map(numberOr()),
            backgroundColor: dataset.colour,
            pointBackgroundColor: dataset.colour,
            borderColor: dataset.colour,
            pointHitRadius: 4,
            spanGaps: true,
          },
        ],
      };
    },
  },
  methods: {
    isLoggedIn() {
      if (this.token) return Promise.resolve();
      return Promise.reject();
    },
    async fetchDataset(id, options = { colour: '', dataName: '' }) {
      const { colour, dataName } = options;

      if ((!dataName && this.dataset.id === id && this.dataset.values)
        || (dataName && this[dataName].id === id && this[dataName].values)) {
        this.isLoading = false;
        return;
      }

      const { getYear, toOptions, $route: { query } } = this;
      try {
        const result = await getSingleData({ id }).then((res) => res.data.data);
        const { data, indicatorName: indicator, ...restOfData } = result;

        const isInitial = options.first;

        const getDefault = (key, defaultVal) => {
          if (isInitial) {
            const forD2 = dataName === 'dataset2';
            return query[`${key}${forD2 ? '2' : ''}`] || defaultVal;
          }
          return defaultVal;
        };

        const dataset = {
          ...restOfData,
          indicator,
          chartType: getDefault('chartType', 'bar'),
          colour: getDefault('colour', colour || '#900F7B'),
          startYear: getDefault('startYear', restOfData.startYear),
          endYear: getDefault('endYear', restOfData.endYear),
          frequency: getDefault('frequency', 'yearly'),
          values: null,
        };

        // remove commas and spaced from data
        const cleanData = data.map((d) => ({
          ...d,
          value: d.value.replaceAll(',', '').replaceAll(' ', ''),
        }));

        const percent = restOfData.metric === '%';
        const valueContainer = calculateFrequency(
          cleanData,
          restOfData.frequency,
          percent,
        );

        valueContainer[restOfData.frequency] = cleanData.map((d) => ({
          ...d,
          value: d.value.replaceAll(',', ''),
        }));
        dataset.values = valueContainer;

        const yearSet = new Set();
        data.forEach((d) => {
          yearSet.add(getYear(d.period));
        });
        dataset.yearsArray = Array.from(yearSet);
        this.years = toOptions(Array.from(yearSet));

        if (dataName) {
          this[dataName] = { ...this[dataName], ...dataset };
          this.country2 = dataset.country;
          this.category2 = dataset.category;
        } else {
          this.dataset = dataset;
          this.country = dataset.country;
          this.category = dataset.category;
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        this.isLoading = false;
      }
    },
    toOptions(list) {
      return list.reduce((accumulated, current) => {
        const newAccum = { ...accumulated };
        newAccum[current] = parseInt(current, 10);
        return newAccum;
      }, {});
    },
    getYear(val) {
      return utilGetYear(val);
    },
    yearsOver(year, yearList) {
      const { getYear } = this;
      return yearList.filter((y) => y && getYear(y) >= getYear(year));
    },
    yearsUnder(year, yearList) {
      const { getYear } = this;
      return yearList.filter((y) => y && getYear(y) <= getYear(year));
    },
    getDatasetOptionsFromURl() {
      const { colour, colour2 } = this.$route.query;

      const datasetItems = Object.entries(this.$route.query).reduce((result, [key, value]) => {
        const newResult = { ...result };
        if (!key.endsWith('2')) newResult[key] = value;
        return newResult;
      }, {});

      const dataset = {
        ...this.dataset,
        ...datasetItems,
        dontHideChart: true,
        colour,
      };

      if (this.dataset2) {
        const dataset2Items = Object.entries(this.$route.query).reduce((result, [key, value]) => {
          const newResult = { ...result };
          if (key.endsWith('2')) newResult[key.slice(0, key.length - 1)] = value;
          return newResult;
        }, {});

        const dataset2 = {
          ...this.dataset2,
          ...dataset2Items,
          dontHideChart: true,
          colour: colour2,
        };

        return Promise.resolve({ dataset, dataset2 });
      }

      return Promise.resolve({ dataset });
    },
    updateURLSearchParams(options, config = {}) {
      const validKeys = config.compare ? [
        'category',
        'indicator',
        'country',
        'colour',
        'frequency',
        'startYear',
        'endYear',
      ] : [
        'category',
        'indicator',
        'country',
        'chartType',
        'colour',
        'frequency',
        'startYear',
        'endYear',
      ];
      Object.entries(options).forEach(([key, value]) => {
        if (validKeys.includes(key)) {
          const routeOptions = {
            path: window.location.pathname,
            query: { ...this.$route.query, [`${key}${config.dataset2 ? '2' : ''}`]: value },
          };
          // if (config.push) this.$router.push(routeOptions).catch((e) => e);
          // else
          this.$router.replace(routeOptions).catch((e) => e);
        }
      });
    },
  },
  watch: {
    embedWidth(val) {
      this.embedHeight = Math.round(Number(val) * 0.525);
    },
  },
};
