<template>
  <div class='page'>
    <div class='container'>
      <template v-if='isLoading'>
        <header class='header loading'>
          <h1 class='suspense'></h1>
          <div class='meta suspense'></div>
        </header>
        <div class='container loading'>
          <p class='suspense'></p>
          <p class='suspense'></p>
          <p class='suspense'></p>
          <p class='suspense'></p>
          <p class='suspense'></p>
          <p class='suspense'></p>
          <p class='suspense'></p>
          <p class='suspense'></p>
          <p class='suspense'></p>
          <p class='suspense'></p>
          <p class='suspense'></p>
          <p class='suspense'></p>
        </div>
      </template>
      <template v-else>
        <header class='header'>
          <h1>{{ job.content.title }}</h1>
          <div class='meta'>
            <div class='location'>{{ job.content.location }}</div>
            <span class='middot'>&middot;</span>
            <div class='remote'>{{ job.content.employment_type }}</div>
          </div>
        </header>
        <div class='container'>
          <section class='body' v-html='description.outerHTML'></section>
          <div class='cta' v-if='job.content.link_to_apply'>
            <a :href='job.content.link_to_apply' target='_blank' rel='noreferrer'>
              <k-button>Apply for this Position</k-button>
            </a>
          </div>
        </div>
      </template>
    </div>
    <website-footer></website-footer>
  </div>
</template>

<script>
import { getSingleJob } from '@/api/careers';

import WebsiteFooter from '../WebsiteFooter.vue';
import KButton from '@/components/Button/Button';

// helpers
import richtextToHTML from '@/utils/RichtextToHTML';

export default {
  name: 'JobListing',
  components: { KButton, WebsiteFooter },
  data: () => ({
    job: {},
    isLoading: true,
  }),
  async mounted() {
    try {
      const { slug } = this.$route.params;
      this.job = await getSingleJob({ slug });
    } catch (e) {
      console.log(e.message);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    description() {
      return this.job.content && richtextToHTML(this.job.content.description);
    },
  },
};
</script>

<style lang='scss' scoped src='./JobListing.scss'>

</style>
