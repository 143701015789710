import axios from 'axios';
import Continents from './Continents.vue';
import KFooter from '../WebsiteFooter.vue';

export default {
  components: {
    Continents,
    KFooter,
  },
  data() {
    return {
      continents: ['americas', 'europe', 'africa', 'asia', 'oceania'],
      countries: [],
      search: '',
      isVisible: false,
    };
  },
  methods: {
    getCountries() {
      axios
        .get('https://restcountries.com/v3.1/all')
        .then((response) => {
          response.data.sort((a, b) => {
            if (a.name.common < b.name.common) {
              return -1;
            }
            if (a.name.common > b.name.common) {
              return 1;
            }
            return 0;
          });
          this.countries = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    // eslint-disable-next-line
    filteredCountries: function () {
      return this.countries.filter(
        (country) =>
          // eslint-disable-next-line
          country.name.common.trim().toLowerCase().match(this.search.trim().toLowerCase())
        // eslint-disable-next-line
      );
    },
  },
  mounted() {
    this.getCountries();
  },
};
