<template>
  <div class='page'>
    <div class='container wider'>
      <header class='header'>
        <h1>Join Our Growing Team</h1>
        <p>Work with our global team of data experts, designers, developers. See openings below</p>
      </header>
      <template v-if='isLoading'>
        <div class='job-list loading'>
          <div v-for='job in [1,2,3,4,5]'
               :key='job'
               class='job'>
            <p class='job__title suspense'>
            </p>
            <div class='job__meta-data suspense'></div>

            <div class='job__description'>
              <p class='suspense'></p>
              <p class='suspense'></p>
              <p class='suspense'></p>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class='job-list'>
          <div v-for='job in jobs'
               :key='job.id'
               class='job'
               @click='$router.push({name: "JobListing", params: {slug: job.slug}})'>
            <p class='job__title'>
              {{ job.content.title }}
            </p>
            <div class='job__meta-data'>
              <div class='location'>{{ job.content.location }}</div>
              <span class='middot'>&middot;</span>
              <div class='remote'>{{ job.content.employment_type }}</div>
            </div>

            <div class='job__description'>
              {{ job.content.preview_description }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <website-footer></website-footer>
  </div>
</template>

<script>
import { getJobs } from '@/api/careers';
import WebsiteFooter from '../WebsiteFooter.vue';

export default {
  name: 'Careers',
  components: { WebsiteFooter },
  data: () => ({
    jobs: [],
    isLoading: true,
  }),
  async mounted() {
    try {
      this.jobs = await getJobs();
    } catch (e) {
      console.log(e.message);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang='scss' scoped src='./Careers.scss'>
</style>
