import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default {
  data: () => ({
    ticketModal: false,
    ticket: {
      firstName: '',
      title: '',
      email: '',
      messageBody: '',
    },
    touched: {},
    error: {},
    general_error: '',
    isLoading: false,
    sendingRequest: false,
  }),
  validations() {
    return {
      ticket: {
        firstName: { required },
        title: { required },
        email: { required },
        messageBody: { required },
      },
    };
  },
  methods: {
    ...mapActions({
      sendTicket: 'auth/sendTicket',
    }),
    async sendEmail() {
      const { ticket } = this;
      const {
        firstName, email, title, messageBody,
      } = ticket;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        this.isLoading = true;
        const response = await this.sendTicket({
          firstName,
          email,
          title,
          messageBody,
        });
        if (!response.error) {
          this.$toast.show({ message: response });
        } else {
          throw Error(response.error);
        }
        this.$v.$reset();
      } catch (error) {
        this.$toast.show({ message: error });
        this.isLoading = false;
      } finally {
        this.isLoading = false;
        this.reset();
      }
    },
    reset() {
      const { ticket } = this;
      ticket.firstName = '';
      ticket.email = '';
      ticket.title = '';
      ticket.messageBody = '';
      this.ticketModal = false;
    },
  },
};
