import currencyWithSymbols from '@/utils/currencyWithSymbols';

export default {
  methods: {
    periodWithValue(data, length, endYear = null) {
      if (length === -1) return endYear ?? data[data.length - 1];
      if (data[length] && data[length].value) return data[length];
      return this.periodWithValue(data, length - 1);
    },
    startPeriodWithValue(data, current, startYear = null) {
      if (current === data.length) return startYear ?? data[0];
      if (data[current] && data[current].value) return data[current];
      return this.startPeriodWithValue(data, current + 1);
    },
    getSymbol(code) {
      const found = currencyWithSymbols.filter((c) => c.abbreviation === code)[0];
      return found ? found.symbol : code;
    },
    withUnit(val, options) {
      const { metric, currency } = options;
      const isString = typeof val === 'string';
      const v = (isString ? Number(val.replaceAll(',', '')) : val).toLocaleString();
      const shortMetric = metric ? this.lower(metric.slice(0, 1)) : '';
      return currency
        ? `${this.getSymbol(currency)}${v}${shortMetric === 't' ? 'k' : shortMetric}`
        : `${v}${metric}`;
    },
  },
};
