<template>
  <k-auth title="Set a New Password">
    <template v-slot:description>
      <p>
        Please enter a new Password to reset your password
      </p>
    </template>
    <form class="form__items" @submit.prevent="changePassword">
      <k-input
       label="New Password"
       type="password" name="password"
       variant="password"
       :error="error.password"
       v-model="user.password" />
      <k-input
        label="Repeat New Password"
        type="password"
        variant="password"
        name="confirm_password"
        :error="error.confirm_password"
        v-model="user.confirm_password"
      ></k-input>
      <k-button :loading="isLoading" size="full" submit>Reset Password</k-button>
    </form>
    <k-button @click="goBack"
    :disabled="disabled"
      size="full"
      variant="link" submit>
      Go Back</k-button>
  </k-auth>
</template>

<script>
import ResetPassword from './ResetPassword';

export default ResetPassword;
</script>

<style lang="scss" scoped></style>
