<template>
  <div class="page">
    <header class="header">
      <h1>{{ titleCase(country) }}: Country Dashboard</h1>

      <div class="controls">
        <k-input
          v-model="country"
          :optionsDisplay="countries"
          label="Country"
          searchInside="countries"
          type="select"
        ></k-input>
        <k-button :loading="isDownloading" variant="secondary" @click="download">
          Download as PDF
        </k-button>
        <k-button @click="openModal = true">Make Custom Request</k-button>
      </div>
    </header>
    <section class="page__content">
      <section class="indicators">
        <h2>Selected Indicators</h2>
        <template v-if="isFetching">
          <div class="indicators__block">
            <div v-for="item in [1, 2, 3, 4, 5, 6, 7, 8]" :key="item" class="item">
              <div class="heading suspense mh-20 w-50"></div>
              <div class="value suspense mh-40 w-70"></div>
            </div>
          </div>
        </template>
        <div v-else-if="!isFetching && dashboard.selectedIndicators" class="indicators__block">
          <div
            v-for="item in dashboard.selectedIndicators"
            :key="item.name"
            class="item"
            @click="
              $router.push({
                name: 'SingleResult',
                params: {
                  indicator: item.name,
                  country: item.country,
                  id: item.datasetId || item.dataset_id,
                },
              })
            "
          >
            <p class="heading">
              {{ item.name }} - {{ periodWithValue(item.data, item.data.length).period }}
            </p>
            <p class="value">
              {{ withUnit(periodWithValue(item.data, item.data.length).value, item) || '--' }}
            </p>
          </div>
        </div>
        <!-- indicators -->
      </section>

      <div class="bottom-section">
        <section class="charts">
          <h2>Selected Charts</h2>
          <template v-if="isFetching">
            <div class="charts__list">
              <div v-for="item in [1, 2]" :key="item" class="charts__item">
                <div class="heading suspense" style="height: 2.4rem; margin-bottom: 1.6rem"></div>
                <div
                  class="subheading suspense w-70"
                  style="height: 2.4rem; margin-bottom: 3.2rem"
                ></div>
                <div class="chart-wrapper suspense" style="height: 12rem; width: 100%"></div>
              </div>
            </div>
          </template>
          <div
            v-else-if="!isFetching && dashboard.selectedCharts"
            ref="charts"
            class="charts__list"
            @mousemove="hovering = {}"
          >
            <div
              v-for="item in dashboard.selectedCharts"
              :key="item.name"
              class="charts__item"
              @click="
                $router.push({
                  name: 'SingleResult',
                  params: {
                    indicator: item.name,
                    country: item.country,
                    id: item.datasetId || item.dataset_id,
                  },
                })
              "
              @mousemove.stop="
                follow($event);
                setHovering(item);
              "
            >
              <p class="heading">{{ item.name }}</p>
              <p class="subheading">{{ getYear(item.startYear) }} - {{ getYear(item.endYear) }}</p>
              <div class="chart-wrapper">
                <div class="chart">
                  <bar-chart-wrapper
                    :datasets="getChartData(item).datasets"
                    :labels="getChartData(item).labels"
                  ></bar-chart-wrapper>
                </div>
              </div>
            </div>
            <div
              ref="definition"
              :style="{ visibility: isFetching || !hovering.name ? 'hidden' : 'visible' }"
              class="hover-helper"
            >
              <div class="name">{{ titleCase(hovering.name) }}</div>
              <template v-if="hovering.values">
                <div class="country">
                  {{ titleCase(hovering.country) }}
                  -
                  {{ latest.period }}
                </div>
                <div class="latest">
                  {{ withUnit(latest.value, hovering) }}
                </div>
                <div :class="['definition', { shown: hovering.indicatorDefinition }]">
                  {{ hovering.indicatorDefinition }}
                </div>
              </template>
            </div>
          </div>
        </section>

        <section class="resources">
          <h2>Resources</h2>
          <template v-if="isFetching">
            <div class="resources__list">
              <div v-for="item in [1, 2]" :key="item" class="resources__item">
                <div class="heading suspense" style="height: 5.6rem; margin-bottom: 1.6rem"></div>
              </div>
            </div>
          </template>
          <div v-else-if="!isFetching && dashboard.resource" class="resources__list">
            <div
              v-for="[name, url] in Object.entries(dashboard.resource)"
              :key="name"
              class="resources__item"
            >
              <a :href="url">
                <div class="resources__item__flex">
                  <img alt="icon" src="@/assets/pdf.svg" />
                  <span>{{ name }}</span>
                </div>
              </a>
            </div>
          </div>
        </section>
      </div>

      <div class="mobile-view-controls">
        <k-button :loading="isDownloading" variant="secondary" @click="download">
          Download as PDF
        </k-button>
        <k-button mobile variant="primary" @click="mobileRequest = true">
          Make Custom Request
        </k-button>
      </div>
    </section>

    <div v-if="mobileRequest" class="overlay" @click="mobileRequest = false"></div>
    <transition name="slide-up">
      <div v-if="mobileRequest" class="customize-options">
        <h1>Custom Request</h1>

        <div class="controls">
          <k-input
            v-model="$v.request.fullName.$model"
            :error="$v.request.fullName.$error ? 'Full Name is required' : ''"
            label="Full Name"
          ></k-input>
          <k-input
            v-model="$v.request.email.$model"
            :error="$v.request.email.$error ? 'Email is required' : ''"
            label="Email Address"
            type="email"
          ></k-input>
          <k-input
            v-model="$v.request.country.$model"
            :error="$v.request.country.$error ? 'Country is required' : ''"
            label="Country"
          ></k-input>
          <k-input
            v-model="$v.request.extraNotes.$model"
            :error="$v.request.extraNotes.$error ? 'Extra Notes is required' : ''"
            label="Extra Notes"
            variant="textarea"
          ></k-input>
        </div>

        <div class="mobile-view-controls no-shadow">
          <k-button
            mobile
            variant="tertiary"
            @click="
              reset();
            "
            >Cancel</k-button
          >
          <k-button :loading="sendingRequest" mobile @click="sendRequest()"> Submit </k-button>
        </div>
      </div>
    </transition>

    <!-- modal start -->
    <k-modal :open="openModal" class="desktop-only" @close="openModal = false">
      <k-card heading="Custom Request" variant="in-modal">
        <div class="form__items">
          <k-input
            v-model="$v.request.fullName.$model"
            :error="$v.request.fullName.$error ? 'Full Name is required' : ''"
            label="Full Name"
          ></k-input>
          <k-input
            v-model="$v.request.email.$model"
            :error="$v.request.email.$error ? 'Email is required' : ''"
            label="Email Address"
            type="email"
          ></k-input>
          <k-input
            v-model="$v.request.country.$model"
            :error="$v.request.country.$error ? 'Country is required' : ''"
            label="Country"
          ></k-input>
          <k-input
            v-model="$v.request.extraNotes.$model"
            :error="$v.request.extraNotes.$error ? 'Extra Notes is required' : ''"
            label="Extra Notes"
            variant="textarea"
          ></k-input>
        </div>
        <div class="btn-wrapper">
          <k-button
            variant="tertiary"
            @click="reset()"
           >Cancel</k-button>
          <k-button :loading="sendingRequest" submit @click="sendRequest">Submit</k-button>
        </div>
      </k-card>
    </k-modal>
    <!-- modal end -->

    <!-- Not Found MODAL -->
    <k-modal :open="notFound" middle @close="notFound = false">
      <k-card heading="" padInside smaller variant="in-modal">
        <div class="img-container">
          <img alt="" src="@/assets/attention.svg" />
        </div>
        <h1 class="modal__heading">Sorry! No dashboard available for {{ titleCase(country) }}</h1>
        <k-button size="full" @click="$router.push({ name: 'SelectCountry' })">
          Select Another
        </k-button>
      </k-card>
    </k-modal>
    <!-- Not Found MODAL end -->
  </div>
</template>

<script>
import Dashboard from './Dashboard';

export default Dashboard;
</script>

<style lang='scss' scoped src='./Dashboard.scss'></style>
