<template>
  <div class="dashboard__content">
    <div class="dashboard__content-header">
      <h3>Fact Check</h3>
      <k-button class="signin-btn" variant="short" @click="$router.push({ name: 'FactChecking' })">
        Create Document
      </k-button>
    </div>

    <div class="dashboard__content-wrapper" v-if="!loading">
      <div v-for="document in documents" :key="document.id" class="dashboard__content-item">
        <div
          style="cursor: pointer"
          @click="$router.push({ path: `/fact-checking?id=${document.query_id}` })"
        >
          <h4 class="dashboard__content-title">{{ document.title }}</h4>
          <span v-if="document.content.split('.', 1).length" class="dashboard__content-content">
            {{ document.content.split('.', 1)[0] }} ...
          </span>
        </div>

        <div>
          <div class="dashboard__content-actions">
            <div>
              <span> {{ document.extraction || 0 }} metrics checked </span>
            </div>
            <div class="dashboard__content-delete" @click="deleteDocuments(document.query_id)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#FF0000"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center; height: 30vh" v-else>
      <p>Please Wait...</p>
    </div>
  </div>
</template>

<script>
import Dashboard from './Dashboard';
export default Dashboard;
</script>

<style lang="scss" src="./Dashboard.scss"></style>
