<template>
  <div class="page">
    <about-header></about-header>
    <about-content></about-content>
    <website-footer></website-footer>
  </div>
</template>

<script>
import About from './About';

export default About;
</script>

<style lang="scss" scoped src="./About.scss">

</style>
