<template>
  <div>
    <div class="country-headers">
      <h3>Search country</h3>

      <p>Select a country to view relevant indicators.</p>
      <div class="country-input-wrapper">
        <div class="country-input">
          <img src="../../../assets/search-icon.svg" alt="kwerty" />
          <input
            type="text"
            placeholder="Enter Country Name"
            v-model="search"
            @click="isVisible = !isVisible"
          />
        </div>
        <div v-if="isVisible">
          <div class="search-suggestions" v-if="filteredCountries.length">
            <div v-for="country in filteredCountries" :key="country.name.common">
              <!-- countries with spaces in them have to be replaced with hyphens for the sake of the URL -->
              <router-link
                :to="`/countries/indicator/${country.name.common
                  .toLowerCase()
                  .split(' ')
                  .join('-')}`"
                ><p>{{ country.name.common }}</p></router-link
              >
              <!--  -->
            </div>
          </div>
          <div class="search-suggestions" v-else>
            <p>No search result found</p>
          </div>
        </div>
        <!-- this is responsible for the search suggestions box closing when there is a click outside it -->
        <div class="transparent-backdrop" v-if="isVisible" @click="isVisible = !isVisible"></div>
        <!--  -->
      </div>
    </div>

    <div class="background">
      <div class="continent-section">
        <Continents v-for="continent in continents" :key="continent" :continent="continent" />
      </div>
    </div>

    <section class="footer">
      <k-footer></k-footer>
    </section>
  </div>
</template>

<script>
import Countries from './Countries';

export default Countries;
</script>

<style lang="scss" scoped src="./Countries.scss"></style>
