<template>
  <div class="content__wrapper">
    <div class="container">
      <header class="content__header">
        <div class="coming-soon">
          <p>COMING SOON</p>
        </div>
        <h1 class="heading">Fact-Check Your Data</h1>
        <p class="sub-heading">
          Our algorithm checks through indicators and data in your content and fact-checks with
          accurate data. All you need to do is to post or type our data-driven content.
        </p>
      </header>
    </div>
    <section class="content__hero--section">
      <div class="content__hero--section-image">
        <div class="content__hero--section-image-item">
          <img src="@/assets/fact-check-image.jpg" alt="" />
        </div>
      </div>
      <div class="mapbase-background">
        <img src="@/assets/mapbase.png" alt="" />
      </div>
    </section>
    <k-footer class="footer"></k-footer>
  </div>
</template>

<script>
import KFooter from '../WebsiteFooter.vue';

export default {
  name: 'Learning',
  components: {
    KFooter,
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 100.6rem;
  padding: 0 2.2rem;
  width: 100%;
}

.content__wrapper {
  padding-top: 12.8rem;
  @media screen and (max-width: 768px) {
    padding-top: 8rem;
  }
  .content__header {
    max-width: 48.1rem;
    width: 100%;
    margin-bottom: 3.8rem;
    .heading {
      font-weight: 700;
      font-size: 3.6rem;
      line-height: 130%;
      letter-spacing: -0.02rem;
      color: $black;
    }
    .sub-heading {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 150%;
      color: $black;
      margin: 2.4rem 0 4.8rem 0;
    }
    .coming-soon {
      padding: 0.6rem 1.2rem;
      background-color: $primary-purple;
      color: $white;
      box-sizing: border-box;
      width: max-content;
      border-radius: 0.4rem;
      margin-bottom: 4rem;
      p {
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1.4rem;
        letter-spacing: 0.1rem;
      }
    }
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      // max-width: 100%;
      margin: auto;
      margin-bottom: 2rem;
      .heading {
        font-size: 2.8rem;
      }
      .sub-heading {
        margin: 1.6rem 0 0 0;
      }
      .coming-soon {
        margin-block: 2.2rem;
      }
    }
  }
  .content__hero--section {
    width: 100%;
    padding: 2rem 0;
    margin-top: 4rem;
    position: relative;
    @media (min-width: 768px) {
      margin-top: 6rem;
      padding: 0;
    }
    @media (min-width: 900px) {
      margin-top: 12rem;
    }
    .mapbase-background {
      max-width: 120rem;
      margin: auto;
      position: relative;
      z-index: 1;
      margin-top: -12rem;
      @media (min-width: 900px) {
        margin-top: -55rem;
      }
      img {
        width: 100%;
        height: 30rem;
        object-fit: cover;
        object-position: 35% 65%;
        @media (min-width: 768px) {
          object-fit: fill;
          object-position: 50% 50%;
        }
      }
    }
    &-image {
      max-width: 96.6rem;
      width: 100%;
      @media (min-width: 980px) {
        margin: auto;
      }
      &-item {
        width: 100%;
        padding: 0 1.5rem;
        box-shadow: -4px 6px 94px -12px rgba(70, 13, 60, 0.11);
        z-index: 7000;
        position: relative;
        img {
          width: 100%;
          height: auto;
          border-radius: 1.6rem;
        }
      }
    }
  }
  .footer {
    margin-top: -20rem;
    @media (min-width: 900px) {
      margin-top: 32rem;
    }
  }
}
</style>
