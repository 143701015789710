import { instance } from '@/config/api';

export const getIndicators = () => instance.get('/indicator');
export const getIndicatorsInCategory = (payload) => {
  const query = new URLSearchParams();
  Object.entries(payload).forEach(([key, value]) => {
    if (value) {
      query.append(key, value);
    }
  });
  return instance.get(`/indicator/filter?${query.toString()}`);
};

export const searchIndicators = ({ name }) => instance.get(`/indicator?search=${name}&limit=250`);
export const searchIndicatorsInCategory = ({ name, category }) => {
  const query = new URLSearchParams();
  query.append('name', name);
  query.append('category', category);
  return instance.get(`/indicator?${query.toString()}`);
};

export const searchSuggestions = ({ word }) =>
  // eslint-disable-next-line
  instance.get(`/search/suggest?q=${word}`).then((res) => res.data.data);
