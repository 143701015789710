<template>
  <div class='page'>
    <div class='latest'>
      <template v-if='isLoading'>
        <div class='container'>
          <div class='latest__image suspense'>

          </div>
        </div>
        <div class='info loading'>
          <div class='container'>
            <div class='suspense  mh-30 w-50 mb-5 space-above'></div>
            <div class='suspense  mh-30 w-50 mb-5'></div>
            <div class='meta suspense mh-20 meta-width'></div>
            <div class='author'>
              <img alt='' class='img suspense'
                   src=''>
              <span class='name suspense mh-10 fw-20'></span>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class='container'>
          <img :src='latest.content.image' alt=''
               class='latest__image'>
        </div>
        <div class='info'>
          <div class='container'>
            <h1 @click="$router.push({name: 'SingleBlog', params: {slug: latest.full_slug}})">
              {{ latest.name }}</h1>
            <div class='meta'>{{ formatDate(latest.created_at) }}</div>
            <div class='author'>
              <img :src='latest.content.author.image ? latest.content.author.image.filename: null'
                   alt=''>
              <span class='name'>{{ latest.content.author ? latest.content.author.name : 'Anonymous'
                }}</span>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class='container'>
      <div class='posts articles'>
        <h2>Articles</h2>
        <ul class='posts__list'>
          <template v-if='isLoading'>
            <li v-for='post in [1,2,3]' :key='post' class='loading'>
              <div class='post__image suspense'></div>
              <div class='title suspense mh-20 w-70'></div>
              <div class='meta suspense mh-20 w-50'></div>
            </li>
          </template>
          <template v-else>
            <li v-for='post in posts' :key='post.id'
                @click="$router.push({name: 'SingleBlog', params: {slug: post.full_slug}})">
              <img :src='post.content.image' alt=''>
              <div class='title'>{{ post.name }}</div>
              <div class='meta'>{{ formatDate(post.created_at) }}</div>
            </li>
            <template v-if='fetching'>
              <li v-for='post in [1,2,3]' :key='post' class='loading'>
                <div class='post__image suspense'></div>
                <div class='title suspense mh-20 w-70'></div>
                <div class='meta suspense mh-20 w-50'></div>
              </li>
            </template>
          </template>
        </ul>
        <div class='btn-container'>
          <k-button short variant='tertiary'
                    v-if='!reachedEnd'
                    @click='fetchNext()'
                    :loading='fetching'>View More</k-button>
        </div>
      </div>
      <div class='posts whitepapers'>
        <h2>Whitepapers</h2>

        <ul class='posts__list'>
          <template v-if='isLoading'>
            <li v-for='post in [1,2,3]' :key='post' class='loading'>
              <div class='post__image suspense'></div>
              <div class='title suspense mh-20 w-70'></div>
              <div class='meta suspense mh-20 w-50'></div>
            </li>
          </template>
          <template v-else>
            <li v-for='post in whitepapers' :key='post.id'>
              <a :href='post.content.document.filename' rel='noreferrer' target='_blank'>
                <img :src='post.content.image.filename' alt=''>
                <div class='title'>{{ post.name }}</div>
                <div class='meta'>{{ getExtension(post.content.document.filename).toUpperCase() }}
                  file
                  &middot;
                  {{ post.content.total_pages }} pages
                </div>
              </a>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <website-footer></website-footer>
  </div>
</template>

<script>
import Blog from './Blog';

export default Blog;
</script>

<style lang='scss' scoped src='./Blog.scss'>

</style>
