<template>
  <k-auth title="Sign up.">
    <template v-slot:description>
      <p>
        Have an account?
        <span><router-link :to="`/login?next=${next}`">Please, sign in,</router-link></span>
      </p>
    </template>
    <form class="form__items" @submit.prevent="signupUser()">
      <k-input v-model="user.fullName" label="Full Name" name="fullname" type="text" />
      <k-input
        v-model="user.email"
        :error="error.email"
        label="Your email address"
        name="email"
        type="email"
      />
      <k-input
        v-model="user.password"
        :error="error.password"
        label="Create Password"
        name="password"
        type="password"
        variant="password"
      ></k-input>
      <k-input
        v-model="user.confirm_password"
        :error="error.confirm_password"
        label="Confirm Password"
        type="password"
        variant="password"
        name="confirm_password"
      ></k-input>
      <k-button :disabled="formInvalid" :loading="isLoading" size="full" submit
        >Create Your Account</k-button
      >
    </form>
    <template v-slot:foot-text>
      <p>
        By creating your account, you agree to our
        <span><router-link :to="{ name: 'KwertyLegal' }">Terms and Conditions</router-link></span>
        and <span><router-link :to="{ name: 'KwertyLegal' }">Privacy Policy</router-link></span>
      </p>
    </template>
  </k-auth>
</template>

<script>
import Signup from './Signup';

export default Signup;
</script>

<style lang="scss" scoped src='./Signup.scss'></style>
