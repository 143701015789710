<template>
  <main class="legal">
    <div class="legal__body">
      <aside class="legal__menu">
        <div class="legal__menu--item">
          <a href="#cookie-policy">Cookie Policy</a>
          <a href="#privacy-policy">Privacy Policy</a>
          <a href="#terms-services">Terms of Services</a>
        </div>
      </aside>
      <div class="legal__content">
        <section class="section__one">
          <h1 id="cookie-policy">Cookie Policy</h1>
          <cookie-policy></cookie-policy>
        </section>
        <section class="section__two">
          <h1 id="privacy-policy">Privacy Policy for Kwerty</h1>
          <privacy-policy></privacy-policy>
        </section>
        <section class="section__three">
          <h1 id="terms-services">Terms of Service for Kwerty</h1>
          <terms-services></terms-services>
        </section>
      </div>
    </div>
    <div class="footer">
      <k-footer></k-footer>
    </div>
  </main>
</template>

<script>
import CookiePolicy from './CookiePolicy.vue';
import PrivacyPolicy from './PrivacyPolicy.vue';
import TermsServices from './TermsOfServices.vue';
import KFooter from '../WebsiteFooter.vue';

export default {
  name: 'Legal',
  components: {
    CookiePolicy,
    PrivacyPolicy,
    TermsServices,
    KFooter,
  },
};
</script>

<style lang="scss" scoped>
.footer {
  max-width: 96.6rem;
  width: 100%;
  margin: auto;
}
.legal {
  &__body {
    display: flex;
    flex-direction: column;
    padding: 0 3.2rem;
    margin: 0 0 2rem 0;
  }
  &__menu {
    display: none;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    left: 5rem;
    top: 12.8rem;
    &--item {
      display: flex;
      flex-direction: column;
    }
    a {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 150%;
      color: #808080;
      margin-bottom: 1.5rem;
      cursor: pointer;
    }
    @media (min-width: 768px) {
      display: flex;
    }
  }
  &__content {
    max-width: 76.6rem;
    h1 {
      font-weight: 700;
      font-size: 3.2rem;
      line-height: 120%;
      letter-spacing: -0.02rem;
      color: $black;
      margin-bottom: 2.4rem;
      padding-top: 10rem;
    }
    #cookie-policy {
      padding-top: 13.2rem;
    }
  }
  @media (min-width: 768px) {
    &__body {
      display: flex;
    }
    &__menu {
      left: 10rem;
    }
    &__content {
      margin-left: 24rem;
    }
  }
  @media (min-width: 1200px) {
    &__body {
      display: flex;
    }
    &__menu {
      left: 20rem;
      top: 12.8rem;
      &--item {
        display: flex;
        flex-direction: column;
      }
    }
    &__content {
      width: 100%;
      max-width: 76.6rem;
      margin-left: 38rem;
      h1 {
        font-weight: 700;
        font-size: 3.2rem;
        line-height: 120%;
        letter-spacing: -0.02rem;
        color: $black;
        margin-bottom: 2.4rem;
        padding-top: 10rem;
      }
      #cookie-policy {
        padding-top: 13.2rem;
      }
    }
  }
}
</style>
