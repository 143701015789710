import { advancedSearch } from '@/api';
import debounce from '@/utils/debounce';

const rank = (item, words) => {
  if (typeof item !== 'string') return 0;
  let count = 0;
  words.forEach((w) => {
    if (item.toLowerCase().includes(w)) count += 1;
  });
  return count;
};

const byRank = (keywords) => (d1, d2) => {
  const rank2 = rank(d2.nameOfIndicator + d2.country, keywords);
  const rank1 = rank(d1.nameOfIndicator + d1.country, keywords);
  return rank2 - rank1;
};

function searchSuggestions(val) {
  debounce(async () => {
    const search = val.trim();
    const keywords = search.split(' ')
      .filter((w) => w && w.length > 2)
      .map((w) => w.toLowerCase());
    if (search && search.length > 2 && keywords.length) {
      this.isSearching = true;
      const searches = keywords.map((w) => advancedSearch({
        indicator: w,
        limit: '20',
      }));
      const resultList = await Promise.all(searches);
      let result = [];
      resultList.forEach((r) => {
        result = [...result, ...r.data.data.dataset.map((d) => d).filter((d) => d.isPublished)];
      });
      result.sort(byRank(keywords));
      const indicatorNames = Array.from(new Set(result.map((d) => d.nameOfIndicator)));
      this.suggestions = indicatorNames.map((name) => ({ searchTerm: name }));
      this.isSearching = false;
    }
  })(val);
}

export default searchSuggestions;
