function escapeHtml(text) {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;',
  };

  return text.replace(/[&<>"']/g, (m) => map[m]);
}

const convert = (obj) => {
  const elementTags = (tag, attrs = false) => {
    const tags = {
      doc: 'DIV',
      paragraph: 'P',
      text: 'SPAN',
      heading: {
        1: 'H1',
        2: 'H2',
        3: 'H3',
        4: 'H4',
        5: 'H5',
        6: 'H6',
      },
      bullet_list: 'UL',
      ordered_list: 'OL',
      list_item: 'LI',
      code_block: 'CODE',
      blockquote: 'BLOCKQUOTE',
      image: 'IMG',
    };

    if (attrs && attrs.level) {
      return `${tags[tag][attrs.level]}`;
    }
    return tags[tag];
  };
  let element = document.createElement(elementTags(obj.type, obj.attrs));

  if (obj.attrs) {
    Object.entries(obj.attrs).forEach(([key, value]) => {
      if (key !== 'level') {
        element.setAttribute(key, value);
      }
    });
  }

  if (!obj.content) {
    if (obj.type === 'text') {
      element.innerHTML = obj.text;

      if (obj.marks && obj.marks.length) {
        // add styles for all marks
        obj.marks.forEach((mark) => {
          // inline code element
          if (mark.type === 'code') {
            element = document.createElement('CODE');
            element.innerHTML = escapeHtml(obj.text);
            element.setAttribute('class', 'language-');
            // link element
          } else if (mark.type === 'link') {
            element = document.createElement('A');
            element.innerHTML = obj.text;
            if (mark.attrs) {
              Object.entries(mark.attrs).forEach((attr) => {
                element.setAttribute(attr[0], attr[1]);
              });
            }
          }
          element.classList.add(`mark-${mark.type}`);
        });
      }
    }

    if (obj.type === 'image') {
      Object.entries(obj.attrs).forEach((attr) => {
        element.setAttribute(attr[0], attr[1]);
      });
    }

    return element;
  }

  obj.content.forEach((item) => {
    element.appendChild(convert(item));
  });

  // Wrap code_blocks in <pre> tags
  if (obj.type === 'code_block') {
    const container = document.createElement('PRE');
    container.appendChild(element);
    container.classList = element.classList;
    element = container;
  }
  return element;
};

export default convert;
