export default {
  all: [
    {
      id: 'fgsawyfcghyegehhe',
      indicator: 'Central Budget',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fghfghbkyfcghyegehhe',
      indicator: 'Primary Income ',
      country: 'Norway',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fghdjkyfjhyegehhe',
      indicator: 'Current Account to GDP',
      country: 'Norway',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fghertfcghyegehhe',
      indicator: 'Direct Investment (Assets)',
      country: 'Italy',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fgxcvyfcghyegehhe',
      indicator: 'Direct Investment (Liabilities)',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fghdjkyfcghyegehhe',
      indicator: 'Portfolio Investments (Liabilities)',
      country: 'Norway',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fghdjkyfcghtryhhe',
      indicator: 'Official Reserve Assets',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fghdjkyfcgghjehhe',
      indicator: 'Population Men ',
      country: 'Italy',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fghdjkyfctyuigehhe',
      indicator: 'Population Women',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fghdjkyfcgrtyugehhe',
      indicator: 'Urban population',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fghdjkyfghjyegehhe',
      indicator: 'Urban Population % of total Population ',
      country: 'Norway',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fghdjkyfcghtfghhe',
      indicator: 'Marriages',
      country: 'Italy',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fghdjkyfcgfghgehhe',
      indicator: 'Legal Separations',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fghdjkyfcghjigehhe',
      indicator: 'Death by Causes (System)',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fghdjkyfcghyegehhe',
      indicator: 'Urban Population % of total Population 2',
      country: 'Norway',
      startYear: '1985',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fghdjkyfcghtyukhhe',
      indicator: 'Marriages 2',
      country: 'Italy',
      startYear: '1985',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fghdjkyfcfghgehhe',
      indicator: 'Legal Separations 2',
      country: 'Poland',
      startYear: '1985',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 'fghdjkyfcgfcvbehhe',
      indicator: 'Death by Causes (System) 2',
      country: 'Poland',
      startYear: '1985',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
  ],
  published: [
    {
      id: 18,
      indicator: 'Primary Income ',
      country: 'Norway',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 19,
      indicator: 'Portfolio Investments (Liabilities)',
      country: 'Norway',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 20,
      indicator: 'Official Reserve Assets',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 21,
      indicator: 'Population Men ',
      country: 'Italy',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 22,
      indicator: 'Urban population',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 23,
      indicator: 'Marriages',
      country: 'Italy',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 24,
      indicator: 'Legal Separations',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 25,
      indicator: 'Death by Causes (System)',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 26,
      indicator: 'Death without the exact cause',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 27,
      indicator: 'Internal Migration for Permanent Residence',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
  ],
  drafts: [
    {
      id: 28,
      indicator: 'Central Budget',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 29,
      indicator: 'Direct Investment (Assets)',
      country: 'Italy',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 30,
      indicator: 'Portfolio Investments (Liabilities)',
      country: 'Norway',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 31,
      indicator: 'Population Men ',
      country: 'Italy',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 33,
      indicator: 'Urban population',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 34,
      indicator: 'Death by Causes (System)',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 35,
      indicator: 'Death without the exact cause',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 36,
      indicator: 'Internal Migration for Permanent Residence',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },
  ],
  deleted: [
    {
      id: 37,
      indicator: 'Death by Causes (System)',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: '4:32 PM, 22-02-2021',
    },

  ],
  indicators: [
    {
      id: 38,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 39,
      indicator: 'Capital Account',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 40,
      indicator: 'Agricultural Land',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 41,
      indicator: 'Agricultural Budget',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 42,
      indicator: 'Life Expectancy - Man',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 43,
      indicator: 'Life Expectancy - Woman',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 44,
      indicator: 'Marriages',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 45,
      indicator: 'Divorces',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 46,
      indicator: 'Legal Separation',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 47,
      indicator: 'Live Births',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 48,
      indicator: 'Value Added Tax',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 49,
      indicator: 'Total Population',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 50,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 51,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 52,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 53,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 54,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 55,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 56,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
    {
      id: 57,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: '4:32 PM, 22-02-2021',
    },
  ],
  single: {
    id: 1,
    indicator: 'Central Budget',
    category: 'Economy',
    country: 'Poland',
    startYear: '1986',
    endYear: '2020',
    lastModified: '4:32 PM, 22-02-2021',
    metric: 'United States Dollar',
    frequency: 'Yearly',
    source: 'World Bank',
    link: 'bit.ly/21SHajes10',
    // eslint-disable-next-line
    note: `This is a summarized analysis of the data set. When it is empty, it will not show up on the customer end. It usually talks about the trends of the chart you’re seeing. Sometimes, it could talk about the history, focus on an anomaly or suggest what else to look at to really understand it. It is never a comprehensive tool to understand the data we share.`,
    data: [
      '{ "period": "2000", "value": "" } ',
      '{ "period": "2001", "value": "" } ',
      '{ "period": "2002", "value": "24,055.90" } ',
      '{ "period": "2003", "value": "27,648.40" } ',
      '{ "period": "2004", "value": "33,208.50" } ',
      '{ "period": "2005", "value": "34,617.90" } ',
      '{ "period": "2006", "value": "36,815.50" } ',
      '{ "period": "2007", "value": "49,824.10" } ',
      '{ "period": "2008", "value": "57,177.40" } ',
      '{ "period": "2009", "value": "44,663.00" } ',
      '{ "period": "2010", "value": "47,255.90" } ',
      '{ "period": "2011", "value": "52,701.00" } ',
      '{ "period": "2012", "value": "57,949.30" } ',
      '{ "period": "2013", "value": "47,520.70" } ',
      '{ "period": "2014", "value": "55,332.20" } ',
      '{ "period": "2015", "value": "55,785.40" } ',
      '{ "period": "2016", "value": "57,795.30" } ',
      '{ "period": "2017", "value": "64.378.0" } ',
      '{ "period": "2018", "value": "77,143.70" } ',
      '{ "period": "2019", "value": "78,902.80" } ',
      '{ "period": "2020", "value": "78,498.80" } ',
      '{ "period": "2021", "value": "86,967.40" }',
    ],
    tags: [
      'budget',
      'poland',
      'central budget',
      'dollar',
      'gross domestic product',
      'gdp',
      'world bank data',
    ],
  },
  relevant: [
    {
      id: 1,
      title: 'Nigeria: Public Debt, 1960 - 2008',
      description: 'This is a 3- line snippet of the data chart analysis will be shown here describing what the data chart means, so that the user knows what’s up about this particular dataset ...',
      tags: 'indicators, public debt, nigeria',
      chart: true,
    }, {
      id: 2,
      title: 'How Nigeria Public Debt Affected Exports',
      description: 'Here’s is where the description from the blog article/whitepaper is going to show up for people to understand and read from. It can be 2 or 3 lines obviously.',
      tags: 'blog, whitepaper, how-nigeria-public-debt-affected-exports',
      chart: false,
    },
  ],
  related: [
    {
      id: 1,
      title: 'Nigeria: Gross Domestic Product, 1999 - 2021',
      description: 'Based on the data searched for, examples like this one can come up — where we present closely related results to the user. It’ll be categorized appropriately too.',
      tags: 'indicators, gdp, nigeria',
      chart: true,
    }, {
      id: 2,
      title: 'Nigeria: Capital Importation, 2019 - 2021',
      description: 'The total value of capital importation into Nigeria stood at $5,820.21m in the second quarter of 2019. This represents a decrease of -31.41% compared to Q1 2019 and +5.56% ...',
      tags: 'indicators, capital importation, nigeria',
      chart: true,
    }, {
      id: 3,
      title: 'Nigeria: Capital Importation, 2019 - 2021',
      description: 'The total value of capital importation into Nigeria stood at $5,820.21m in the second quarter of 2019. This represents a decrease of -31.41% compared to Q1 2019 and +5.56% ...',
      tags: 'indicators, capital importation, nigeria',
      chart: true,
    },
  ],
  popularSearches: [
    'what is nigeria gdp?',
    'what is public debt of africa?',
    'per capita income in nigeria',
    'what is the highest public debt of Nigeria',
    'imports and exports nigeria',
    'inflation rate nigeria',
  ],
};
