<template>
  <div :class="{ 'content__wrapper': radial}" @click='sendClickToLabel'>
    <div :class="['wrapper',
  {
    'wrapper--radial' : radial,
  }
  ]">
      <input
        :id='name'
        v-model='innerValue'
        :class="[
        'checkbox__input',
        {
          [`checkbox__input--${fill}`]: fill,
          ['checkbox__input--radial'] : radial,
        },
      ]"
        :name='name'
        :value='value'
        type='checkbox'
      />
      <label :for='name' class='checkbox__label'>{{ label }}</label>
    </div>
  </div>
</template>

<script>
import KCheckbox from './Checkbox';

export default KCheckbox;
</script>

<style lang='scss' scoped src='./Checkbox.scss'></style>
