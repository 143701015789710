<template>
  <div class="media-screen__container">
    <!-- <section class="home">
      <div class="home__heading">
        <div class="home__heading--text">
          <carousel :per-page="1" autoplay loop :paginationEnabled="false" :mouse-drag="false">
            <slide
              ><h1 class="title" ref="mainTitle">
                Empower Your Words with Precision: Data Fact-Checking for Everyone
              </h1>
              <p class="description" ref="description">
                Unveil the Truth Behind the Numbers – Elevate Your Writing with Our Data
                Fact-Checking Tool
              </p>
            </slide>
            <slide>
              <h1 class="title" ref="mainTitle">
                Economic Insights at a Glance: Explore Country Dashboards
              </h1>
              <p class="description" ref="description">
                Navigating Economic Landscapes Made Simple
              </p>
            </slide>
            <slide>
              <h1 class="title" ref="mainTitle">
                Empowering Insight: Explore Economic Indicators with Precision
              </h1>
              <p class="description" ref="description">
                Uncover Key Data Trends through Intuitive Visualization and Advanced Search
              </p>
            </slide>
          </carousel>
          <div class="btn__wrapper" ref="ctaContainer">
            <template v-if="!getToken">
              <k-button
                class="signin-btn"
                variant="tertiary"
                @click="$router.push({ name: 'FactChecking' })"
              >
                Fact Check
              </k-button>
              <k-button class="getStarted" @click="$router.push({ name: 'Signup' })"
                >Get Started for Free
              </k-button>
            </template>
            <template v-else>
              <k-button variant="tertiary" @click="$router.push({ name: 'Search' })">
                Data Search
              </k-button>
              <k-button @click="$router.push({ name: 'Search', hash: '#advanced' })">
                Advanced Search
              </k-button>
            </template>
          </div>
        </div>
      </div>
    </section> -->
    <!-- <section class="home-hero" ref="aboutSection">
      <div class="background"></div>
      <div class="container">
        <div class="background-image" ref="aboutContent">
          <div class="background-image-text">
            <p>
              The world is in need of data services, accompanied by fluid and seamless experience to
              make core decisions.
            </p>
            <k-button class="btn" variant="tertiary" @click="$router.push({ name: 'About' })">
              Learn More About Us
            </k-button>
          </div>
        </div>
      </div>
    </section> -->
    <section class="section__one" ref="sectionTwo">
      <div class="container highlights">
        <!-- item -->
        <div class="highlights--item with--color4 r5" ref="trigger3">
          <img alt="" class="img2" src="@/assets/factCheck.png" ref="img2" />
        </div>
        <!-- item -->
        <div class="highlights--item content--card r6">
          <div class="content--card-item" ref="embedContent">
            <h2>Don't leave your economic decisions to chance.</h2>
            <p class="subtitle">
              Embrace the power of accuracy with our
              <span style="font-weight: 700; color: #900f7a">Factcheck</span> and unlock a world of
              reliable economic insights. Knowledge is the key to prosperity, and we're here to
              ensure you have it.
            </p>
            <k-button variant="tertiary" @click="$router.push({ name: 'FactChecking' })"
              >Get Started for Free
            </k-button>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="section__layout">
          <div class="heading--content">
            <h1 class="heading" ref="sectionTwoHead">
              Find any country’s <br />
              data in less than 2 mins
            </h1>
            <p class="title" ref="sectionTwoDesc">
              We took care of the hassle of researching public data.
            </p>
          </div>
        </div>
        <div class="highlights">
          <!-- item -->
          <div ref="trigger" class="highlights--item background with--color1 r1">
            <svg
              ref="findSvg"
              class="search-img"
              fill="none"
              height="258"
              viewBox="0 0 406 258"
              width="406"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_dd_1731_4189)">
                <path :d="searchSvg.path1" fill="white" />
                <path ref="text" :d="searchSvg.path2" fill="#666666" />
                <path :d="searchSvg.path3" />
                <rect
                  ref="search"
                  height="38"
                  rx="7.5"
                  stroke="#D9D9D9"
                  width="283"
                  x="56.5"
                  y="119.5"
                />
                <rect ref="rect1" fill="#F2F2F2" height="12" rx="6" width="51" x="289" y="171" />
                <rect ref="rect2" fill="#F2F2F2" height="12" rx="6" width="154" x="186" y="191" />
                <rect ref="rect3" fill="#F2F2F2" height="12" rx="6" width="208" x="132" y="211" />
                <rect ref="rect4" fill="#F2F2F2" height="12" rx="6" width="51" x="222" y="171" />
              </g>
              <defs>
                <filter
                  id="filter0_dd_1731_4189"
                  color-interpolation-filters="sRGB"
                  filterUnits="userSpaceOnUse"
                  height="280"
                  width="406"
                  x="0"
                  y="0"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dx="7" dy="7" />
                  <feGaussianBlur stdDeviation="7.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                  />
                  <feBlend
                    in2="BackgroundImageFix"
                    mode="normal"
                    result="effect1_dropShadow_1731_4189"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dx="-7" dy="-7" />
                  <feGaussianBlur stdDeviation="7.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                  />
                  <feBlend
                    in2="effect1_dropShadow_1731_4189"
                    mode="normal"
                    result="effect2_dropShadow_1731_4189"
                  />
                  <feBlend
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1731_4189"
                    mode="normal"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
          <!-- item -->
          <div class="highlights--item content--card r2">
            <div class="content--card-item" ref="searchContent">
              <h2>
                Use your own words to <br />
                search for any kind of data
              </h2>
              <p class="subtitle">
                Have a question? Looking to confirm a statement? Type it into the search box and get
                access to data, charts, articles and whitepapers in less than 5 seconds.
              </p>
              <k-button variant="tertiary" @click="$router.push({ name: 'Search' })">
                Get Started with Search
              </k-button>
            </div>
          </div>

          <!-- item -->
          <div ref="trigger2" class="highlights--item background with--color2 r4">
            <svg
              fill="none"
              height="250"
              viewBox="0 0 394 250"
              width="394"
              xmlns="http://www.w3.org/2000/svg"
              ref="compare"
            >
              <g filter="url(#filter0_dd_1731_4601)">
                <path :d="compareSvg.path1" fill="white" />
                <path :d="compareSvg.path2" fill="#666666" />

                <g ref="compLeft">
                  <g ref="compLeftCirc">
                    <circle cx="73" cy="118" r="8.75" stroke="#900F7B" stroke-width="0.5" />
                    <circle cx="71" cy="116" fill="#900F7B" r="9" />
                  </g>
                  <rect height="38" rx="7.5" stroke="#D9D9D9" width="117" x="56.5" y="97.5" />
                </g>
                <g ref="compRight">
                  <g ref="compRightCirc">
                    <circle cx="239" cy="118" r="8.75" stroke="#0F2B90" stroke-width="0.5" />
                    <circle cx="237" cy="116" fill="#0F2B90" r="9" />
                  </g>
                  <rect height="38" rx="7.5" stroke="#D9D9D9" width="117" x="222.5" y="97.5" />
                </g>
                <g ref="rectGroup">
                  <rect
                    fill="#F2F2F2"
                    height="12"
                    rx="6"
                    transform="rotate(-90 75 220)"
                    width="52"
                    x="75"
                    y="220"
                  />
                  <rect
                    fill="#F2F2F2"
                    height="11"
                    rx="5.5"
                    transform="rotate(-90 134 220)"
                    width="42"
                    x="134"
                    y="220"
                  />
                  <rect
                    fill="#F2F2F2"
                    height="12"
                    rx="6"
                    transform="rotate(-90 192 220)"
                    width="52"
                    x="192"
                    y="220"
                  />
                  <rect
                    fill="#F2F2F2"
                    height="11"
                    rx="5.5"
                    transform="rotate(-90 251 220)"
                    width="52"
                    x="251"
                    y="220"
                  />
                  <rect
                    fill="#F2F2F2"
                    height="12"
                    rx="6"
                    transform="rotate(-90 309 220)"
                    width="42"
                    x="309"
                    y="220"
                  />
                  <rect
                    fill="#F2F2F2"
                    height="12"
                    rx="6"
                    transform="rotate(-90 95 220)"
                    width="69"
                    x="95"
                    y="220"
                  />
                  <rect
                    fill="#F2F2F2"
                    height="12"
                    rx="6"
                    transform="rotate(-90 153 220)"
                    width="57"
                    x="153"
                    y="220"
                  />
                  <rect
                    fill="#F2F2F2"
                    height="11"
                    rx="5.5"
                    transform="rotate(-90 212 220)"
                    width="52"
                    x="212"
                    y="220"
                  />
                  <rect
                    fill="#F2F2F2"
                    height="12"
                    rx="5"
                    transform="rotate(-90 270 220)"
                    width="10"
                    x="270"
                    y="220"
                  />
                  <rect
                    fill="#F2F2F2"
                    height="12"
                    rx="6"
                    transform="rotate(-90 328 220)"
                    width="62"
                    x="328"
                    y="220"
                  />
                  <rect
                    fill="#F2F2F2"
                    height="12"
                    rx="6"
                    transform="rotate(-90 56 220)"
                    width="17"
                    x="56"
                    y="220"
                  />
                  <rect
                    fill="#F2F2F2"
                    height="12"
                    rx="6"
                    transform="rotate(-90 114 220)"
                    width="37"
                    x="114"
                    y="220"
                  />
                  <rect
                    fill="#F2F2F2"
                    height="11"
                    rx="5.5"
                    transform="rotate(-90 173 220)"
                    width="30"
                    x="173"
                    y="220"
                  />
                  <rect
                    fill="#F2F2F2"
                    height="12"
                    rx="6"
                    transform="rotate(-90 231 220)"
                    width="37"
                    x="231"
                    y="220"
                  />
                  <rect
                    fill="#F2F2F2"
                    height="12"
                    rx="6"
                    transform="rotate(-90 289 220)"
                    width="24"
                    x="289"
                    y="220"
                  />
                </g>
              </g>
              <defs>
                <filter
                  id="filter0_dd_1731_4601"
                  color-interpolation-filters="sRGB"
                  filterUnits="userSpaceOnUse"
                  height="272"
                  width="394"
                  x="0"
                  y="0"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dx="7" dy="7" />
                  <feGaussianBlur stdDeviation="7.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                  />
                  <feBlend
                    in2="BackgroundImageFix"
                    mode="normal"
                    result="effect1_dropShadow_1731_4601"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dx="-7" dy="-7" />
                  <feGaussianBlur stdDeviation="7.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                  />
                  <feBlend
                    in2="effect1_dropShadow_1731_4601"
                    mode="normal"
                    result="effect2_dropShadow_1731_4601"
                  />
                  <feBlend
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1731_4601"
                    mode="normal"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>

          <!-- item -->
          <div class="highlights--item content__card r3">
            <div class="content__card-item" ref="compareContent">
              <h2>Compare data across countries, indicators & categories</h2>
              <p class="subtitle">
                Want to study how Nigeria’s Labour Market affect Ghana Immigration Data? You can
                compare both data here — for free.
              </p>
              <k-button
                variant="tertiary"
                @click="$router.push({ name: 'Search', hash: '#advanced' })"
              >
                Go to Advanced Search
              </k-button>
            </div>
          </div>
          <!-- item -->
          <div class="highlights--item with--color3 r5" ref="trigger3">
            <img
              alt=""
              class="img1"
              src="https://one-percent-bucket-1.s3.us-west-2.amazonaws.com/kwertyassets/Push%20notifications.png"
              ref="img1"
            />
            <img alt="" class="img2" src="@/assets/highlight-3.png" ref="img2" />
          </div>
          <!-- item -->
          <div class="highlights--item content--card r6">
            <div class="content--card-item" ref="embedContent">
              <h2>Share, embed and download your own style</h2>
              <p class="subtitle">
                Edit your choice data to your taste. You can customize titles, add author’s notes,
                choose the kind of data visualization you want, and more.
              </p>
              <k-button variant="tertiary" @click="$router.push({ name: 'Signup' })"
                >Get Started for Free
              </k-button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section__two">
      <div class="container">
        <div class="section__layout">
          <div class="heading--content" ref="kwertyFor">
            <h1 class="heading">How does Kwerty work for you?</h1>
            <p class="title">
              Work with globally certified data professionals and consultants to setup structure for
              better tracking and reporting.
            </p>
          </div>
        </div>
        <div class="section__two-card-holder" ref="kwertyForCards">
          <div class="card">
            <div class="card-icon">
              <img alt="icon" src="@/assets/card-icon.svg" />
            </div>
            <p class="title">
              Kwerty for <br />
              Institutions
            </p>
            <div class="subtitle-wrapper">
              <p class="subtitle">
                Learn how we can optimise your data infrastructure, personnel and technology needs
              </p>
            </div>
            <k-button class="btn" @click="$router.push({ name: 'KwertyInstitutions' })"
              >Learn More
            </k-button>
          </div>
          <div class="card">
            <div class="card-icon">
              <img alt="icon" src="@/assets/card-icon-person.svg" />
            </div>
            <p class="title">
              Kwerty for <br />
              Leaders
            </p>
            <div class="subtitle-wrapper">
              <p class="subtitle">
                We are raising new leaders in the data analysis and visualization field
              </p>
            </div>
            <k-button class="btn" @click="$router.push({ name: 'KwertyLeader' })"
              >Learn More
            </k-button>
          </div>
          <div class="card">
            <div class="card-icon">
              <img alt="icon" src="@/assets/kwerty-for-writers.svg" />
            </div>
            <p class="title">
              Kwerty for <br />
              Writers
            </p>
            <div class="subtitle-wrapper">
              <p class="subtitle">Our fact-checking tool provides accurate data for pieces</p>
            </div>
            <k-button class="btn" @click="$router.push({ name: 'KwertyLearning' })"
              >Learn More
            </k-button>
          </div>
        </div>
      </div>
    </section>
    <section class="section__three">
      <k-partners></k-partners>
    </section>
    <section class="footer">
      <k-footer></k-footer>
    </section>
  </div>
</template>

<script>
import Home from './Home';

export default Home;
</script>

<style lang="scss" scoped src="./Home.scss"></style>
