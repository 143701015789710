<template>
  <div class="content__wrapper">
    <div class="container">
      <header class="content__header">
        <div class="content__header--item">
          <h1 class="heading">Co-build a Data-Centric Organization With Us</h1>
          <p class="sub-heading">
            With combined experience of 25 years, we can help your organization focus on the key
            metrics and environment that can scale your work.
          </p>
          <a href="#contact--us">
            <k-button>Contact Us</k-button>
          </a>
        </div>
      </header>
    </div>
    <section class="content__hero--section">
      <div class="mapbase-background">
        <img src="@/assets/mapbase.png" alt="" />
      </div>
      <div class="content__hero--section-image">
        <div class="content__hero--section-image-item">
          <img src="@/assets/background.jpg" alt="" />
        </div>
        <div class="content__hero--section-image-text">
          <p>
            We believe that a full integrated investment in data infrastructure, collection
            methodologies, and rigorous training are required if public and private institutions are
            required to provide optimal results for their stakeholders.
          </p>
          <k-button @click="$router.push({ name: 'Login' })" class="btn" variant="tertiary"
            >Learn More About Us</k-button
          >
        </div>
      </div>
    </section>
    <section class="section__one">
      <div class="container">
        <h2 class="title">Our Approach</h2>
        <div class="section__one--articles">
          <div class="article">
            <span>01</span>
            <div class="article-item">
              <h2 class="title">Engagement with Leadership</h2>
              <p class="description">
                We fully assess the challenges, opportunities and pathways to strengthening the
                capacity of the bureaucracy including the statistical agencies especially across
                Africa.
              </p>
            </div>
          </div>
          <div class="article">
            <span>02</span>
            <div class="article-item">
              <h2 class="title">Strategy Development & Review</h2>
              <p class="description">
                We also review strategies which include required infrastructure, human resource,
                appreciation of technology and collection approach to ensure continuous delivery of
                quality data services for the citizens and how to fully communicate impact and
                insights.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section__two">
      <div class="container">
        <div class="section__two--layout">
          <picture>
            <source
              media="(max-width: 768px)"
              :srcset="mobileImage"
              alt="picture"
              class="mobile-image"
            />
            <img :src="desktopImage" alt="picture" class="image" />
          </picture>
          <div class="section__two--layout-text">
            <div class="text-wrapper">
              <h2 class="title">What We Can Do</h2>
              <p class="description">
                Our value proposition to you is to build a data-centric organization that optimises
                resources for stakeholders. Our services include:
              </p>
              <ul>
                <li>Data Audit for Institutions</li>
                <li>Strengthening Statistical Capacity of Private and Public Institutions</li>
                <li>Design and Deployment of Data Infrastructure</li>
                <li>Design of Organization Data Structures and Strategy</li>
                <li>
                  Data Innovation Technologies including Machine Learning, AI and Data Analytics
                  Platforms
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <k-partners></k-partners>
    <section id="contact--us" ref="contactUs" class="section__four">
      <div class="container">
        <h2 class="heading">Contact Us</h2>
        <p class="description">Reach out to us on your needs by filling this form:</p>
        <form class="form__items" @submit.prevent="sendRequest">
          <k-input
            label="Name"
            v-model="$v.ticket.name.$model"
            :error="$v.ticket.name.$error ? 'Name is required' : ''"
          ></k-input>
          <k-input
            label="Organization"
            v-model="$v.ticket.organization.$model"
            :error="$v.ticket.organization.$error ? 'Organization is required' : ''"
          ></k-input>
          <k-input
            label="Purpose"
            v-model="$v.ticket.purpose.$model"
            :error="$v.ticket.purpose.$error ? 'Purpose is required' : ''"
          ></k-input>
          <k-input
            label="Email Address"
            v-model="$v.ticket.email.$model"
            :error="$v.ticket.email.$error ? 'Email is required' : ''"
          ></k-input>
          <k-input
            type="phone"
            label="Phone Number (optional)"
            v-model="$v.ticket.phone.$model"
            :error="$v.ticket.phone.$error ? 'Phone number can only contain numeric values' : ''"
          ></k-input>
          <k-button :loading="isSending" submit>Submit & Send</k-button>
        </form>
      </div>
      <k-modal v-if="openModal" :open="openModal" @close="openModal = false">
        <div class="kcard">
          <div class="icon">
            <svg
              width="47"
              height="47"
              viewBox="0 0 47 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.519 26.7043L25.2574 29.4427L41.6763
              13.0238L44.4186 15.7661L25.2574 34.9273L12.9151
              22.585L15.6574 19.8427L19.7786 23.9639L22.519
              26.7023V26.7043ZM22.5229 21.2197L32.1267 11.6138L34.8613
              14.3484L25.2574 23.9542L22.5229 21.2197ZM17.0402 32.1869L14.2998
              34.9273L1.95752 22.585L4.69982 19.8427L7.44019 22.583L7.43825 22.585L17.0402
              32.1869V32.1869Z"
                fill="#900F7B"
              />
            </svg>
          </div>
          <h2>Your request has been sent.</h2>
          <p>We will contact you shortly.</p>
          <k-button @click="openModal = false" size="full">Close</k-button>
        </div>
      </k-modal>
    </section>
    <k-footer></k-footer>
  </div>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';
import { KButton, KInput, KModal } from '@/components';
import KFooter from '../WebsiteFooter.vue';
import KPartners from './Partners.vue';
import image from '../../../assets/what-we-can-do.jpg';
import imageMobile from '../../../assets/what-we-can-do-mobile.png';
import { contactUs } from '@/api';

export default {
  name: 'Institutions',
  components: {
    KButton,
    KFooter,
    KInput,
    KModal,
    KPartners,
  },
  data: () => ({
    openModal: false,
    desktopImage: image,
    mobileImage: imageMobile,
    isSending: false,
    ticket: {
      name: '',
      phone: null,
      organization: '',
      purpose: '',
      email: '',
    },
    touched: {},
    error: {},
    general_error: '',
    correctForm: false,
  }),
  validations() {
    return {
      ticket: {
        name: { required },
        organization: { required },
        purpose: { required },
        email: { required },
        phone: { numeric },
      },
    };
  },
  methods: {
    async sendRequest() {
      const { ticket } = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        this.isSending = true;
        const response = await contactUs(ticket);
        if (response.status === 200) {
          this.$toast.show({ message: 'Request created succesfully' });
        }
        this.$v.$reset();
        this.openModal = true;
      } catch (e) {
        console.log(e);
        this.$toast.show({ message: e });
      } finally {
        this.isSending = false;
        this.reset();
      }
    },
    reset() {
      const { ticket } = this;
      ticket.name = '';
      ticket.email = '';
      ticket.organization = '';
      ticket.purpose = '';
      ticket.phone = '';
    },
  },
};
</script>

<style lang="scss" scoped src="./style.scss">
</style>
