// import axios from 'axios';
import { instance } from '../config';
import stringHelpers from '../utils/string-helpers';

export function getSingleData({ id }) {
  return instance.get(`/dataset/${id}`);
}

export function advancedSearch(body) {
  const query = new URLSearchParams();
  const { removeSymbols: clean } = stringHelpers;

  // create a copy of body
  const params = { ...body };
  // replace indicator with search
  params.search = params.indicator;
  if (!('limit' in params)) params.limit = '50';
  delete params.indicator;
  delete params.category;

  // create a query with entries with values only
  Object
    .entries(params)
    .forEach(([key, value]) => {
      if (value) query.append(key, clean(value));
    });

  return instance.get(
    `/dataset?${query.toString()}`,
  );
}

export function downloadDataset({ data, type }) {
  return instance.post(`/export/dataset?file_type=${type}`, data, {
    'Content-Type': 'multipart/form-data',
    responseType: 'blob',
  });
}

export function downloadCsv(options) {
  const query = new URLSearchParams();
  const { id } = options;
  query.append('dataset1', id);

  if (options.id2) {
    query.append('dataset2', options.id2);
  }
  return instance.get(`/export/dataset?${query.toString()}`);
}

export function updateClicked({ searchId, body }) {
  return instance.patch(`/search/${searchId}`, body);
}
