import { instance } from '../config';

export function signup({ user }) {
  return instance.post('/customer/auth/signup', user);
}

export function verifyOtp(otp) {
  return instance.patch('/customer/auth/verify', otp);
}

export function resendOtp(email) {
  return instance.post('/customer/auth/resend-otp', email);
}

export function login({ user }) {
  return instance.post('/customer/auth/login', user);
}

export function fetchProfile() {
  return instance.get('/customer/id');
}

export function forgotPassword(email) {
  return instance.post('/customer/auth/forgot-password', email);
}
export function resetPassword({ token, ...user }) {
  return instance.patch('/customer/auth/reset-password', user, {
    headers: {
      Authorization: `${token}`,
    },
  });
}

export function updatePassword(user) {
  return instance.patch('/customer/auth/update-password', user);
}

export function deleteAccount() {
  return instance.delete('/customer');
}

export function disableAccount({ id, status }) {
  return instance.patch('/customer', { id, status });
}
