import { mapActions } from 'vuex';
import { KAuth, KInput, KButton } from '@/components';
import Validators from '@/mixins/Validators';

export default {
  name: 'Signup',
  components: {
    KAuth,
    KInput,
    KButton,
  },
  mixins: [Validators],
  data: () => ({
    user: {},
    touched: {},
    error: {},
    isLoading: false,
    next: '',
  }),
  mounted() {
    this.next = this.$route.query.next || '';
  },
  methods: {
    ...mapActions({
      signup: 'auth/signup',
    }),
    async signupUser() {
      const { user: { fullName, email, password } } = this;
      this.isLoading = true;
      try {
        const userCreated = await this.signup({ user: { fullName, email, password } });
        if (!userCreated.error) {
          this.$toast.show({ message: userCreated });
          this.resetForm();
          this.$router.push({ name: 'Otp', query: { email, next: this.next } });
        } else {
          throw Error(userCreated.error);
        }
        this.isLoading = false;
      } catch (error) {
        this.$toast.show({ message: error });
        this.isLoading = false;
      }
    },
    resetForm() {
      this.user.fullName = '';
      this.user.email = '';
      this.user.password = '';
    },
  },
  computed: {
    formInvalid() {
      const errors = Object.values(this.error);
      const values = Object.values(this.user);

      return errors.some((k) => !!k) || !(values.length === 4 && values.every((v) => !!v));
    },
  },
};
