import { instance } from '../config';

export function sendTicket({
  firstName, email, title, messageBody,
}) {
  return instance.post('/request/ticket', {
    firstName,
    email,
    title,
    messageBody,
  });
}

export function contactUs(ticket) {
  return instance.post('/contact-us/organisation', ticket);
}

export function apply({ applyTicket }) {
  return instance.post('/contact-us/individual', applyTicket);
}
