import { saveAs } from 'file-saver';
import { advancedSearch, downloadCsv } from '@/api';
import {
  KButton, KCard, KInput, KModal,
} from '@/components';
import BarChartWrapper from '@/components/Charts/BarChartWrapper.vue';
import LineWrapper from '@/components/Charts/LineWrapper.vue';

// helpers
import countries from '@/utils/countries';
import colors from '@/utils/colors';
import stringHelpers from '@/utils/string-helpers';

import SaveAsPng from '../views/Search/Save/SaveAsPng.vue';
import SaveAsPdf from '../views/Search/Save/SaveAsPdf.vue';
import getDataForChart from '@/utils/getDataForChart';
import { getYear } from '@/views/Search/Save/downloadTemplate';

delete countries[''];

export default {
  components: {
    KButton,
    KInput,
    BarChartWrapper,
    KCard,
    KModal,
    SaveAsPng,
    SaveAsPdf,
    LineWrapper,
  },
  data: () => ({
    show: true,
    showDownloadModal: false,
    showShareModal: false,
    showEmbedModal: false,
    shareLink: '',
    isLoading: true,
    embedWidth: 950,
    embedHeight: 450,
    initial: {},
    category: '',
    category2: '',
    country: '',
    country2: '',
    dataset: {
      category: '',
      indicator: '',
      country: '',
      colour: '',
    },
    dataset2: {
      category: '',
      indicator: '',
      country: '',
      colour: '',
    },
    track1: {},
    track2: {},
    countries,
    notFound: false,
    colourOptions: colors,
    tab: '1',
    saving: '',
    editing: false,
    isSavingCsv: false,
    chartType: 'bar',
    xPath: 'M6.99974 5.58599L11.9497 0.635986L13.3637 2.04999L8.41374 6.99999L13.3637 11.95L11.9497 13.364L6.99974 8.41399L2.04974 13.364L0.635742 11.95L5.58574 6.99999L0.635742 2.04999L2.04974 0.635986L6.99974 5.58599Z',
  }),
  async created() {
    const {
      dataset,
      dataset2 = {},
    } = await this.getDatasetOptionsFromURl();
    this.chartType = dataset.chartType;
    this.isLoading = true;
    this.track1 = { ...this.dataset };

    const foundDataset = await this.advancedFetch(dataset, { name: 'dataset' });
    if (!foundDataset) {
      this.notFound = true;
    } else {
      this.dataset = { ...foundDataset };
      this.country = foundDataset.country;
      this.category = foundDataset.category;
      this.track1 = { ...foundDataset };
      this.initial = { ...foundDataset };
    }

    if (dataset2.indicator && dataset2.country) {
      const foundDataset2 = await this.advancedFetch(dataset2, { name: 'dataset2' });
      if (foundDataset2) {
        this.dataset2 = { ...foundDataset2 };
        this.country2 = foundDataset2.country;
        this.category2 = foundDataset2.category;
        this.track2 = { ...foundDataset2 };
      }
    }
    this.isLoading = false;

    this.shareLink = window.location.href;
  },
  mounted() {
    const {
      definition,
      definition2,
      chart,
    } = this.$refs;
    if (chart) {
      chart.addEventListener('mousemove', ({
        clientX: x,
        clientY: y,
      }) => {
        definition.style.top = `${y}px`;
        definition2.style.top = `${y}px`;

        const screenWidth = window.innerWidth;
        const halfScreen = screenWidth / 2;
        if (screenWidth > 768) {
          definition.style.left = `${x}px`;
          definition2.style.left = `${x}px`;
        } else {
          const smaller = screenWidth < 420;
          const width = smaller
            ? ((0.9 * screenWidth) - 32)
            : 390;
          definition.style.width = `${width}px`;
          definition.style.left = `${(screenWidth - width) / 2}px`;

          definition2.style.width = `${width}px`;
          definition2.style.left = `${(screenWidth - width) / 2}px`;
        }

        if (x < halfScreen) {
          definition.style.display = 'block';
          definition2.style.display = 'none';
        } else if (this.dataset2.id && x >= halfScreen) {
          definition.style.display = 'none';
          definition2.style.display = 'block';
          // } else {
          //   definition.style.display = 'block';
          //   definition2.style.display = 'none';
        }
      });
    }
  },
  watch: {
    $route() {
      this.shareLink = window.location.href;
    },
    ready() {
      this.hide();
    },
    refetchOptions: {
      deep: true,
      async handler(val) {
        const {
          indicator,
          country,
        } = val;
        if (indicator === this.initial.indicator && country === this.initial.country) return;
        const indicatorChanged = this.track1.indicator !== indicator;
        const countryChanged = this.track1.country !== country;

        if (this.ready && (indicatorChanged || countryChanged)) {
          try {
            this.isLoading = true;
            const found = await this.advancedFetch(val, { name: 'dataset' });
            const setTwo = this.dataset2.frequency;
            if (found) {
              if (setTwo && setTwo !== found.frequency) this.resetDataset2(this.country2);
              this.dataset = { ...found };
              this.country = found.country;
              this.category = found.category;
              this.track1 = { ...found };
              this.updateURLSearchParams({ ...found }, { push: true });
              this.isLoading = false;
            } else {
              this.notFound = true;
              const resetVersion = {
                category: '',
                indicator: '',
                country,
                colour: this.dataset.colour,
              };
              this.dataset = { ...resetVersion };
              this.category = '';
              this.track1 = { ...resetVersion };
              this.isLoading = false;
            }
          } catch (e) {
            console.log(e.message);
          }
        } else {
          this.updateURLSearchParams(val, this.track1.id !== val.id);
          this.track1 = { ...val };
        }
      },
    },
    refetchOptions2: {
      deep: true,
      async handler(val) {
        const {
          indicator,
          country,
        } = val;
        if (this.twoDatasets) this.hide();

        const indicatorChanged = this.track2.indicator !== indicator;
        const countryChanged = this.track2.country !== country;
        if (this.set2Ready && (indicatorChanged || countryChanged)) {
          try {
            this.isLoading = true;
            const found = await this.advancedFetch({
              ...val,
              country: this.country2,
            },
            { name: 'dataset2' });
            if (found) {
              this.dataset2 = { ...found };
              this.country2 = found.country;
              this.category2 = found.category;
              this.updateURLSearchParams({ ...found }, { dataset2: true });
              this.isLoading = false;
              this.track2 = { ...found };
            } else {
              // else reset to previous dataset
              // this.notFound = true;
              this.resetDataset2(this.country2);
              // this.dataset2 = { ...this.track2 }; // reset to previous if not found
            }
          } catch (e) {
            console.log(e.message);
          }
        } else {
          this.updateURLSearchParams(this.dataset2, { dataset2: true });
          this.track2 = { ...this.dataset2 };
          // this.updateURLSearchParams(val, { dataset2: true });
          // this.track2 = { ...val };
        }
      },
    },
    refreshOptions: {
      deep: true,
      handler(val) {
        this.hide();
        const values = Object.entries(val);
        const set1 = values.reduce((accum, [key, value]) => {
          const total = accum;
          if (!key.endsWith('2') && value) total[key] = value;
          return total;
        }, {});

        const set2 = values.reduce((accum, [key, value]) => {
          const total = accum;
          if (key.endsWith('2') && value) total[key.slice(0, key.length - 1)] = value;
          return total;
        }, {});
        this.updateURLSearchParams(set1);
        this.updateURLSearchParams(set2, { dataset2: true });
      },
    },
    show() {
      setTimeout(() => {
        this.show = true;
      }, 0);
    },
  },
  computed: {
    latest() {
      return this.periodWithValue(this.dataset.data, this.dataset.data.length);
    },
    latest2() {
      return this.periodWithValue(this.dataset2.data, this.dataset2.data.length);
    },
    embedString() {
      const {
        dataset: {
          indicator,
          category,
          country,
          startYear,
          endYear,
          colour,
          frequency,
          chartType,
        },
        dataset2,
        embedWidth,
        embedHeight,
      } = this;
      const { origin } = window.location;
      const query = new URLSearchParams();
      query.append('indicator', indicator);
      query.append('category', category);
      query.append('country', country);
      query.append('startYear', startYear);
      query.append('endYear', endYear);
      query.append('colour', colour);
      query.append('frequency', frequency);
      query.append('chartType', chartType);
      query.append('indicator2', dataset2.indicator);
      query.append('category2', dataset2.category);
      query.append('country2', dataset2.country);
      query.append('startYear2', dataset2.startYear);
      query.append('endYear2', dataset2.endYear);
      query.append('colour2', dataset2.colour);
      query.append('frequency2', dataset2.frequency);
      query.append('chartType2', dataset2.chartType);

      const url = `${origin}/chart/compare?${query.toString()}`;
      return `<iframe src='${url}' style='border:0px #ffffff none;' name='Kwerty Embed' scrolling='no' frameBorder='1'marginHeight='0px' marginWidth='0px' height='${embedHeight}px' width='${embedWidth}px' allowFullScreen></iframe>`;
    },
    set2Ready() {
      return !!this.dataset2.indicator && !!this.country2;
    },
    ready() {
      const {
        indicator,
        country,
      } = this.dataset;

      return !!indicator && !!country;
    },
    twoDatasets() {
      return !!this.dataset2.data?.length;
    },
    chartDataLocal() {
      const {
        startYear,
        endYear,
      } = this.dataset;
      const {
        startYear: startYear2,
        endYear: endYear2,
      } = this.dataset2;
      const colour1 = this.dataset.colour;
      const colour2 = this.dataset2.colour;

      const {
        datalist1,
        datalist2,
        labels,
      } = getDataForChart(
        this.dataset.data,
        this.dataset2.data || [],
        {
          startYear,
          endYear,
          startYear2,
          endYear2,
        },
      );

      const dataset = {
        yAxisID: 'A',
        data: datalist1,
        backgroundColor: colour1,
        pointBackgroundColor: colour1,
        borderColor: colour1,
        pointHitRadius: 10,
        spanGaps: true,
      };

      const dataset2 = {
        ...dataset,
        data: datalist2,
        backgroundColor: colour2 || '#0F2B90',
        pointBackgroundColor: colour2,
        borderColor: colour2,
        yAxisID: 'B',
      };

      return {
        // labels: !this.twoDatasets ? this.values.map((item) => item.period) : mergedLabels,
        labels,
        datasets: this.twoDatasets ? [
          dataset,
          dataset2,
        ] : [dataset],
      };
    },
    values2() {
      const {
        dataset2: {
          startYear,
          endYear,
          data,
        },
      } = this;
      if ((data && !data.length) || !data) return [];
      return data.filter((item) => {
        const year = getYear(item.period);
        const start = parseFloat(startYear);
        const end = parseFloat(endYear);
        return year >= start && year <= end;
      });
    },
    set2YearsGreaterThan() {
      const {
        dataset2: dataset,
        toOptions,
        yearsOver,
      } = this;
      if (!dataset.yearsArray) return {};

      const {
        startYear,
        yearsArray,
      } = dataset;
      return toOptions(yearsOver(startYear, yearsArray));
    },
    set2YearsLessThan() {
      const {
        dataset2: dataset,
        toOptions,
        yearsUnder,
      } = this;
      if (!dataset.yearsArray) return {};

      const {
        endYear,
        yearsArray,
      } = dataset;
      return toOptions(yearsUnder(endYear, yearsArray));
    },
    refetchOptions() {
      return {
        indicator: this.dataset.indicator,
        country: this.dataset.country,
        frequency: this.dataset.frequency,
      };
    },
    refetchOptions2() {
      return {
        indicator: this.dataset2.indicator,
        country: this.dataset2.country,
        frequency: this.dataset2.frequency,
      };
    },
    refreshOptions() {
      return {
        colour: this.dataset.colour,
        startYear: this.dataset.startYear,
        endYear: this.dataset.endYear,
        colour2: this.dataset2.colour,
        startYear2: this.dataset2.startYear,
        endYear2: this.dataset2.endYear,
      };
    },
  },
  methods: {
    ...stringHelpers,
    resetDataset2(country = '') {
      this.country2 = country;
      const resetVersion = {
        category: '',
        indicator: '',
        // country,
        colour: this.dataset2.colour,
      };
      this.dataset2 = { ...resetVersion };
      this.category2 = '';
      this.track2 = { ...resetVersion };
    },
    hide() {
      this.show = false;
    },
    cancelSaving() {
      this.saving = false;
    },
    generate() {
      const canvas = document.querySelector('canvas');
      this.dataset.image = canvas.toDataURL();
    },
    async saveAsCsv() {
      const {
        dataset,
        dataset2,
      } = this;
      const {
        id,
        indicator,
        country,
        currency,
        metric,
      } = dataset;
      this.isSavingCsv = true;
      try {
        const response = await downloadCsv({
          id,
          id2: dataset2.id,
        });
        if (response.error) {
          throw Error(response.error);
        }
        let data = response.data.replace('"value"', `"${indicator} (in ${metric}${currency ? ` ${currency}` : ''})"`);
        data = data.replace('"value2"', `"${dataset2.indicator} (in ${dataset2.metric}${dataset2.currency ? ` ${dataset2.currency}` : ''})"`);
        data = data.replace('"period2"', '"period"');
        const download = new Blob([data], { type: 'text/plain;charset=UTF-8' });
        const fileName = this.titleCase(`${country} ${this.removeSymbols(indicator)} v ${dataset2.country} ${this.removeSymbols(dataset2.indicator)}.csv`);
        saveAs(download, fileName);
        this.$toast.show({ message: ` Downloaded ${fileName}` });
        this.isSavingCsv = false;
        this.showDownloadModal = false;
      } catch (error) {
        console.log(error);
        this.isSavingCsv = false;
      }
    },
    async advancedFetch(options, { name = 'dataset' }) {
      const { removeSymbols: clean } = this;
      const {
        indicator,
        category,
        country,
      } = options;
      const { data: { data } } = await advancedSearch({
        indicator: clean(indicator),
        country: clean(country),
        category: clean(category),
      });

      if (data.dataset.length) {
        const compoundData = {};
        data.dataset.forEach((d) => {
          const indicatorMatch = indicator?.toLowerCase() === d?.nameOfIndicator.toLowerCase();
          const countryMatch = country?.toLowerCase() === d.country?.toLowerCase();
          const { isPublished } = d;
          if (indicatorMatch && countryMatch && isPublished) {
            compoundData[d.frequency] = d;
          }
        });
        const availableFrequencies = Object.keys(compoundData);

        const isDataset1 = name === 'dataset';
        const isDataset2 = name === 'dataset2';
        if (!availableFrequencies.length) {
          this.isLoading = false;
          return null;
        }
        const frequency = options.frequency
          || this.dataset.frequency
          || Object.keys(compoundData)[0];
        const dataAtFrequency = compoundData[frequency] || compoundData[availableFrequencies[0]];
        let {
          startYear,
          endYear,
        } = dataAtFrequency;
        const yearsArray = Array.from(
          new Set(dataAtFrequency.data.map((i) => this.getYear(i.period))),
        );

        let { colour } = this[name];
        const { query } = this.$route;

        const firstValue = this.startPeriodWithValue(dataAtFrequency.data, 0);
        const firstValuePeriod = getYear(firstValue.period);

        const lastValue = this.periodWithValue(dataAtFrequency.data, dataAtFrequency.data.length);
        const lastValuePeriod = getYear(lastValue.period);

        if (isDataset1) {
          const nameMatch = dataAtFrequency.nameOfIndicator === query.indicator;
          const fromQueryS = nameMatch ? query.startYear : null;
          const fromQueryE = nameMatch ? query.endYear : null;

          startYear = fromQueryS || firstValuePeriod || startYear;
          endYear = fromQueryE || lastValuePeriod || endYear;
          colour = query.colour || colour || '#900F7B';
        }
        if (isDataset2) {
          const nameMatch = dataAtFrequency.nameOfIndicator === query.indicator2;
          const fromQueryS = nameMatch ? query.startYear2 : null;
          const fromQueryE = nameMatch ? query.endYear2 : null;

          startYear = fromQueryS || firstValuePeriod || startYear;
          endYear = fromQueryE || lastValuePeriod || endYear;
          colour = query.colour2 || '#0F2B90';
        }
        this.isLoading = false;
        return {
          ...this[name],
          ...options,
          ...dataAtFrequency,
          indicator: dataAtFrequency.nameOfIndicator,
          startYear: this.getYear(startYear) || this[name].startYear,
          endYear: this.getYear(endYear) || this[name].endYear,
          yearsArray,
          colour,
        };
      }
      this.isLoading = false;
      return null;
    },
    reset() {
      this.dataset = { ...this.initial };
      this.country = this.initial.country;
      this.category = this.initial.category;

      this.country2 = '';
      this.category2 = '';
      this.dataset2 = {
        category: '',
        indicator: '',
        country: '',
        colour: '',
        id: '',
      };
      this.track2 = {
        category: '',
        indicator: '',
        country: '',
        colour: '',
        id: '',
      };
      this.updateURLSearchParams({ ...this.dataset2 }, {
        push: true,
        dataset2: true,
      });
    },
    saveAsPng() {
      const canvas = document.querySelector('canvas');
      this.dataset.image = canvas.toDataURL('image/png');
      this.showDownloadModal = false;
      this.saving = 'png';
    },
    copy() {
      navigator.clipboard.writeText(this.shareLink);
    },
    yearsArrayToObject(obj, current) {
      const newObj = { ...obj };
      newObj[current.period] = current.value;
      return newObj;
    },
  },
};
