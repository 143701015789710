export default {
  SET_USER_ID: (state, payload) => {
    state.user.id = payload;
    state.userId = payload;
  },
  SET_TOKEN: (state, payload) => {
    state.token = payload;
  },
  SET_USER: (state, payload) => {
    state.user = payload;
  },
  SET_PROFILE: (state, payload) => {
    state.profile = payload;
  },
  RESET: (state) => {
    state.user = {};
    state.profile = {};
    state.userId = '';
    state.token = null;
  },
};
