<template>
  <div class='wrapper'>
    <div :class='["chart", {suspense: isLoading}]'>
      <template v-if='!isLoading'>
        <div class='metric'>{{ dataset.metric }} {{ dataset.currency }}
        </div>
        <bar-chart-wrapper v-if="dataset.chartType === 'bar' && show"
                           :datasets='chartData.datasets'
                           :labels='chartData.labels' />
        <line-wrapper v-if="dataset.chartType === 'line' && show" :datasets='chartData.datasets'
                      :height='150'
                      :labels='chartData.labels' />
      </template>
    </div>

    <footer class='footer'>
      <div class='heading'>{{ dataset.country }} - {{ dataset.indicator }}
<!--        {{ dataset.startYear }}&ndash;{{ dataset.endYear }}-->
      </div>
      <div class='from'>Powered by <a href='https://kwerty.io' target='_blank' rel='noreferrer'>
        Kwerty</a></div>
    </footer>
  </div>
</template>

<script>
import DatasetResult from '../../mixins/DatasetResult';
import BarChartWrapper from '../../components/Charts/BarChartWrapper.vue';
import LineWrapper from '../../components/Charts/LineWrapper.vue';
import stringHelpers from '../../utils/string-helpers';
import { advancedSearch } from '../../api';
import { getYear } from '@/views/Search/Save/downloadTemplate';
import GetLatestWithValue from '@/mixins/GetLatestWithValue';

export default {
  name: 'SingleChart',
  mixins: [DatasetResult, GetLatestWithValue],
  components: {
    BarChartWrapper,
    LineWrapper,
  },
  data: () => ({
    dataset: {},
    isLoading: true,
    show: true,
    compoundData: null,
  }),
  async mounted() {
    const {
      indicator, country, colour, category, startYear, endYear, frequency, chartType,
    } = this.$route.query;
    const { removeSymbols: clean } = stringHelpers;
    this.dataset.indicator = indicator;
    this.dataset.category = category;
    this.dataset.country = country;
    this.dataset.colour = `${colour || '#900F7B'}`;
    this.dataset.startYear = startYear;
    this.dataset.endYear = endYear;
    this.dataset.chartType = chartType;

    const { data: { data } } = await advancedSearch({
      indicator: clean(indicator),
      country: clean(country),
      category: clean(category),
    });

    if (data.dataset.length) {
      const compoundData = {};
      data.dataset.forEach((d) => {
        const indicatorMatch = indicator.toLowerCase() === d.nameOfIndicator.toLowerCase();
        const countryMatch = country.toLowerCase() === d.country.toLowerCase();
        const { isPublished } = d;
        if (indicatorMatch && countryMatch && isPublished) {
          compoundData[d.frequency] = {
            ...d,
            endYear: getYear(this.periodWithValue(d.data, d.data.length).period, d.endYear),
          };
        }
      });

      const availableFrequencies = Object.keys(compoundData);
      if (!availableFrequencies.length) {
        this.notFound = true;
      } else {
        this.compoundData = compoundData;
        this.frequency = frequency || Object.keys(compoundData)[0];
      }
      this.isLoading = false;
    } else {
      this.notFound = true;
    }

    const fromCompound = this.compoundData[frequency];
    const firstPeriodWithValue = this.startPeriodWithValue(
      fromCompound.data,
      0,
      (startYear),
    ).period;
    const lastPeriodWithValue = this.periodWithValue(
      fromCompound.data,
      fromCompound.data.length,
      (endYear),
    ).period;
    const yearsArray = fromCompound.data.map((item) => getYear(item.period));

    this.dataset = {
      ...this.dataset,
      ...fromCompound,
      yearsArray,
      startYear: getYear(firstPeriodWithValue),
      endYear: getYear(lastPeriodWithValue),
    };
  },
  methods: {
    setShow() {
      this.show = true;
    },
  },
  watch: {
    dataset: {
      deep: true,
      handler() {
        this.show = false;
      },
    },
    show(val) {
      if (!val) {
        setTimeout(this.setShow, 0);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
  .wrapper {
    padding: 5%;
  }

  .chart {
    min-height: 32vw;
    position: relative;
    display: grid;
    grid: 1fr / 2rem auto;

    .metric {
      font-weight: 300;
      top: 50%;
      transform: rotateZ(-90deg);
      position: relative;
      height: 2.4rem;
      white-space: nowrap;
    }
  }

  .footer {
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
    padding: 0.8rem 1.6rem;
    background: rgba($dark-purple, 0.05);
    color: rgba(0,0,0,0.5);
    margin-top: 2.4rem;
    text-align: center;

    & > * {
      font-size: calc(max(min(1.2rem, 2.25vw), 0.8rem));
      line-height: 1.2;
    }

    .heading {
      color: rgba(0, 0, 0, 0.75);
      font-weight: 600;
      font-size: calc(max(min(1.2rem, 2.25vw), 0.8rem));
      line-height: 1.2;
    }

    a {
      color: $dark-purple;
    }

    .from {
      text-transform: none;
    }
  }
</style>
