/* eslint-disable no-param-reassign */
import EventBus from '@/utils/event-bus';
import CheckAuth from '@/components/CheckAuth/CheckAuth.vue';

export default {
  install(Vue) {
    Vue.component('CheckAuth', CheckAuth);
    Vue.prototype.$checkAuth = {
      show: () => {
        EventBus.$emit('showAuthPrompt');
      },
    };
  },
};
