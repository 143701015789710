<template>
  <k-auth title="Forgot Password?">
   <template v-slot:description>
      <p>
        To reset your password, please
        provide the verification code sent to op********@g****.com
      </p>
    </template>
    <form class="form__items" @submit.prevent="sendCode">
      <k-input
       label="Verification Code"
       type="number" name="code"
       :error="error"
       v-model="user.code" />
      <k-button @click="$router.push({ name: 'ResetPassword' })"
       :loading="isLoading" size="full" submit>Send verification code</k-button>
    </form>
      <k-button @click="$router.push({ name: 'Signup' })"
      :loading="isLoading" size="full"
      variant="link">
      Go back to Sign In</k-button>
  </k-auth>
</template>

<script>
import { KAuth, KInput, KButton } from '@/components';

export default {
  name: 'VerificationCode',
  components: {
    KAuth,
    KInput,
    KButton,
  },
  data: () => ({
    user: {},
    isLoading: false,
  }),
};
</script>

<style></style>
