<template>
  <div class="page">
    <header class="header">
      <div class="heading">
        <div class="close">
          <img src="@/assets/close.png" alt="" @click="$emit('back')" />
        </div>
        <h1>Save as PDF</h1>
        <div class="back">
          <img src="@/assets/arrow.svg" alt="" @click="$emit('back')" />
          Customize Your PDF File
        </div>
      </div>
      <div class="controls">
        <k-button
          variant="tertiary"
          @click="download()"
          :loading="isDownloading"
          :disabled="!showList.length"
          >Download</k-button
        >
        <k-button negative variant="tertiary" @click="$emit('back')">Cancel</k-button>
      </div>
    </header>

    <div class="body">
      <div class="inputs">
        <k-input label="Name of Document" v-model="dataset.title"></k-input>
        <k-input label="Authors Note" v-model="dataset.notes" variant="textarea"></k-input>
        <k-input label="Source" v-model="dataset.source" disabled></k-input>
        <k-input
          label="Chart Type"
          type="select"
          :optionsDisplay="chartTypeOptions"
          v-model="dataset.chartType"
          disabled
        ></k-input>
      </div>

      <div class="preview">
        <h2>PAGES</h2>
        <div class="preview">
          <k-checkbox :radial="true" label="Chart" v-model="showList" value="chart" name="Chart">
          </k-checkbox>
          <k-checkbox :radial="true" label="Table" v-model="showList" value="table" name="Table">
          </k-checkbox>
          <k-checkbox
            :radial="true"
            label="Data Analysis"
            v-model="showList"
            value="analysis"
            name="Data Analysis"
          ></k-checkbox>
        </div>
      </div>
    </div>
    <div class="controls--mobile">
      <k-button variant="secondary" size="full" :loading="isDownloading" @click="download()">
        Download
      </k-button>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { mapGetters } from 'vuex';
import { downloadDataset } from '@/api';
import { KButton, KInput, KCheckbox } from '@/components';
import chartTypeOptions from '@/utils/chart-type-options';
import stringHelpers from '@/utils/string-helpers';
import scrollToTop from '@/utils/scrollToTop';

import { pdfTemplate } from './downloadTemplate';

export default {
  name: 'SaveAsPdf',
  components: {
    KInput,
    KButton,
    KCheckbox,
  },
  data: () => ({
    chartTypeOptions,
    dataset: {},
    showList: ['chart'],
    isDownloading: false,
  }),
  mounted() {
    setTimeout(() => scrollToTop(), 0);
    const {
      country, indicator, startYear, endYear,
    } = this.options;
    const title = this.titleCase(`${country} - ${indicator}: ${startYear} - ${endYear}`);
    this.dataset = { analysis: '', ...this.options, title };
  },
  props: {
    options: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({ token: 'auth/getToken' }),
  },
  methods: {
    ...stringHelpers,
    async download() {
      const {
        showList,
        dataset: { country, indicator },
      } = this;

      const showNotes = showList.includes('analysis');
      const showChart = showList.includes('chart');
      const showTable = showList.includes('table');

      const final = pdfTemplate({
        ...this.dataset,
        showNotes,
        showChart,
        showTable,
      });

      const htmlBlob = new Blob([final], { type: 'text/plain' });
      const htmlFile = new File([htmlBlob], 'template.txt');

      const formData = new FormData();
      formData.append('file', htmlFile);
      try {
        this.isDownloading = true;
        const response = await downloadDataset({ data: formData, type: 'pdf' });
        const responseBlob = new Blob([response.data], { type: 'application/pdf' });
        const fileName = `${this.titleCase(country)}_${this.titleCase(indicator)}.pdf`;
        saveAs(await responseBlob, fileName);
        this.$toast.show({ message: `Downloaded ${fileName}` });
      } catch (e) {
        this.$toast.show({ message: e.message });
      } finally {
        this.isDownloading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./Save.scss"></style>
