<template>
  <div class='page'>
    <form class='content' @submit='$router.push({name: "CountryDashboard", params: {country}})'>
      <h1>Country Dashboard</h1>
      <p>Please, select a country to begin</p>
      <k-input v-model='country' :optionsDisplay='countries' label='Country'
               searchInside='countries' type='select'></k-input>
      <k-button full submit :disabled="!country">Submit</k-button>
    </form>
  </div>
</template>

<script>
import countries from '@/utils/countries';
import { KButton, KInput } from '@/components';

delete countries.all;

export default {
  name: 'Select',
  components: {
    KInput, KButton,
  },
  data: () => ({
    country: '',
    countries,
  }),
};
</script>

<style lang='scss' scoped>
.content {
  max-width: 36.6rem;
  margin: 6.4rem auto;

  h1 {
    margin-bottom: 1.6rem;
  }

  p {
    margin-bottom: 3.2rem;
  }

  button {
    margin-top: 3.2rem;
  }
}
</style>
