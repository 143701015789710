<template>
  <div>
    <div v-if="!saving" class="page">
      <header class="header">
        <h1>
          {{ titleCase(kebabCase(country)) }}: {{ titleCase(indicator)
          }}<span class="change-options">
            <!-- change country button -->
            <div
              class="change-country"
              @click="
                showModal = true;
                isCountry = true;
              "
            >
              <p>
                Change Country<span><img src="../../../assets/arrow-down.svg" alt="" /></span>
              </p>
            </div>
            <!--  -->
            <!-- change indicator button -->
            <div class="change-indicator-wrapper">
              <div class="loading-spinner" v-if="isIndicatorLoading"></div>
              <div
                class="change-indicator"
                @click="
                  showModal = true;
                  isIndicator = true;
                  setCategory(0);
                "
              >
                <p>
                  Change Indicator<span><img src="../../../assets/arrow-down.svg" alt="" /></span>
                </p>
              </div>
            </div>
            <!--  -->
          </span>
        </h1>
        <div
          class="backdrop"
          v-if="showModal"
          @click="
            showModal = false;
            isCountry = false;
            isIndicator = false;
          "
        ></div>
        <!-- country modal -->
        <div class="country-modal" v-if="isCountry">
          <input type="text" placeholder="Search Country" v-model="search" />
          <div class="countries" v-if="filteredCountries.length">
            <div v-for="country in filteredCountries" :key="country.name.common">
              <p
                @click="
                  selectedCountry = country.name.common;
                  showModal = false;
                  isCountry = false;
                  isIndicator = false;
                "
              >
                {{ country.name.common }}
              </p>
            </div>
          </div>
          <div class="countries" v-else>
            <p>No search result found</p>
          </div>
        </div>
        <!--  -->
        <!-- indicator modal -->
        <div class="indicator-modal" v-if="isIndicator">
          <div class="category-switch-btn" @click="isShowCategories = true">
            <div class="category-switch-btn-text">
              <p>Indicator Category</p>
              <h3>{{ allCategories[allCategoriesIndex] }}</h3>
            </div>
            <div class="category-switch-btn-arrow">
              <img src="../../../assets/arrow-down.svg" alt="" />
            </div>
          </div>

          <div class="indicators" v-if="categoryIndicators.length">
            <!-- indicators will be called here -->
            <div v-if="selectedCountry.length">
              <a
                v-for="indicator in categoryIndicators"
                :key="indicator.id"
                :href="`/advanced/${indicator.name}/${selectedCountry.toLowerCase()}`"
                >{{ indicator.name }}</a
              >
            </div>
            <div v-else>
              <a
                v-for="indicator in categoryIndicators"
                :key="indicator.id"
                :href="`/advanced/${indicator.name}/${country}`"
                >{{ indicator.name }}</a
              >
            </div>
          </div>
          <div class="indicators" v-else>
            <!-- if there are no indicators for a category -->
            <p>No Indicators for this category</p>
          </div>
          <!-- category modal -->
          <div class="category-modal" v-if="isShowCategories" @click="isShowCategories = false">
            <div class="categories">
              <div
                v-for="(category, index) in allCategories"
                :key="category"
                @click="
                  allCategoriesIndex = index;
                  setCategory(index);
                "
              >
                <p>{{ category }}</p>
              </div>
            </div>
          </div>
          <!--  -->
          <!--  -->
        </div>
        <div v-if="!notFound" class="controls">
          <div class="options">
            <template v-if="isLoading">
              <div class="suspense"></div>
              <div class="suspense"></div>
              <div class="suspense"></div>
              <div class="suspense"></div>
              <div class="suspense"></div>
              <div class="suspense"></div>
            </template>
            <template v-else>
              <!-- class="options--inputs" -->
              <k-input
                v-model="dataset.chartType"
                :optionsDisplay="chartTypeOptions"
                label="Chart Type"
                type="select"
              />
              <k-input
                v-model="dataset.colour"
                :optionsDisplay="colourOptions"
                label="Colour"
                type="select"
              />
              <k-input
                v-model="frequency"
                :optionsDisplay="frequencyOptions"
                label="Frequency"
                type="select"
              />
              <k-input
                v-model="dataset.startYear"
                :optionsDisplay="yearsLessThan"
                label="Start Year"
                searchInside="start year"
                type="select"
              />
              <k-input
                v-model="dataset.endYear"
                :optionsDisplay="yearsGreaterThan"
                label="End Year"
                searchInside="end year"
                type="select"
              />
            </template>
          </div>
          <k-button class="compare-btn" @click="$router.push(`/compare?${compareDataQuery}`)">
            Compare Data
          </k-button>
        </div>
      </header>
      <div class="main">
        <div @mousemove="follow" @mouseover="follow" :class="['chart', { suspense: isLoading }]">
          <div class="metric">{{ dataset.metric }} {{ dataset.currency }}</div>
          <template v-if="!isLoading">
            <div class="chart__wrapper">
              <bar-chart-wrapper
                v-if="dataset.chartType === 'bar' && show"
                :datasets="chartData.datasets"
                :labels="chartData.labels"
                :height="150"
              />
              <line-wrapper
                v-if="dataset.chartType === 'line' && show"
                :datasets="chartData.datasets"
                :height="150"
                :labels="chartData.labels"
              />
            </div>
          </template>
          <div
            ref="definition"
            :style="{
              visibility: isLoading || !dataset.indicatorDefinition ? 'hidden' : 'visible',
            }"
            class="hover-helper"
          >
            <!--            <div class='name'>{{ titleCase(dataset.indicator) }}</div>-->
            <template v-if="dataset.data && dataset.data.length">
              <div :class="['definition', { shown: dataset.indicatorDefinition }]">
                {{ dataset.indicatorDefinition }}
              </div>
            </template>
          </div>
        </div>
        <div class="more-info">
          <div class="notes">
            <div class="notes__content">
              <template v-if="isAuthenticated">
                <h3>Notes</h3>
                <p v-if="!dataset.notes">No notes available</p>
                <ul v-else>
                  <li
                    v-for="note in dataset.notes
                      .split(/\*\s{0,2},/g)
                      .filter((w) => w.trim().length > 1)"
                    :key="note"
                  >
                    {{ note }}{{ !note.trim().endsWith('*') ? '*' : '' }}
                  </li>
                </ul>
                <h3>Data Analysis</h3>
                <p>{{ analysis }}</p>
                <p class="disclaimer" v-if="analysis">
                  Please note: Analysis is generated by an algorithm. Users are advised to review
                  for context and accuracy
                </p>
              </template>
              <template v-else>
                <h3>Notes</h3>
                <p v-if="!dataset.notes">No notes available</p>
                <ul v-else>
                  <li v-for="note in dataset.notes.split(/\*\s{0,2},/g)" :key="note">
                    {{ note }}{{ !note.endsWith('*') ? '*' : '' }}
                  </li>
                </ul>
              </template>
            </div>
          </div>
          <div class="meta">
            <div class="meta__content">
              <h3>Source</h3>
              <p>{{ dataset.source }}</p>
              <div class="actions">
                <k-button short variant="tertiary" @click="showShareModal = true"> Share </k-button>
                <k-button
                  short
                  variant="tertiary"
                  @click="
                    checkAuthBefore(() => {
                      showEmbedModal = true;
                    })
                  "
                >
                  Embed
                </k-button>
                <k-button
                  short
                  variant="tertiary"
                  @click="
                    checkAuthBefore(() => {
                      showDownloadModal = true;
                    });
                    generate();
                  "
                >
                  Download
                </k-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-view-controls">
        <k-button mobile variant="tertiary" @click="customize = true">Customize Chart</k-button>
        <k-button mobile @click="readyCompare = true">
          <!--        <k-button mobile @click="$router.push(`/compare?${compareDataQuery}`)">-->
          Compare Data
        </k-button>
      </div>

      <div v-if="customize || readyCompare" class="overlay" @click="customize = false"></div>
      <transition name="slide-up">
        <div v-if="customize" class="customize-options">
          <h1>Customize Chart</h1>

          <div class="controls">
            <k-input
              v-model="mobileOptions.chartType"
              :optionsDisplay="chartTypeOptions"
              label="Chart Type"
              type="select"
            />
            <k-input
              v-model="mobileOptions.colour"
              :optionsDisplay="colourOptions"
              label="Colour"
              type="select"
            />
            <k-input
              v-model="mobileOptions.frequency"
              :optionsDisplay="frequencyOptions"
              label="Frequency"
              type="select"
            />
            <k-input
              v-model="mobileOptions.startYear"
              :optionsDisplay="yearsLessThan"
              label="Start Year"
              searchInside="start year"
              type="select"
            />
            <k-input
              v-model="mobileOptions.endYear"
              :optionsDisplay="yearsGreaterThan"
              label="End Year"
              searchInside="end year"
              type="select"
            />
          </div>

          <div class="mobile-view-controls no-shadow">
            <k-button mobile variant="tertiary" @click="customize = false">Cancel</k-button>
            <k-button mobile @click="updateDataset()"> Finish </k-button>
          </div>
        </div>
      </transition>

      <transition name="slide-up">
        <div v-if="readyCompare" class="customize-options">
          <h1>Compare Data with</h1>

          <div class="controls">
            <k-input
              v-model="country2"
              :optionsDisplay="countries"
              label="Country"
              searchInside="countries"
              type="select"
            />
            <k-input
              v-model="category2"
              :optionsDisplay="categories"
              label="Category"
              searchInside="categories"
              type="select"
              @search="(val) => fetchIndicators({ name: val })"
            />
            <k-input
              v-model="dataset2.indicator"
              :optionsDisplay="indicators2"
              label="Indicator"
              searchInside="indicators"
              type="select"
              @search="(val) => fetchIndicators({ name: val })"
            />
            <k-input
              v-model="dataset2.startYear"
              :optionsDisplay="yearsLessThan"
              label="Start Year"
              searchInside="start year"
              type="select"
            />
            <k-input
              v-model="dataset2.endYear"
              :optionsDisplay="yearsGreaterThan"
              label="End Year"
              searchInside="end year"
              type="select"
            />
          </div>

          <div class="mobile-view-controls no-shadow">
            <k-button mobile variant="tertiary" @click="readyCompare = false">Cancel</k-button>
            <k-button mobile @click="$router.push(`/compare?${compareDataQuery}`)">
              Submit
            </k-button>
          </div>
        </div>
      </transition>

      <!-- Share MODAL -->
      <k-modal :open="showShareModal" middle @close="showShareModal = false">
        <k-card heading="Share with others" padInside smaller variant="in-modal" wrapHeading>
          <p class="modal-paragraph share">
            Anyone with the link can immediately view the page on their web browser.
          </p>
          <k-input :value="shareLink" disabled label="" variant="copyInput"></k-input>
          <div class="btn-container">
            <a
              :href="`https://twitter.com/share?ref_src=twsrc%5Etfw&url=${encodeURIComponent(
                shareLink
              )}`"
              rel="noreferrer"
              target="_blank"
            >
              <k-button variant="tertiary">Twitter</k-button>
            </a>
            <a
              :href="`https://wa.me?text=${encodeURIComponent(shareLink)}`"
              rel="noreferrer"
              target="_blank"
            >
              <k-button variant="tertiary">Whatsapp</k-button>
            </a>
            <a
              :href="`mailto:?body=${encodeURIComponent(shareLink)}`"
              rel="noreferrer"
              target="_blank"
            >
              <k-button variant="tertiary">Email</k-button>
            </a>
          </div>
          <div class="cta">
            <k-button variant="secondary" @click="showShareModal = false">Close</k-button>
          </div>
        </k-card>
      </k-modal>

      <!-- Embed MODAL -->
      <k-modal :open="showEmbedModal" middle @close="showEmbedModal = false">
        <k-card heading="Embed HTML" padInside smaller variant="in-modal" wrapHeading>
          <p class="modal-paragraph">
            Copy and paste the code below to embed this chart on any page.
          </p>
          <k-input :value="embedString" disabled label="" variant="copyInput"> </k-input>
          <div class="btn-container embed">
            <k-input v-model="embedWidth" label="Width"></k-input>
            <k-input v-model="embedHeight" label="Height"></k-input>
          </div>

          <div class="cta">
            <k-button variant="secondary" @click="showEmbedModal = false">Close</k-button>
          </div>
        </k-card>
      </k-modal>

      <!-- Download MODAL -->
      <k-modal :open="showDownloadModal" middle @close="showDownloadModal = false">
        <k-card heading="Save to Your Device" padInside smaller variant="in-modal">
          <p class="modal-paragraph">Save a copy of this data to your device in PDF, CSV or PNG.</p>
          <div class="btn-container download">
            <k-button :loading="isSavingCsv" variant="tertiary" @click="saveAsCsv">as CSV</k-button>
            <k-button variant="tertiary" @click="saveAsPdf()">as PDF</k-button>
            <k-button variant="tertiary" @click="saveAsPng()">as PNG</k-button>
          </div>
          <div class="cta">
            <k-button variant="secondary" @click="showDownloadModal = false">Close</k-button>
          </div>
        </k-card>
      </k-modal>

      <!-- Not Found MODAL -->
      <k-modal :open="notFound" middle @close="notFound = false">
        <k-card heading="" padInside smaller variant="in-modal">
          <div class="img-container">
            <img alt="" src="@/assets/attention.svg" />
          </div>
          <h1 class="modal__heading">Sorry! There’s no dataset available for your selection.</h1>
          <k-button
            size="full"
            @click="
              $router.go(-1);
              show = false;
            "
            >Go Back
          </k-button>
        </k-card>
      </k-modal>
    </div>
    <template v-else-if="saving === 'png'">
      <save-as-png :options="{ ...dataset, indicator, country }" @back="cancelSaving"></save-as-png>
    </template>
    <template v-else-if="saving === 'pdf'">
      <save-as-pdf :options="{ ...dataset, indicator, country, analysis }" @back="cancelSaving">
      </save-as-pdf>
    </template>
  </div>
</template>

<script>
import SingleResult from './SingleResult';

export default SingleResult;
</script>

<style lang="scss" scoped src="./SingleResult.scss"></style>
