export default {
  ADD_INDICATORS: (state, payload) => {
    const uniqueList = new Set([...payload.map((ind) => ind.name), ...state.list]);
    const list = Array.from(uniqueList);

    list.sort((a, b) => a.localeCompare(b));
    state.list = list.slice(0, 200);
    state.loadedList = [...payload.slice(0, 300), ...state.loadedList.slice(0, 200)].slice(0, 500);
  },
  ADD_CATEGORIES: (state, payload) => {
    const uniqueList = new Set([
      ...payload.map((ind) => ind.category.toLowerCase().trim()),
      ...state.categories,
    ]);
    const list = Array.from(uniqueList);

    list.sort((a, b) => a.localeCompare(b));
    state.categories = list;
  },
};
