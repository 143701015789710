import axios from 'axios';
import EventBus from '@/utils/event-bus';
import { APP_NAME } from './app';

const https = require('https');

// const API_URL = process.env.VUE_APP_KWERTY_API_URL;
const API_URL = process.env.VUE_APP_KWERTY_API_URL;
const API_VERSION = 1;

const getToken = () => {
  const store = JSON.parse(localStorage.getItem(APP_NAME));
  const apiToken = store && store.auth.token;
  return apiToken ? `Bearer ${apiToken}` : '';
};

const instance = axios.create({
  httpsAgent: new https.Agent({ rejectUnauthorized: false }),
  baseURL: `${API_URL}/api/v${API_VERSION}`,
  crossdomain: true,
  timeout: 600000,
});

instance.interceptors.request.use((request) => ({
  ...request,
  headers: {
    'Content-Type': 'application/json',
    Authorization: request.headers.Authorization || getToken(),
  },
}));

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const exempt = ['/login', '/otp', '/reset-password'];
    if (!exempt.includes(window.location.pathname)) {
      if (error.response.status === 401) {
        EventBus.$emit('clear');
        setTimeout(() => {
          const { location } = window;
          const pathAndSearch = location.pathname + location.search;
          window.location = `/login?next=${encodeURIComponent(pathAndSearch)}`;
        }, 500);
        return Promise.reject(error);
      }
    }
    console.log(error.message);
    return Promise.reject(error);
  }
);

const storyblok = axios.create({
  httpsAgent: new https.Agent({ rejectUnauthorized: false }),
  baseURL: 'https://api.storyblok.com/v2/cdn',
});

storyblok.interceptors.request.use((config) => {
  const request = { ...config };
  request.params = config.params || {};
  request.params.token = process.env.VUE_APP_KWERTY_STORYBLOK_API_KEY;
  return request;
});

const storyblokCareers = axios.create({
  httpsAgent: new https.Agent({ rejectUnauthorized: false }),
  baseURL: 'https://api.storyblok.com/v2/cdn',
});

storyblokCareers.interceptors.request.use((config) => {
  const request = { ...config };
  request.params = config.params || {};
  request.params.token = process.env.VUE_APP_KWERTY_STORYBLOK_JOBS_API_KEY;
  return request;
});

const storyblokHome = axios.create({
  baseURL: 'https://api.storyblok.com/v2/cdn',
});

const evaluate = axios.create({
  baseURL: 'https://m3wkuvnrqp.eu-west-2.awsapprunner.com/',
});

evaluate.defaults.headers.post['Authorization'] = 'Bearer sharonadityajenniferkenechi';
evaluate.defaults.headers.post['Accept'] = '*/*';

storyblokHome.interceptors.request.use((config) => {
  const request = { ...config };
  request.params = config.params || {};
  request.params.token = process.env.VUE_APP_KWERTY_STORYBLOK_HOME_API_KEY;
  return request;
});

export { API_URL, API_VERSION, instance, storyblok, storyblokCareers, storyblokHome, evaluate };
