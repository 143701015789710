<template>
  <div class="profile__layout">
    <div class="profile__layout-content">
      <div>
        <h1>Profile</h1>
      </div>
      <div class="update-profile">
        <div class="profile-picture">
          <img :src="tempUploadImage || user.imageUrl || dummyImage" alt="pic" class="user-image" />
        </div>
        <div class="change-avatar">
          <k-button variant="tertiary" :uploading="isUploading" :disabled="isUploading">
            <input
              type="file"
              name="userProfile"
              id="image-upload"
              accept="image/png, image/jpeg, image/jpg"
              @change="setTempFile($event)"
            />
            Change Avatar</k-button
          >
        </div>
      </div>
      <div class="account__info">
        <p class="title">Account Information</p>
        <form class="account__info-content">
          <k-input label="Full Name" type="text" name="fullName" v-model="user.fullName" />
          <k-input label="Email Address" type="email" v-model="user.email" />
          <div class="btn-wrapper">
            <k-button variant="tertiary" @click="updateMyProfile" :loading="isLoading"
              >Save Changes</k-button
            >
          </div>
        </form>
      </div>
      <div class="change__password">
        <p class="title">Change Password</p>
        <p class="subtitle">Please use a password that you can remember.</p>
        <form class="form__items" @submit.prevent="changePassword">
          <k-input
            label="Current Password"
            type="password"
            variant="password"
            name="old_password"
            v-model="user.oldPassword"
            :error="error.oldPassword"
          ></k-input>
          <k-input
            label="New Password"
            type="password"
            variant="password"
            name="password"
            :error="error.password"
            v-model="user.password"
          ></k-input>
          <k-input
            label="Repeat Password"
            type="password"
            variant="password"
            name="confirm_password"
            :error="error.confirm_password"
            v-model="user.confirm_password"
          ></k-input>
          <div class="btn-wrapper">
            <k-button variant="tertiary" submit :loading="isSaving">Save New Password</k-button>
          </div>
        </form>
      </div>
      <div class="delete-your-account">
        <p class="title">Manage Your Account</p>
        <p class="subtitle">We don’t want to see you go, but we respect your decision.</p>
      </div>
      <div class="delete-btn-wrapper">
        <k-button @click="disableMyAccount" variant="tertiary" :loading="isDisabling">
          {{ profile.status === 'disabled' ? 'Enable My Account' : 'Disable My Account' }}
        </k-button>
        <k-button variant="secondary" negative="negative" @click="deleteModal = true"
          >Delete My Account</k-button
        >
      </div>
      <k-modal v-if="deleteModal" :open="deleteModal" @close="deleteModal = false" class="kmodal">
        <div class="kcard">
          <h2>Are You Sure You Want To Delete Your Account?</h2>
          <p>This action cannot be undone.</p>
          <k-button
            @click="deleteMyAccount"
            variant="secondary"
            negative="negative"
            size="full"
            :loading="isDeleting"
            >Yes, Continue</k-button
          >
          <k-button @click="deleteModal = false" variant="link" size="full" class="btn"
            >No, Go Back</k-button
          >
        </div>
      </k-modal>
    </div>
  </div>
</template>

<script>
import Profile from './Profile';

export default Profile;
</script>

<style lang="scss" scoped src="./Profile.scss"></style>
