import { mapActions, mapGetters, mapMutations } from 'vuex';

import {
  KButton, KCard, KInput, KModal,
} from '@/components';
import Validator from '@/mixins/Validators';
import { uploadFile } from '@/api/upload';
import { deleteAccount } from '@/api/auth';
import avatar from '@/assets/avatar.svg';

export default {
  name: 'Profile',
  components: {
    KButton,
    KInput,
    KModal,
    KCard,
  },
  mixins: [Validator],
  data: () => ({
    deleteModal: false,
    lastname: '',
    isLoading: false,
    isDeleting: false,
    isDisabling: false,
    isSaving: false,
    user: {
      oldPassword: '',
      password: '',
      confirm_password: '',
    },
    touched: {},
    error: {},
    isUploading: false,
    dummyImage: avatar,
    tempUploadFile: null,
  }),
  mounted() {
    this.getProfile();
    this.user = { ...this.user, ...this.userFromState, ...this.profile };
  },
  watch: {
    profile(val) {
      this.user = { ...this.user, ...val };
    },
  },
  computed: {
    ...mapGetters({
      userFromState: 'auth/getUser',
      profile: 'auth/getProfile',
    }),
    tempUploadImage() {
      if (!this.tempUploadFile) return '';
      return URL.createObjectURL(this.tempUploadFile);
    },
  },
  methods: {
    ...mapActions({
      fetchProfile: 'auth/fetchProfile',
      updatePassword: 'auth/updatePassword',
      updateProfile: 'auth/updateProfile',
      disableAccount: 'auth/disableAccount',
    }),
    ...mapMutations({
      reset: 'auth/RESET',
    }),
    async getProfile() {
      try {
        const profile = await this.fetchProfile();
        if (profile.error) {
          throw Error(profile.error);
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    async changePassword() {
      const {
        user: { oldPassword, password },
      } = this;
      this.isSaving = true;
      try {
        const response = await this.updatePassword({ oldPassword, newPassword: password });
        if (!response.error) {
          this.$toast.show({ message: response });
          const clear = {
            oldPassword: '',
            password: '',
            confirm_password: '',
          };
          this.user = { ...this.user, ...clear };
        } else {
          throw Error(response.error);
        }
        this.isSaving = false;
        this.resetForm();
      } catch (error) {
        this.$toast.show({ message: error });
        this.isSaving = false;
      }
    },
    async updateMyProfile() {
      this.isLoading = true;
      try {
        let imageUrl = null;
        if (this.tempUploadFile) {
          imageUrl = await this.uploadImage();
        }
        const {
          user: { fullName, imageUrl: existingImage, email },
        } = this;
        imageUrl = imageUrl || existingImage;
        const response = await this.updateProfile(imageUrl
          ? {
            fullName,
            email,
            imageUrl,
          } : {
            fullName,
            email,
          });
        if (!response.error) {
          this.$toast.show({ message: response });
        } else {
          throw Error(response.error);
        }
        this.isLoading = false;
      } catch (error) {
        this.$toast.show({ message: error });
        this.isLoading = false;
      }
    },
    setTempFile(event) {
      const [file] = event.target.files;
      this.tempUploadFile = file;
    },
    async uploadImage() {
      this.isUploading = true;
      const file = this.tempUploadFile;
      const form = new FormData();
      form.append('file', file);
      const imageUploaded = await uploadFile(form);
      this.isUploading = false;
      try {
        if (imageUploaded.status === 200) {
          const {
            data: { data },
          } = imageUploaded;
          this.user.imageUrl = data.url;
          this.$toast.show({ message: 'Image Upload Successful' });
          return data.url;
        }
      } catch (e) {
        this.$toast.show({
          message: e.message,
        });
      }
      return null;
    },
    async deleteMyAccount() {
      this.isDeleting = true;
      try {
        const response = await deleteAccount();
        if (!response.error) {
          // this.$toast.show({ message: response.data.message });
          this.$toast.show({ message: response.data.message });
        } else {
          throw Error(response.error);
        }
        this.isDeleting = false;
        this.logout();
      } catch (error) {
        this.$toast.show({ message: error });
        this.isDeleting = false;
      }
    },
    async disableMyAccount() {
      this.isDisabling = true;
      const { id } = this.userFromState;
      const status = 'disabled';
      try {
        const response = await this.disableAccount({ id, status });
        if (!response.error) {
          this.$toast.show({ message: 'Profile updated successfully' });
        } else {
          throw Error(response.error);
        }
        this.isDisabling = false;
        this.logout();
      } catch (error) {
        this.$toast.show({ message: error });
        this.isDisabling = false;
      }
    },
    resetForm() {
      this.user.oldPassword = '';
      this.user.password = '';
      this.user.confirm_password = '';
    },
    logout() {
      this.reset();
      if (this.$route.meta.auth) {
        this.$router.go(-1);
      }
    },
  },
};
