import { evaluate } from '@/config';

export const evaluateEconomicStatement = async (query) => {
  const { data } = await evaluate.post(`/economic/statement`, { query });
  return data;
};

export const evaluateEconomicValidate = async (query) => {
  const { data } = await evaluate.post(`/economic/validate`, { query });
  return data;
};

// export const evaluateCheck = async ({ id, re_run }) => {
//   const { data } = await evaluate.post(`/extract/${id}?rerun_extraction=${re_run}`);
//   return data;
// };

// export const getEvaluateCheck = async ({ id }) => {
//   const { data } = await evaluate.get(`/extract?query_id=${id}`);
//   return data;
// };

// export const updateDocument = async ({ id, title, content }) => {
//   const { data } = await evaluate.put(`/documents/${id}`, { title, content });
//   return data;
// };

// export const createDocument = async ({ title, content, kwerty_user_id }) => {
//   const { data } = await evaluate.post('/documents', { title, content, kwerty_user_id });
//   return data;
// };

// export const getDocument = async ({ id }) => {
//   const { data } = await evaluate.get('/documents', {
//     params: {
//       kwerty_user_id: id,
//     },
//   });
//   return data;
// };

// export const getShowDocument = async ({ id }) => {
//   const { data } = await evaluate.get(`/documents/${id}`);
//   return data;
// };

// export const deleteDocument = async ({ id }) => {
//   const { data } = await evaluate.delete(`/documents/${id}`);
//   return data;
// };
