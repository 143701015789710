import { mapMutations,  } from 'vuex';
// import { KButton } from '@/components';
// import { deleteDocument, getDocument } from '../../api/factCheck';

export default {
  name: 'LayoutDashboard',
  components: {
    // KButton,
  },
  data() {
    return {
      documents: [],
      loading: false,
    };
  },
//   mounted() {
//     this.fetchDocuments();
//   },
  computed: {
    // ...mapGetters({
    //   user: 'auth/getUser',
    // }),
    // userData() {
    //   return { ...this.user };
    // },
  },
  methods: {
    ...mapMutations({
      reset: 'auth/RESET',
    }),
    logout() {
      this.reset();
      if (this.$route.meta.auth) {
        // this.$router.go(-1);
      }
      window.location.href = '/';
    },
  },
};
