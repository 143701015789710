import { storyblok } from '../config';

const getPosts = async ({ page = 1, perPage = 10 }) => {
  const response = await storyblok
    .get(`/stories?content_type=Post&page=${page}&per_page=${perPage}`);
  const { data: { stories }, headers: { total } } = response;
  return { total: Number(total), stories };
};

const getSinglePost = async ({ slug }) => {
  const { data } = await storyblok.get(`/stories/${slug}`);
  return data.story;
};

export { getPosts, getSinglePost };
