<template>
  <div class="accordion__container">
    <div>
      <div class="accordion__header" @click="$emit('validate', index)">
        <div style="position: relative">
          <div
            :class="`fact__box fact__message-${
              content.isError === null ? 'gray' : colors[content.isError]
            }`"
          ></div>
          <div
            v-if="content.source && content.source.source_type === 'Kwerty'"
            style="position: absolute; right: 0"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#44CAA5"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#fff"
              class="size-6"
              width="20"
              height="20"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
              />
            </svg>
          </div>
        </div>

        <div>
          <p class="font-semibold" :ref="'header' + index">{{ content.validatingText }}</p>
        </div>
      </div>

      <div v-if="index === selected">
        <div v-if="!loadingStatement[index]" class="fact__text">
          <div>
            <div>
              <p class="accordion__data" @click="updateAccept(content, data)">
                {{ content.message }}
              </p>
            </div>
          </div>
        </div>
        <div v-else>
          <loader />
        </div>
        <div v-if="content.source" class="flex">
          <a
            v-if="content.source.source_type !== 'Kwerty'"
            :href="content.source.url"
            target="_blank"
            v-show="content.source.url"
          >
            <button class="fact__button fact__button-source">Go to Source</button>
          </a>

          <button v-else class="fact__button fact__button-source_not">
            Source: {{ content.source.provider }}
          </button>
          <!-- <button class="fact__button fact__button-move" @click="updateAccept(content)">Accept</button> -->
        </div>
      </div>
      <!-- <p class="accordion__data" @click="updateAccept(content, data)">
        {{ content.validatingText }}
      </p> -->
    </div>
  </div>
</template>

<script>
import Accordion from './Accordion';
//"IndicatorMatchedButValueNotAvailable"
export default Accordion;
</script>

<style lang="scss" scoped src="./Accordion.scss"></style>
