import { mapMutations, mapGetters } from 'vuex';
import { KButton } from '@/components';
// import { deleteDocument, getDocument } from '../../api/factCheck';

export default {
  name: 'Dashboard',
  components: {
    KButton,
  },
  data() {
    return {
      documents: [],
      loading: false,
    };
  },
  mounted() {
    this.fetchDocuments();
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
    userData() {
      return { ...this.user };
    },
  },
  methods: {
    ...mapMutations({
      reset: 'auth/RESET',
    }),
    logout() {
      this.reset();
      if (this.$route.meta.auth) {
        // this.$router.go(-1);
      }
      window.location.href = '/';
    },
    async fetchDocuments() {
      // this.loading = true;
      // const res = await getDocument({ id: this.userData.id });

      // this.documents = res.documents
      //   .map((d) => ({
      //     ...d,
      //     extraction: d.extraction?.length || 0,
      //   }))
      //   .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

      // this.loading = false;
    },
    async deleteDocuments(id) {
      // this.loading = true;
      // try {
      //   await deleteDocument({ id });

      //   this.$toast.show({ message: 'Your document has been deleted!' });

      //   this.fetchDocuments();
      // } catch (err) {
      //   this.$toast.show({ message: 'There is an error' });
      // }

      // this.loading = false;
    },
  },
};
