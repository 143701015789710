<template>
  <div class='page'>
    <div class='main'>
      <section class='content'>
        <form class='search' @submit.prevent='search()'>
          <div class='input-container'>
            <k-input v-model='value' label='' reactive type='search'></k-input>
            <ul :class='["search-suggestions", {loading: isSearching}]'>
              <div v-if='isSearching'>
                <div class='lds-default'>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <template v-else>
                <li v-for='searchTerm in sortedSuggestions'
                    :key='searchTerm'
                    tabindex='0'
                    @click='$router.push({
                      name: "Results",
                      query: {q: encodeURIComponent(searchTerm)}
                    })'>
                  {{ searchTerm }}
                </li>
              </template>
            </ul>
          </div>
          <k-button :disabled='!value' short submit type='submit' ref='searchBtn'>
            Search
          </k-button>
        </form>
        <div class='results'>
          <template v-if='loading'>
            <div class='desktop-left'>
              <section>
                <h3>Relevant Results</h3>
                <div v-for='i in [1, 2]' :key='i' class='result'>
                  <div class='tags'>
                    <div class='suspense mh-10 w-50'></div>
                  </div>
                  <div class='title'>
                    <div class='suspense mh-20 w-70'></div>
                  </div>
                  <div class='description'>
                    <div class='suspense mh-10 mb'></div>
                    <div class='suspense mh-10 mb'></div>
                    <div class='suspense mh-10 mb'></div>
                  </div>
                </div>
              </section>
              <section>
                <h3>Related Results</h3>
                <div v-for='i in [1, 2, 3]' :key='i' class='result'>
                  <div class='tags'>
                    <div class='suspense mh-10 w-50'></div>
                  </div>
                  <div class='title'>
                    <div class='suspense mh-20 w-70'></div>
                  </div>
                  <div class='description'>
                    <div class='suspense mh-10 mb'></div>
                    <div class='suspense mh-10 mb'></div>
                    <div class='suspense mh-10 mb'></div>
                  </div>
                </div>
              </section>
            </div>
          </template>
          <template v-else-if='!loading && !relevantResults.length && !relatedResults.length'>
            <div class='desktop-left'>
              <section class='relevant'>
                <h3>Relevant Results</h3>
                <p>No results for your query</p>
              </section>
              <section class='popular-searches'>
                <h3>People also searched for</h3>
                <ul class='phrases'>
                  <li v-for='phrase in sortedSuggestions' :key='phrase' @click='search(phrase)'>
                    {{ phrase }}
                  </li>
                </ul>
              </section>
            </div>
          </template>
          <template v-else>
            <div class='desktop-left'>
              <section class='relevant' v-show='relevantResults.length'>
                <h3>Relevant Results</h3>
                <div v-for='(r) in relevantResults' :key='r.indicatorId'
                     class='result'>
                  <div class='tags'>{{
                      `indicators ‣ ${r.category} ‣ ${r.indicatorName}`
                    }}
                  </div>
                  <div class='title' @click='listenForClicks'>
                    <router-link
                      :to='`/indicators/${encodeURIComponent(r.indicatorName)}/${
                        encodeURIComponent(r.country)
                      }?category=${encodeURIComponent(r.category)}&frequency=${
                        encodeURIComponent(r.frequency)}`'
                    >
                      {{ `${titleCase(r.country)}: ${r.indicatorName}` }}
                    </router-link>
                    <span v-if='datasetId(r)' class='chart'>{{ r.frequency }}</span>
                  </div>
                  <div class='description'>
                    {{ r.notes }}
                  </div>
                </div>
              </section>
              <section v-if='dashboard.id' class='country-dashboard'>
                <h3>Country Dashboard</h3>
                <div class='country'>
                  <h1 class='name'>{{ titleCase(dashboard.name) }}</h1>
                  <img :src='dashboard.imageUrl' alt='' class='image'>
                  <div class='description'>
                    {{ dashboard.description }}
                  </div>
                  <k-button
                    short
                    variant='tertiary'
                    @click='$router.push({
                    name: "CountryDashboard",
                    params: {country: dashboard.name}
                  })'>
                    See an overview of {{ titleCase(dashboard.name) }}
                  </k-button>
                </div>
              </section>
              <section class='related' v-show='relatedResults.length'>
                <h3>Related Results</h3>
                <div v-for='(r) in relatedResults' :key='r.indicatorId'
                     class='result'>
                  <div class='tags'>{{
                      `indicators ‣ ${r.category} ‣ ${r.indicatorName}`
                    }}
                  </div>
                  <div class='title' @click='listenForClicks'>
                    <router-link
                      :to='`/indicators/${encodeURIComponent(r.indicatorName)}/${
                        encodeURIComponent(r.country)}?category=${
                        encodeURIComponent(r.category)}&frequency=${
                        encodeURIComponent(r.frequency)}`'
                       @click="$emit('clickedMore')"
                    >
                      {{ `${titleCase(r.country)}: ${r.indicatorName}` }}
                    </router-link>
                    <span v-if='datasetId(r)' class='chart'>{{ r.frequency }}</span>
                  </div>
                  <div class='description'>
                    {{ r.notes }}
                  </div>
                </div>
              </section>
              <section class='popular-searches'>
                <h3>People also searched for</h3>
                <ul class='phrases'>
                  <li v-for='phrase in relatedSearches' :key='phrase[0]' @click='search(phrase[0])'>
                    {{ phrase[0] }}
                  </li>
                </ul>
              </section>
            </div>
            <div class='desktop-right'>
              <section v-if='dashboard.id' class='country-dashboard only-on-desk'>
                <h3>Country Dashboard</h3>
                <div class='country'>
                  <h1 class='name'>{{ titleCase(dashboard.name) }}</h1>
                  <img :src='dashboard.imageUrl' alt='' class='image'>
                  <div class='description'>
                    {{ dashboard.description }}
                  </div>
                  <k-button
                    short
                    variant='tertiary'
                    @click='$router.push({
                      name: "CountryDashboard",
                      params: {country: dashboard.name}
                    })'>
                    See an overview of {{ titleCase(dashboard.name) }}
                  </k-button>
                </div>
              </section>
            </div>
          </template>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Results from './Results';

export default Results;
</script>

<style lang='scss' scoped src='./Results.scss'>

</style>
