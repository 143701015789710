<template>
  <section class="section__three">
    <div class="container" ref='partners'>
      <div class="heading__content" >
        <h1 class="heading">
          Partnered with top government institutions and agencies all over the world.
        </h1>
        <p class="title">
          Every dataset is gotten directly from verified sources of government and world data.
        </p>
      </div>
      <div class="section__three--partners">
<!--        <div class="partner">-->
<!--          <img-->
<!--            alt="logo"-->
<!--            src="https://one-percent-bucket-1.s3.us-west-2.amazonaws.com/kwertyassets/budgit.png"-->
<!--          />-->
<!--        </div>-->
<!--        <div class="partner">-->
<!--          <img-->
<!--            alt="logo"-->
<!--            src="https://one-percent-bucket-1.s3.us-west-2.amazonaws.com/kwertyassets/fitila.png"-->
<!--          />-->
<!--        </div>-->
<!--        <div class="partner">-->
<!--          <img-->
<!--            alt="logo"-->
<!--            src="https://one-percent-bucket-1.s3.us-west-2.amazonaws.com/kwertyassets/p1.png"-->
<!--          />-->
<!--        </div>-->
<!--        <div class="partner">-->
<!--          <img-->
<!--            alt="logo"-->
<!--            src="https://one-percent-bucket-1.s3.us-west-2.amazonaws.com/kwertyassets/p2.png"-->
<!--          />-->
<!--        </div>-->
        <template v-if='logos.length'>
        <div class="partner" v-for='logo in logos' :key='logo.url'>
          <img
            :alt="logo.alt"
            :src="logo.url"
          />
        </div>

        </template>
      </div>
    </div>
  </section>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import getPartners from '@/api/home';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'Partners',
  components: {},
  data: () => ({
    logos: [],
  }),
  async mounted() {
    const {
      partners,
    } = this.$refs;

    const partnersRes = await getPartners();
    this.logos = partnersRes.map((partner) => ({
      url: partner.content.Logo.filename,
      alt: partner.name,
    }));

    const heading = partners.querySelector('.heading');
    const title = partners.querySelector('.title');

    const partnersTl = gsap.timeline({
      scrollTrigger: {
        trigger: partners,
        start: '0 95%',
      },
    });

    const fadeUp = {
      opacity: 0,
      y: 100,
      ease: 'ease.out',
      duration: 0.5,
    };

    // const showUp = {
    //   opacity: 0,
    //   ease: 'ease.out',
    // };

    partnersTl.from(heading, { ...fadeUp });
    partnersTl.from(title, { ...fadeUp });

    const partnerCards = Array.from(partners.querySelectorAll('.partner'));
    const partnersCardsTl = gsap.timeline({
      scrollTrigger: {
        trigger: partnerCards[0],
        start: '0 95%',
      },
    });

    partnersCardsTl.from(partnerCards[0], { ...fadeUp, delay: 0.02 });
    partnerCards.slice(1).forEach((card) => {
      partnersCardsTl.from(card, { ...fadeUp }, '<+=0.025');
    });
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 100.6rem;
  padding: 0 2.2rem;
  width: 100%;
}
.section__three {
  height: auto;
  padding: 9rem 0 4rem 0;
  .heading__content {
    max-width: 48rem;
    width: 100%;
    .heading {
      font-weight: 700;
      font-size: 3.2rem;
      line-height: 130%;
      letter-spacing: -0.02rem;
      color: $black;
      margin-bottom: 1.6rem;
    }
    .title {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 150%;
      letter-spacing: -0.02rem;
      color: $black;
    }
  }
  &--partners {
    display: grid;
    grid: auto-flow 11.3rem / repeat(4, 1fr);
    grid-gap: 3.2rem;
    margin-top: 6.3rem;
    .partner {
      background: #ffffff;
      border: 1px solid $grey;
      box-sizing: border-box;
      border-radius: 0.8rem;
      display: grid;
      place-content: center;
      align-items: center;
      padding: 1.6rem 2.4rem;
      @include on-event {
        box-shadow: 0.2rem 0.2rem 1.5rem rgba(0, 0, 0, 0.1);
      }
    }
    img {
      width: 100%;
      object-fit: contain;
      height: 10rem;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 5rem 0 3rem 0;
    .heading__content {
      .heading {
        font-size: 2.4rem;
      }
    }
    &--partners {
      grid: auto-flow max-content / repeat(3, 1fr);
      grid-gap: 1.6rem;
      .partner {
        padding: 1.6rem;
        height: 7.6rem;
      }
    }
  }
}
</style>
