import listToOptions from '@/utils/stringListToOptions';

export default {
  all: (state) => state.list,
  asOptions({ list, loadedList }) {
    return (category, country, frequency = '') => {
      if (category || country) {
        const lowerCat = category.toLocaleLowerCase();
        const filteredList = loadedList
          .filter((i) => {
            const lowerCountries = i.country && (typeof country !== 'string')
              ? i.country.map((c) => c.toLowerCase().trim())
              : i.country;
            const categoryMatch = i.category === lowerCat;
            const countryMatch = country
              ? lowerCountries?.includes(country.toLowerCase().trim())
              : true;
            const frequencyMatch = frequency ? i.frequency === frequency : true;
            const isPublished = countryMatch && i.status ? i.status[country] : false;
            if (category && country) {
              return categoryMatch
              && countryMatch
              && frequencyMatch
              && isPublished;
            }
            if (category) return categoryMatch && frequencyMatch && isPublished;
            return countryMatch && frequencyMatch && isPublished;
          })
          .map((i) => i.name)
          .slice(0, 50)
          .sort((a, b) => a.localeCompare(b));
        return listToOptions(filteredList);
      }
      return listToOptions(list.slice(0, 100));
    };
  },
  categories: (state) => state.categories,
  categoryOptions: (state) => listToOptions(state.categories, 'Categories'),
};
