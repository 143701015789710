import { instance } from '../config';

export function fetchCountryData({ country }) {
  console.log(instance.get(`/country-dashboard/user/${country}`));
  return instance.get(`/country-dashboard/user/${country}`);
}

export function fetchCountrySummary({ country }) {
  return instance.get(`/country-dashboard/search/${country}`);
}

export function updateFoundCountry(id) {
  return instance.patch(`/country-dashboard/user/${id}`);
}

export function sendCustomRequest(body) {
  return instance.post('/request', body);
}
