<template>
  <div class="page">
    <header class="header">
      <h1>Hi, how can we help?</h1>
      <p>
        Have questions or need to report an issue with one of our products or services? We got you!
      </p>
      <k-button short @click="ticketModal = true">Contact Us</k-button>
    </header>
    <div class="contact__content">
      <div class="group">
        <h2>Visit Our Headquarters</h2>
        <p>42 Montgomery Rd, Yaba 101245, Lagos.</p>
        <a href="tel:+234 (812) 219-1029">+234 (812) 219-1029</a>
        <a
          href="https://goo.gl/maps/tMhTXqJBKeW5VYne6"
          target="_blank"
          rel="noreferrer"
          class="button-wrapper"
        >
          <k-button short variant="tertiary"> See Map Directions </k-button>
        </a>
      </div>
      <div class="group">
        <h2>Careers</h2>
        <p>Learn more about our job openings.</p>
        <k-button short variant="tertiary" @click="$router.push('/careers')">
          Explore Job Openings
        </k-button>
      </div>
      <div class="group">
        <h2>Press</h2>
        <p>If you are a member of the press, email us at</p>
        <a href="mailto:oafolabi@kwerty.io">oafolabi@kwerty.io</a>
        <k-button short variant="tertiary" @click="$router.push('/blog')">View Our Blog</k-button>
      </div>
    </div>
    <div class="container">
      <website-footer></website-footer>
    </div>
    <k-modal v-if="ticketModal" :open="ticketModal" @close="ticketModal = false">
      <k-card heading="Submit a Ticket" variant="in-modal">
        <div class="input-list">
          <k-input
            label="First Name"
            v-model="$v.ticket.firstName.$model"
            :error="$v.ticket.firstName.$error ? 'FirstName is required' : ''"
          ></k-input>
          <k-input
            label="Email Address"
            v-model="$v.ticket.email.$model"
            :error="$v.ticket.email.$error ? 'Email is required' : ''"
            type="email"
          ></k-input>
          <k-input
            label="Title"
            v-model="$v.ticket.title.$model"
            :error="$v.ticket.title.$error ? 'Title is required' : ''"
          ></k-input>
          <k-input
            label="Message"
            v-model="$v.ticket.messageBody.$model"
            :error="$v.ticket.messageBody.$error ? 'Message is required' : ''"
            variant="textarea"
          ></k-input>
        </div>
        <div class="btn-container">
          <k-button variant="tertiary" @click="reset">Close</k-button>
          <k-button :loading="isLoading" submit @click="sendEmail">Submit</k-button>
        </div>
      </k-card>
    </k-modal>
  </div>
</template>

<script>
import Contact from './Contact';

export default Contact;
</script>

<style lang="scss" scoped src="./Contact.scss">
</style>
