var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['card', {
  'card--in-modal': _vm.inModal,
  'card--no-space-top': _vm.variant === 'no-space-top',
  'wrap-heading': _vm.wrapHeading,
  'longer': _vm.wrapHeading && _vm.longerHeading,
  'card--smaller': _vm.smaller,
  'card--padded': _vm.padInside
}]},[(_vm.heading)?_c('h2',{staticClass:"card__heading"},[_vm._v(" "+_vm._s(_vm.heading)+" ")]):_vm._e(),_c('section',{staticClass:"card__body"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }