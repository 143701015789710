import currencyWithSymbols from '@/utils/currencyWithSymbols';
import stringHelpers from '@/utils/string-helpers';

export default {
  computed: {
    analysis() {
      const { dataset } = this;
      return this.getAnalysis(dataset);
    },
    set2Analysis() {
      try {
        if (!this.dataset2.data) return '';
        const { dataset2 } = this;
        return this.getAnalysis(dataset2);
      } catch (e) {
        return '';
      }
    },
  },
  methods: {
    ...stringHelpers,
    getAnalysis(options) {
      const {
        titleCase, getFirstHalf, getYear, getChange, withUnit,
      } = this;
      const {
        frequency, data: dataInState, country, indicator, startYear, endYear,
      } = options;

      if (!options.data) return '';

      try {
        /* ================
        *   MONTHLY
        * ================ */
        if (frequency === 'monthly') {
          const data = dataInState.map(({ period, value }) => ({
            period, value: parseFloat(value.replaceAll(/,|\s{0,3}/g, '')),
          }));
          let lastMonth = data[data.length - 1];
          let lastIndex = data.length - 1;
          const { length } = data;
          for (let i = length - 1; i > 1; i -= 1) {
            const dataItem = data[i];
            const dataYear = getYear(dataItem?.period);
            if ((dataYear === getYear(endYear)) && dataItem?.value) {
              lastMonth = data[i];
              lastIndex = i;
              break;
            } else if (dataYear < getYear(endYear) && data[i]?.value) {
              lastMonth = data[i];
              lastIndex = i;
              break;
            }
          }
          const { period } = lastMonth;
          const { value } = lastMonth;

          const prevMonth = data[lastIndex - 1];
          const { period: prevPeriod } = prevMonth;
          const { value: prevValue } = prevMonth;

          const { percentage, direction } = getChange({ value, prevValue });

          const last12Months = data
            .slice(lastIndex - 11, lastIndex + 1)
            .filter((d) => d.value || d.value === 0);
          last12Months.sort((a, b) => b.value - a.value);

          // highest value in last 12 months
          const { period: hPeriod, value: hValue } = last12Months.shift();

          // lowest value in last 12 months
          const { period: lPeriod, value: lValue } = last12Months.pop();

          return `${this.capitalize(country)}’s ${titleCase(indicator)} as at
          ${getFirstHalf(period)} ${getYear(period)} was
          ${withUnit(value, options)}.
          This represents a ${percentage}${prevValue === 0 && percentage !== 0 ? '' : '%'} ${direction} month-on-
          month when compared to ${getFirstHalf(prevPeriod)} ${getYear(prevPeriod)}.
          Also, the highest ${titleCase(indicator)} figure in the last 12-month stood at
          ${withUnit(hValue, options)} for the month of ${getFirstHalf(hPeriod)} ${getYear(hPeriod)},
          while the lowest ${titleCase(indicator)} figure was ${withUnit(lValue, options)} for
          the month of ${getFirstHalf(lPeriod)} ${getYear(lPeriod)}.
        `;
        }

        /* ================
        *   QUARTERLY
        * ================ */
        if (frequency === 'quarterly') {
          const data = dataInState.map(({ period, value }) => ({
            period, value: parseFloat(value.replaceAll(/,|\s{0,3}/g, '')),
          }));

          let lastQuarter = data[data.length - 1];
          let lastIndex = data.length - 1;
          const { length } = data;
          for (let i = length - 1; i > 1; i -= 1) {
            const dataItem = data[i];
            const dataYear = getYear(dataItem?.period);
            if ((dataYear === getYear(endYear)) && dataItem?.value) {
              lastQuarter = data[i];
              lastIndex = i;
              break;
            } else if (dataYear < getYear(endYear) && data[i]?.value) {
              lastQuarter = data[i];
              lastIndex = i;
              break;
            }
          }
          const { period } = lastQuarter;
          const { value } = lastQuarter;

          const prevYearQuarter = data[lastIndex - 4];
          const { period: prevPeriod } = prevYearQuarter;
          const { value: prevValue } = prevYearQuarter;

          const { percentage, flow } = getChange({ value, prevValue });

          const last8Quarter = data
            .slice(lastIndex - 7, lastIndex + 1)
            .filter((d) => d.value || d.value === 0);
          last8Quarter
            .sort((a, b) => b.value - a.value);
          // highest value in last 12 months
          const { period: hPeriod, value: hValue } = last8Quarter.shift();

          // lowest value in last 12 months
          const { period: lPeriod, value: lValue } = last8Quarter.pop();

          return `${this.capitalize(country)}’s latest ${titleCase(indicator)} figure is
        ${withUnit(value, options)} for ${getFirstHalf(period)} ${getYear(period)} as shown
        in the chart.
        This represents a ${percentage}${prevValue === 0 && percentage !== 0 ? '' : '%'} ${flow}
        when compared to ${getFirstHalf(prevPeriod)} ${getYear(prevPeriod)}. The highest
        ${this.capitalize(country)}’s ${titleCase(indicator)}
        value in the last 8-quarter stood at ${withUnit(hValue, options)} in
        ${getFirstHalf(hPeriod)} ${getYear(hPeriod)}. In the same manner, the lowest
        ${this.capitalize(country)}’s ${titleCase(indicator)} value was ${withUnit(lValue, options)}
        in ${getFirstHalf(lPeriod)} ${getYear(lPeriod)}.`;
        }

        /* ================
        *   YEARLY
        * ================ */
        if (frequency === 'yearly') {
          const data = dataInState.map(({ period, value }) => ({
            period, value: parseFloat(value?.replaceAll(/,|\s{0,3}/g, '')),
          }));
          const dataLength = data.length;
          let firstYear = data.find((d) => Number(d.period) === Number(startYear))
            || data[0].period;
          for (let i = 0; i < dataLength; i += 1) {
            const dataItem = data[i];
            const dataYear = getYear(dataItem?.period);
            if ((dataYear === getYear(endYear)) && dataItem?.value) {
              firstYear = data[i];
              break;
            } else if (dataYear < getYear(endYear) && data[i]?.value) {
              firstYear = data[i];
              break;
            }
          }
          const { period: firstPeriod } = firstYear;
          const { value: firstValue } = firstYear;

          // const recentYear = data.find((d) => Number(d.period) === Number(endYear))
          //   || data[data.length - 1];
          // const recentIndex = data.findIndex((d) => Number(d.period) === Number(endYear))
          //   || data.length - 1;
          let recentYear = data[data.length - 1];
          let recentIndex = data.length - 1;
          const { length } = data;
          for (let i = length - 1; i > 1; i -= 1) {
            const dataItem = data[i];
            const dataYear = getYear(dataItem?.period);
            if ((dataYear === getYear(endYear)) && dataItem?.value) {
              recentYear = data[i];
              recentIndex = i;
              break;
            } else if (dataYear < getYear(endYear) && data[i]?.value) {
              recentYear = data[i];
              recentIndex = i;
              break;
            }
          }
          const { period } = recentYear;
          const { value } = recentYear;

          const { percentage, growth } = getChange({ value, prevValue: firstValue });

          const last5Years = data
            .slice(recentIndex - 4, recentIndex + 1)
            .filter((d) => d.value || d.value === 0);
          last5Years.sort((a, b) => b.value - a.value);

          // highest value in last 12 months
          const { period: hPeriod, value: hValue } = last5Years.shift();

          // lowest value in last 12 months
          const { period: lPeriod, value: lValue } = last5Years.pop();

          return `${this.capitalize(country)}’s ${titleCase(indicator)} value as at
          ${firstPeriod} was ${withUnit(firstValue, options)}.
          This figure has ${growth} in value
          by ${percentage}${firstValue === 0 && percentage !== 0 ? '' : '%'} when compared to the
          ${period} ${titleCase(indicator)}.
          In the last 5 years, ${this.capitalize(country)}’s highest ${titleCase(indicator)}
          value was ${withUnit(hValue, options)} in ${hPeriod} while the lowest
          ${titleCase(indicator)} value was ${withUnit(lValue, options)} in ${lPeriod}.`;
        }
        return '';
      } catch (e) {
        console.log(e);
        return '';
      }
    },
    getFirstHalf(val) {
      const months = {
        jan: 'January',
        feb: 'February',
        mar: 'March',
        apr: 'April',
        may: 'May',
        jun: 'June',
        jul: 'July',
        aug: 'August',
        sep: 'September',
        oct: 'October',
        nov: 'November',
        dec: 'December',
      };
      if (val && months[val.split('-')[0].toLowerCase()]) {
        return months[val.split('-')[0].toLowerCase()];
      }
      return val.split('-')[0];
    },
    getChange({ value, prevValue }) {
      const val = parseFloat(value) || 0;
      const prev = parseFloat(prevValue) || 0;
      const change = val - prev;
      let percentage = ((Math.abs(change / Math.abs(prev)) * 100) || 0)
        .toFixed(2);
      if (prev === 0 && val === 0) {
        percentage = 0;
      } else if (prev === 0) {
        percentage = this.withUnit(val, this.dataset);
      }
      const direction = change >= 0 ? 'increase' : 'decrease';
      const flow = change >= 0 ? 'rise' : 'decline';
      const growth = change >= 0 ? 'grown' : 'declined';
      return {
        percentage, direction, flow, growth,
      };
    },
    getSymbol(code) {
      const found = currencyWithSymbols.filter((c) => c.abbreviation === code)[0];
      return found ? found.symbol : '';
    },
  },
};
