import { mapGetters, mapMutations } from 'vuex';
import menu from '@/config/menu';
import avatar from '@/assets/avatar.svg';
import { KButton } from '@/components';

export default {
  name: 'Navbar',
  data: () => ({
    isOpen: false,
    dummyImage: avatar,
    menu,
  }),
  components: { KButton },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      token: 'auth/getToken',
      profile: 'auth/getProfile',
    }),
    userData() {
      return { ...this.user, ...this.profile };
    },
    loggedIn() {
      return !!this.token;
    },
  },
  methods: {
    ...mapMutations({
      reset: 'auth/RESET',
    }),
    logout() {
      this.reset();
      if (this.$route.meta.auth) {
        // this.$router.go(-1);
      }
      window.location.href = '/';
      this.close();
    },
    close(now = false) {
      if (now) {
        this.isOpen = false;
      } else {
        // navigate to page before close
        setTimeout(() => {
          this.isOpen = false;
        }, 300);
      }
      try {
        setTimeout(() => {
          this.$refs.navContent.scrollTo(0, 0);
        }, 500);
      } catch (e) {
        console.log(e.message);
      }
    },
  },
};
