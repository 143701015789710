import { storyblok } from '@/config';
import { getPosts } from '@/api/blog';

import { KButton } from '@/components';
import WebsiteFooter from '../WebsiteFooter.vue';
import DateFormat from '@/mixins/DateFormat';

export default {
  name: 'Blog',
  components: { WebsiteFooter, KButton },
  mixins: [DateFormat],
  data: () => ({
    total: NaN,
    posts: [],
    whitepapers: [],
    latest: {},
    isLoading: true,
    fetching: false,
  }),
  async mounted() {
    this.isLoading = true;
    await this.getPosts();
    await this.getWhitepapers();
    this.isLoading = false;
  },
  methods: {
    async getPosts() {
      const { total, stories: posts } = await getPosts({});
      this.total = total;
      const latest = posts.shift();
      const uuid = latest.content.author;
      const author = {};
      if (uuid) {
        await storyblok.get(`/stories/${uuid}?find_by=uuid`).then(({ data: { story } }) => {
          author.name = story.content.name;
          author.image = story.content.image;
          latest.content.author = author;
        });
      }
      this.posts = posts;
      this.latest = latest;
    },
    async getWhitepapers() {
      try {
        this.whitepapers = await storyblok.get('/stories?content_type=Whitepaper')
          .then(({ data }) => data)
          .then(({ stories }) => stories);
      } catch (e) {
        console.log(e.message);
      }
    },
    getExtension(filename) {
      const nameBreakdown = filename.split('.');
      return nameBreakdown[nameBreakdown.length - 1];
    },
    async fetchNext() {
      try {
        this.fetching = true;
        const { total, stories } = await getPosts({ page: 2, perPage: this.posts.length + 1 });
        this.total = total;
        this.posts = [...this.posts, ...stories.slice(0, 3)];
      } catch (e) {
        console.log(e.message);
      } finally {
        this.fetching = false;
      }
    },
  },
  computed: {
    reachedEnd() {
      return this.total === this.posts.length + 1;
    },
  },
};
