import { mapActions } from 'vuex';
import { KAuth, KInput, KButton } from '@/components';

export default {
  name: 'ForgotPassword',
  components: {
    KAuth,
    KInput,
    KButton,
  },
  data: () => ({
    user: {
      email: '',
    },
    isLoading: false,
    error: {},
  }),
  methods: {
    ...mapActions({
      forgotPassword: 'auth/forgotPassword',
    }),
    async sendResetLink() {
      const { user: { email } } = this;
      this.isLoading = true;
      try {
        const response = await this.forgotPassword({ email });
        if (!response.error) {
          this.$toast.show({ message: response });
          this.email = '';
          this.isLoading = false;
        } else {
          throw Error(response.error);
        }
      } catch (error) {
        this.$toast.show({ message: error });
        this.isLoading = false;
      }
    },
  },
};
