import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import { APP_NAME } from '../config/app';

import auth from './modules/auth';
import indicators from './modules/indicators';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth'],
  key: APP_NAME,
});

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    indicators,
  },
  plugins: [vuexLocal.plugin],
});
