import { storyblokCareers } from '../config';

const getJobs = async () => {
  const { data } = await storyblokCareers.get('/stories?content_type=Job');
  return data.stories;
};

const getSingleJob = async ({ slug }) => {
  const { data } = await storyblokCareers.get(`/stories/${slug}?content_type=Job`);
  return data.story;
};

export { getJobs, getSingleJob };
