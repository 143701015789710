import { Months } from '../../config/months';
import { colorList } from '../../config/library';
import Loader from '@/components/Loader/Loader.vue';

export default {
  name: 'AccordionCard',
  props: ['content', 'index', 'selected', 'loadingStatement'],
  components: { Loader },
  data() {
    return {
      isShow: false,
      isAccept: false,
      colors: [{ name: '' }],
    };
  },
  mounted() {
    this.colors = colorList;
  },
  methods: {
    updateAccept(data, title) {
      this.isAccept = true;
      this.isShow = false;
      this.$emit('handleAccept', { content: data, title });
      this.$toast.show({ message: 'Metric value accepted!' });
    },
    scrollToSection(sectionRef) {
      if (this.$refs[sectionRef])
        this.$refs[sectionRef].scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
  },
  watch: {
    selected: function (newVal) {
      if (newVal !== null) this.scrollToSection('header' + newVal);
    },
  },
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat('en-US').format(value);
    },
    capitalize(val) {
      const firstCharVal = val.split('_');
      const month = Months.find((m) => m.value === firstCharVal[0]).name;
      if (!val) return '';
      return (
        (month.toString().charAt(0).toUpperCase() + month.slice(1)).replace('_', ' ') +
        ' ' +
        firstCharVal[1]
      );
    },
    seperator(val) {
      const quarter = val.split('_')[0].replace('q', '');
      const quarters = [
        { label: 'First', value: 1 },
        { label: 'Second', value: 2 },
        { label: 'Third', value: 3 },
        { label: 'Fourth', value: 4 },
      ];
      if (quarter) return quarters.find((q) => q.value == quarter).label;
    },
  },
};
