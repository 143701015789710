import { getYear } from '@/views/Search/Save/downloadTemplate';
import numberOr from './numberOr';

const toCorrectedPeriod = (p) => p.trim().replace(/\d{1,4}$/, getYear(p));

const correctLabelKeys = (obj) => (Object.entries(obj).length
  ? Object.entries(obj).reduce((accum, [label, value]) => {
    const newVal = accum;
    newVal[toCorrectedPeriod(label)] = value;
    return newVal;
  }) : obj);

const getDataForChart = (
  set1 = [],
  set2 = [],
  {
    startYear = null,
    endYear = null,
    startYear2 = null,
    endYear2 = null,
  },
) => {
  const weightOf = (part) => {
    const weights = {
      q1: 1,
      q2: 2,
      q3: 3,
      q4: 4,
      jan: 1,
      feb: 2,
      mar: 3,
      apr: 4,
      may: 5,
      jun: 6,
      jul: 7,
      aug: 8,
      sep: 9,
      oct: 10,
      nov: 11,
      dec: 12,
    };

    const toLower = part.toLowerCase();
    return weights[toLower];
  };

  const getParts = (period) => {
    const notYearly = period.includes('-');
    const match = notYearly
      ? period.match(/(?<firstHalf>.{1,4})-(?<year>\d{1,4})/i)
      : period.match(/(?<year>\d{1,4})/);
    if (!match) return period;

    const toInt = Number(match.groups.year);

    return {
      ...match.groups,
      year: getYear(toInt),
    };
  };

  const byPeriod = (a, b) => {
    const type = typeof a;
    const isString = type === 'string';
    const group1 = getParts(isString ? a : a.period);
    const group2 = getParts(isString ? b : b.period);

    const diff = group1.year - group2.year;

    if (diff !== 0 || !group1.firstHalf || !group2.firstHalf) return diff;
    return weightOf(group1.firstHalf) - weightOf(group2.firstHalf);
  };

  const toObject = (list) => list.reduce((accum, cur) => ({
    ...accum,
    [cur.period]: cur.value,
  }), {});

  let labels1 = set1.map((i) => i.period).map(toCorrectedPeriod);
  let labels2 = set2.map((i) => i.period).map(toCorrectedPeriod);

  labels1 = labels1.filter((label) => {
    const { year } = getParts(label);
    const aboveStartYear = startYear ? year >= startYear : true;
    const belowEndYear = endYear ? year <= endYear : true;
    return aboveStartYear && belowEndYear;
  });

  labels2 = labels2.filter((label) => {
    const { year } = getParts(label);
    const aboveStartYear = startYear2 ? year >= startYear2 : true;
    const belowEndYear = endYear2 ? year <= endYear2 : true;
    return aboveStartYear && belowEndYear;
  });

  const joinedLabels = Array.from(
    new Set([...labels1, ...labels2]),
  );
  joinedLabels.sort(byPeriod);

  const data1Obj = correctLabelKeys(toObject(set1));
  const data2Obj = correctLabelKeys(toObject(set2));

  const datalist1 = joinedLabels.map((label) => (
    labels1.includes(label) ? numberOr(null)(data1Obj[label]) : null));
  const datalist2 = joinedLabels.map((label) => (
    labels2.includes(label) ? numberOr(null)(data2Obj[label]) : null));

  return {
    datalist1,
    datalist2,
    labels: joinedLabels,
  };
};

export default getDataForChart;
