<template>
  <div class="content__wrapper">
    <div class="container">
      <header class="content__header">
        <div class="content__header--item">
          <h1 class="heading">Become a Leader in Data Analysis and Visualization</h1>
          <p class="sub-heading">
            Gain further training in machine learning, big data analytics and artificial
            intelligence.
          </p>
        </div>
      </header>
    </div>
    <section class="content__hero--section">
      <div class="mapbase-background">
        <img src="@/assets/mapbase.png" alt="" />
      </div>
      <div class="content__hero--section-image">
        <div class="content__hero--section-image-item">
          <img src="@/assets/background.jpg" alt="" />
        </div>
        <div class="content__hero--section-image-text">
          <p>
            Our focus is to build a world class training center dedicated for data mining, analysis
            and visualization, catering to a class of people interested in building a career within
            the sphere.
          </p>
          <k-button @click="$router.push({ name: 'Login' })" class="btn" variant="tertiary"
            >Learn More About Us</k-button
          >
        </div>
      </div>
    </section>
    <section class="section--one">
      <div class="container">
        <header>
          <h2 class="title">Training Modules</h2>
          <p class="description">
            Here’s an opportunity to receive world class training on data journalism, data mining,
            analysis and visualization and preparatory school for machine learning, artificial
            intelligence and data science ecosystem.
          </p>
        </header>
        <div class="section--one-body">
          <div class="section--one-body-item">
            <p class="months">3 MONTHS</p>
            <p class="sub-title">Data Analyst Course</p>
            <ul>
              <li>
                Spreadsheet Training
                <p>Including basic and advanced training in Microsoft Excel</p>
              </li>
              <li>
                Data Basics
                <p>Scripting, Wrangling and Visualization</p>
              </li>
              <li>
                Business Intelligence
                <p>Microsoft PowerBI, Tableau, Infogram, Data Wrapper Data</p>
              </li>
              <li>Data Reporting and Insight Development</li>
            </ul>
          </div>
          <div class="section--one-body-item">
            <p class="months">6 MONTHS</p>
            <p class="sub-title">Advanced Data Analyst Course</p>
            <ul>
              <li>
                Python Programming
                <p>Introduction and Case Studies</p>
              </li>
              <li>
                R Programming
                <p>Introduction and Case Studies</p>
              </li>
              <li>
                SQL
                <p>Database Management Systems</p>
              </li>
              <li>Machine Learning</li>
            </ul>
          </div>

        </div>
      </div>
    </section>
    <k-partners></k-partners>

    <k-footer></k-footer>
  </div>
</template>

<script>
import { KButton } from '@/components';
import KFooter from '../WebsiteFooter.vue';
import KPartners from './Partners.vue';

export default {
  name: 'Leaders',
  components: {
    KButton,
    KFooter,
    KPartners,
  },
  data: () => ({
    openModal: false,
    isLoading: false,
    ticket: {
      name: '',
      organization: '',
      email: '',
      phone: null,
    },
    touched: {},
    error: {},
    general_error: '',
  }),
};
</script>

<style lang="scss" scoped src="./style.scss"></style>
