import isEmail from 'validator/es/lib/isEmail';
import isStrongPassword from 'validator/es/lib/isStrongPassword';

export default {
  methods: {
    validate(field) {
      switch (field) {
        case 'email':
          return (val) => {
            const valid = isEmail(val, { message: 'Not a valid email' });
            return valid ? '' : 'Your email is not correct';
          };
        case 'password':
          return (val, match = '') => {
            if (match && val !== match) {
              return "Passwords don't match";
            }
            if (typeof val === 'string' && val.length < 7) {
              return 'Password should be 7 characters minimum';
            }
            const valid = isStrongPassword(val, { minLength: 7, minSymbols: 0 });
            if (!valid) return 'Password should contain at least 1 uppercase, 1 number';
            return '';
          };
        default:
          return true;
      }
    },
  },
  watch: {
    email(val) {
      this.touched.email = true;
      this.error.email = val ? this.validate('email')(val) : '';
    },
    password(val) {
      this.touched.password = true;
      this.error.password = val ? this.validate('password')(val) : '';
      if (this.confirm_password) {
        this.error.confirm_password = val
          ? this.validate('password')(this.confirm_password) : '';
      }
    },
    confirm_password(val) {
      this.touched.confirm_password = true;
      this.error.confirm_password = val ? this.validate('password')(val, this.password || '') : '';
    },
    oldPassword(val) {
      this.touched.oldPassword = true;
      this.error.oldPassword = val ? this.validate('password')(val) : '';
    },
  },
  computed: {
    email() {
      return this.user.email;
    },
    password() {
      return this.user.password;
    },
    confirm_password() {
      return this.user.confirm_password;
    },
    oldPassword() {
      return this.user.oldPassword;
    },
  },
};
