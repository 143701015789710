var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'content__wrapper': _vm.radial},on:{"click":_vm.sendClickToLabel}},[_c('div',{class:['wrapper',
{
  'wrapper--radial' : _vm.radial,
}
]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],class:[
      'checkbox__input',
      ( _obj = {}, _obj[("checkbox__input--" + _vm.fill)] = _vm.fill, _obj['checkbox__input--radial'] = _vm.radial, _obj ) ],attrs:{"id":_vm.name,"name":_vm.name,"type":"checkbox"},domProps:{"value":_vm.value,"checked":Array.isArray(_vm.innerValue)?_vm._i(_vm.innerValue,_vm.value)>-1:(_vm.innerValue)},on:{"change":function($event){var $$a=_vm.innerValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.innerValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.innerValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.innerValue=$$c}}}}),_c('label',{staticClass:"checkbox__label",attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.label))])])])}
var staticRenderFns = []

export { render, staticRenderFns }