<template>
  <div class='wrapper'>
    <div :class="['chart', {suspense: isLoading}]">
      <div :class='["color-box color-box--left",
        `color-box--${dataset.colour && dataset.colour.slice(1)}`]'></div>

      <div :class='["color-box color-box--right",
        `color-box--${dataset.colour && dataset2.colour.slice(1)}`]'></div>

      <template v-if='!isLoading'>
          <div class='metric metric--left'>
            {{ dataset.metric }} {{ dataset.currency }}
          </div>
          <bar-chart-wrapper v-if="show"
                             :datasets="chartDataLocal.datasets"
                             :labels="chartDataLocal.labels"/>
          <div class='metric metric--right'>
            {{ dataset2.metric }} {{ dataset2.currency }}
          </div>
      </template>
    </div>

    <footer class='footer'>
      <div class='group'>
        <div class='heading'>{{ dataset.country }} - {{ dataset.indicator }}
          <!--        {{ dataset.startYear }}&ndash;{{ dataset.endYear }}-->
        </div>
        x
        <div class='heading'>{{ dataset2.country }} - {{ dataset2.indicator }}
          <!--        {{ dataset.startYear }}&ndash;{{ dataset.endYear }}-->
        </div>
      </div>
      <div class='from'>Powered by <a href='https://kwerty.io' rel='noreferrer' target='_blank'>
        Kwerty</a></div>
    </footer>
  </div>
</template>

<script>
import CompareMixin from '@/mixins/CompareMixin';
import DatasetResult from '@/mixins/DatasetResult';
import GetLatestWithValue from '@/mixins/GetLatestWithValue';

export default {
  name: 'CompareChart',
  mixins: [CompareMixin, DatasetResult, GetLatestWithValue],
};
</script>

<style lang='scss' scoped>
.wrapper {
  padding: 5%;
}

.chart {
  min-height: 32vw;
  position: relative;
  display: grid;
  grid: 1fr / 2rem auto minmax(0, 2rem);
  align-items: center;

  .color-box {
    width: 1.6rem;
    height: 1.6rem;
    top: -3.2rem;
    background: $grey-light;

    $box-padding: 2%;

    &--left {
      position: absolute;
      left: $box-padding;
    }

    &--right {
      position: absolute;
      right: $box-padding;
    }

    $colors: ('E1331B', 'FFA500', 'FFE800', '62BD69', '00CEF1', '0F2B90', '900F7B',
    'F06292', 'CC00CC');

    @each $color in $colors {
      &--#{$color} {
        background-color: #{'#'+$color}
      }
    }
  }

  .metric {
    font-weight: 300;
    position: relative;
    white-space: nowrap;
    text-orientation: mixed;

    &--left {
      transform: rotateZ(180deg);
      writing-mode: vertical-lr;
    }

    &--right {
      writing-mode: vertical-lr;

    }
  }

}

.footer {
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  padding: 0.8rem 1.6rem;
  background: rgba($dark-purple, 0.05);
  color: rgba(0, 0, 0, 0.5);
  margin-top: 2.4rem;
  text-align: center;

  & > * {
    font-size: calc(max(min(1.2rem, 2.25vw), 0.8rem));
    line-height: 1.2;
  }

  .heading {
    color: rgba(0, 0, 0, 0.75);
    font-weight: 600;
    font-size: calc(max(min(1.2rem, 2.25vw), 0.8rem));
    line-height: 1.2;
  }

  a {
    color: $dark-purple;
  }

  .from {
    text-transform: none;
  }

  .group {
    display: flex;
    gap: 1rem;
  }
}
</style>
