<template>
  <div class="not__found">
    <div class="not_found-content">
      <div class="img-container">
        <!-- <img alt="" src="@/assets/attention.svg" /> -->
      </div>
      <h1>404</h1>
      <!-- <h2 class="page-not-found">Page not found!</h2> -->
      <p class="page-not-found">Sorry, we couldn't find the page your looking for.</p>
    </div>
    <k-button short class="btn-home" @click="$router.push({ name: 'Home' })">Go Home</k-button>
  </div>
</template>

<script>
import { KButton } from '@/components';

export default {
  name: 'Notfound',
  components: {
    KButton,
  },
};
</script>

<style lang="scss" scoped>
.not__found {
  margin-top: 10rem;
  width: 100%;
  height: 75vh;
  display: grid;
  place-content: center;
  .page-not-found {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 2.4rem;
    color: $grey-darker;
    max-width: 20rem;
  }
}
.not_found-content {
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
}
.img-container {
  img {
    width: 8rem;
    height: 8rem;
  }
}

h1 {
  font-size: 8rem;
  margin: 2.4rem 0 1.2rem;
}
.btn-home {
  width: max-content;
  margin: 2.4em auto 0;
}
</style>
