import { instance } from '../config';

export function uploadFile(form) {
  return instance.post('/upload', form, {
    'Content-Type': 'multipart/form-data',
  });
}

export function updateProfile(user) {
  return instance.put('/customer', user);
}
