import {
  signup,
  verifyOtp,
  login,
  resendOtp,
  fetchProfile,
  updatePassword,
  updateProfile,
  forgotPassword,
  resetPassword,
  sendTicket,
  disableAccount,
} from '@/api';
import errorHandler from '@/utils/error-handler';

export default {
  signup: ({ commit }, body) => signup(body)
    .then(async ({ data }) => {
      commit('SET_USER_ID', data.data.id);
      const { message } = data;
      return message;
    })
    .catch((response) => errorHandler(response)),
  verifyOtp: ({ commit }, body) => verifyOtp(body)
    .then(async ({ data: { data } }) => {
      commit('SET_TOKEN', data.token);
      commit('SET_USER', data);
      return data;
    })
    .catch((response) => errorHandler(response)),
  login: ({ commit, dispatch }, body) => login(body)
    .then(({ data }) => {
      const { token, ...user } = data.data;
      commit('SET_USER_ID', user.id);
      if (user.isVerified) {
        commit('SET_TOKEN', token);
        commit('SET_USER', user);
        dispatch('fetchProfile', {});
      }
      return data;
    })
    .catch((response) => errorHandler(response)),
  fetchProfile: ({ commit }, body) => fetchProfile(body)
    .then(({ data }) => {
      commit('SET_PROFILE', data.data);
      const { message } = data;
      return message;
    })
    .catch((response) => errorHandler(response)),
  updatePassword: (context, body) => updatePassword(body)
    .then(({ data }) => {
      const { message } = data;
      return message;
    })
    .catch((response) => errorHandler(response)),
  updateProfile: ({ dispatch }, body) => updateProfile(body)
    .then(({ data }) => {
      dispatch('fetchProfile', {});
      const { message } = data;
      return message;
    })
    .catch((response) => errorHandler(response)),
  forgotPassword: (context, body) => forgotPassword(body)
    .then(({ data }) => {
      const { message } = data;
      return message;
    })
    .catch((response) => errorHandler(response)),
  resetPassword: (context, body) => resetPassword(body)
    .then(({ data }) => {
      const { message } = data;
      return message;
    })
    .catch((response) => errorHandler(response)),
  resendOtp: (context, body) => resendOtp(body)
    .then(({ data }) => {
      const { message } = data;
      return message;
    })
    .catch((response) => errorHandler(response)),
  sendTicket: (context, body) => sendTicket(body)
    .then(({ data }) => {
      const { message } = data;
      return message;
    })
    .catch((response) => errorHandler(response)),
  disableAccount: (context, body) => disableAccount(body)
    .then(async ({ data }) => data)
    .catch((response) => errorHandler(response)),
};
