<template>
  <div class="page">
    <header class="header">
      <div class="heading">
        <div class="close" @click="$emit('back')">
          <img alt="" src="@/assets/close.png" />
        </div>
        <h1>Save as PNG</h1>
        <div class="back">
          <img alt="back" src="@/assets/arrow.svg" @click="$emit('back')" />
          Customize Your PNG File
        </div>
      </div>

      <div class="controls">
        <k-button :loading="isDownloading" variant="tertiary" @click="downloadPng"
          >Download Your File
        </k-button>
        <k-button negative variant="tertiary" @click="$emit('back')">Cancel Download</k-button>
      </div>
    </header>

    <!--  BODY  -->
    <div class="page-body">
      <div class="inputs">
        <k-input v-model="dataset.title" label="Edit Title"></k-input>
        <k-input v-model="dataset.notes" label="Author's Note" variant="textarea"></k-input>
        <k-input
          v-model="position"
          :optionsDisplay="positions"
          label="Position of Authors Note"
          type="select"
        ></k-input>
        <k-input
          :value="dataset.source + ' • Created with Kwerty'"
          disabled
          label="Source"
        ></k-input>
        <k-input
          v-model="dataset.chartType"
          :optionsDisplay="chartTypeOptions"
          label="Chart Type"
          type="select"
          disabled
        ></k-input>
        <!-- inputs -->
      </div>

      <div class="preview">
        <h2>PNG PREVIEW</h2>
        <div class="preview__screen">
          <div class="title">{{ dataset.title }}</div>
          <div v-if="position === 'top'" class="notes">{{ dataset.notes }}</div>
          <!--          <img :src='dataset.image' alt='' class='chart'>-->
          <div :class="['chart', { compare: !!dataset.dataset2 }]">
            <div class="metric">
              <div class="">
                <span>
                  {{ dataset.country }}
                </span>
                {{ dataset.indicator }}
              </div>
              <div>
                {{ dataset.metric }} <span v-if="dataset.currency">({{ dataset.currency }})</span>
              </div>
            </div>
            <img :src="dataset.image" alt="" />
            <div v-if="dataset.dataset2" class="metric">
              <div class="">
                <span>
                  {{ dataset.dataset2.country }}
                </span>
                {{ dataset.dataset2.indicator }}
              </div>
              <div>
                {{ dataset.dataset2.metric }}
                <span v-if="dataset.dataset2.currency"> ({{ dataset.dataset2.currency }})</span>
              </div>
            </div>
          </div>

          <div v-if="position === 'bottom'" class="notes">{{ dataset.notes }}</div>
          <div class="source">Source: {{ dataset.source }} &middot; Kwerty</div>
        </div>
      </div>
    </div>
    <!-- preview mobile start-->
    <div v-if="showPngPreview === true" class="preview--mobile">
      <div class="preview__screen--mobile">
        <div class="preview--header">
          <h2>PNG PREVIEW</h2>
          <div class="close">
            <img alt="" src="@/assets/close.png" @click="showPngPreview = false" />
          </div>
        </div>
        <div class="preview__screen--content">
          <div class="preview__screen--content--item">
            <div class="title">{{ dataset.title }}</div>
            <div v-if="position === 'top'" class="notes">{{ dataset.notes }}</div>
            <div :class="['chart', { compare: !!dataset.dataset2 }]">
              <div class="metric">
                {{ dataset.metric }} <span v-if="dataset.currency">({{ dataset.currency }})</span>
              </div>
              <img :src="dataset.image" alt="" />
              <div v-if="dataset.dataset2" class="metric">
                {{ dataset.dataset2.metric }}
                <span v-if="dataset.dataset2.currency"> ({{ dataset.dataset2.currency }})</span>
              </div>
            </div>
            <div v-if="position === 'bottom'" class="notes">{{ dataset.notes }}</div>
            <div class="source">Source: {{ dataset.source }} &middot; Kwerty</div>
          </div>
        </div>
        <!-- preview -->
      </div>
    </div>
    <!-- preview mobile end -->
    <div class="controls--mobile">
      <k-button variant="tertiary" @click="showPngPreview = true">Preview PNG</k-button>
      <k-button variant="secondary" @click="downloadPng" :loading="isDownloading">
        Download</k-button
      >
    </div>

    <div class="png-screen" ref="screen"></div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import * as htmlToImage from 'html-to-image';

// import download from 'downloadjs';
import { KButton, KInput } from '@/components';
// import { downloadDataset } from '@/api';

// helpers
import chartTypeOptions from '@/utils/chart-type-options';
import stringHelpers from '@/utils/string-helpers';
import scrollToTop from '@/utils/scrollToTop';

import { pngTemplate } from './downloadTemplate';

export default {
  name: 'SaveAsPng',
  components: {
    KInput,
    KButton,
  },
  data: () => ({
    position: 'top',
    positions: {
      top: 'Top',
      bottom: 'Bottom',
    },
    dataset: {},
    showPngPreview: false,
    chartTypeOptions,
    show: true,
    isDownloading: false,
    hideScreen: true,
  }),
  props: {
    options: {
      type: Object,
    },
  },
  mounted() {
    setTimeout(() => scrollToTop(), 0);
    const { options } = this;
    const { country, indicator } = options;
    this.dataset = { ...options, title: `${this.titleCase(country)} ${indicator}` };
    console.log('Data');
    console.log(this.dataset.dataset2);
  },
  methods: {
    ...stringHelpers,
    getYear(val) {
      return val.match(/\d+$/)[0];
    },
    toOptions(list) {
      return list.reduce((accumulated, current) => {
        const newAccum = { ...accumulated };
        newAccum[current] = parseInt(current, 10);
        return newAccum;
      }, {});
    },
    async downloadPng() {
      const { screen } = this.$refs;
      const { country, indicator } = this.dataset;
      const template = pngTemplate({ ...this.dataset, position: this.position });
      this.hideScreen = false;

      try {
        screen.style.display = 'block';
        screen.innerHTML = template;
        this.isDownloading = true;
        // need these extra calls in safari
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.includes('safari/') && !userAgent.includes('chrome')) {
          console.log('in safari');
          await htmlToImage.toPng(screen);
          await htmlToImage.toPng(screen);
          await htmlToImage.toPng(screen);
        }
        // this call alone works for chrome and firefox
        const blob = await htmlToImage.toPng(screen);

        setTimeout(async () => {
          const fileName = `${this.titleCase(country)}_${this.titleCase(indicator)}.png`;
          await saveAs(blob, fileName);
          screen.style.display = 'none';
          this.$toast.show({ message: `Downloaded ${fileName}` });
        }, 1000);
      } catch (e) {
        this.$toast.show({ message: e.message });
      } finally {
        this.isDownloading = false;
      }
    },
  },
  watch: {
    show(val) {
      if (!val) {
        setTimeout(() => {
          this.show = true;
        }, 0);
      }
    },
  },
  computed: {
    values() {
      const {
        dataset: { startYear, endYear, values, frequency },
        getYear,
      } = this;
      if (!Object.values(values).length) return {};
      const data = values[frequency];
      return data.filter((item) => {
        const year = getYear(item.period);
        const start = parseInt(startYear, 10);
        const end = parseInt(endYear, 10);
        return year >= start && year <= end;
      });
    },
    chartData() {
      const { dataset, values } = this;
      return {
        labels: values && values.map((item) => item.period),
        datasets: [
          {
            data: values && values.map((item) => parseInt(item.value, 10) || 0),
            backgroundColor: dataset.colour,
            pointBackgroundColor: dataset.colour,
            borderColor: dataset.colour,
            pointHitRadius: 4,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped src="./SaveAsPng.scss"></style>
