import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  data: () => ({ gradient2: null }),
  props: ['chartData', 'height'],
  computed: {
    options() {
      const { chartData: { datasets } } = this;

      // fine tuning charts
      const isTwoDatasets = datasets.length === 2;
      const values1 = datasets.length && [...datasets[0].data];
      const values2 = isTwoDatasets ? [...datasets[1].data] : [];

      const min1 = Math.min(...values1);
      const max1 = Math.max(...values1);
      const container1 = {
        min: min1,
        max: max1,
        lowerRatio: Math.abs(min1) / (Math.abs(min1) + max1),
      };

      const min2 = isTwoDatasets && Math.min(...values2);
      const max2 = isTwoDatasets && Math.max(...values2);
      const container2 = isTwoDatasets
        ? { min: min2, max: max2, lowerRatio: Math.abs(min2) / (Math.abs(min2) + max2) }
        : {};

      const fixBoundaries = () => {
        const { lowerRatio } = container1;
        if (lowerRatio < 0.5) {
          container1.suggestedMin = -container1.max;
        } else if (lowerRatio > 0.5) {
          container1.suggestedMax = Math.abs(container1.min);
        }

        const { lowerRatio: lowerRatio2 } = container2;
        if (lowerRatio2 < 0.5) {
          container2.suggestedMin = -container2.max;
        } else if (lowerRatio2 > 0.5) {
          container2.suggestedMax = Math.abs(container2.min);
        }
      };

      if (isTwoDatasets && (container1.min < 0 || container2.min < 0)) {
        fixBoundaries();
      }

      const yAxes = [
        {
          id: 'A',
          position: 'left',
          gridLines: { borderDash: [2, 2] },
          ticks: {
            suggestedMin: container1.suggestedMin || container1.min,
            suggestedMax: container1.suggestedMax || container1.max,
            beginAtZero: true,
            fontSize: datasets[0].small ? 5 : 12,
            userCallback(value) {
              return value.toLocaleString();
            },
          },
        },
      ];
      if (isTwoDatasets) {
        yAxes.push({
          ...yAxes[0],
          id: 'B',
          position: 'right',
          gridLines: { color: 'rgba(0, 0, 0, 0)', zeroLineColor: 'rgba(0, 0, 0, 0)' },
          ticks: isTwoDatasets && {
            suggestedMin: container2.suggestedMin || container2.min,
            suggestedMax: container2.suggestedMax || container2.max,
            beginAtZero: true,
          },
        });
      }

      return {
        responsive: true,
        aspectRatio: 1.75,
        tooltips: {
          callbacks: {
            label({ datasetIndex, index }, data) {
              const value = data.datasets[datasetIndex].data[index];
              return value.toLocaleString();
            },
          }, // end callbacks:
        }, // end tooltips
        scales: {
          yAxes,
          xAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                padding: 10,
                fontSize: datasets[0].small ? 5 : 12,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      };
    },
  },
  mounted() {
    const { hexToRgb } = this;
    this.$refs.canvas.height = this.height || 120;
    this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(900, 0, 900, 400);
    this.gradient2 = this.$refs.canvas.getContext('2d').createLinearGradient(900, 0, 900, 400);
    // use border color for gradient backround
    const { r, g, b } = hexToRgb(this.chartData.datasets[0].borderColor);
    this.gradient.addColorStop(0, `rgba(${r}, ${g}, ${b}, 0.2)`);
    this.gradient.addColorStop(1, 'rgba(255,255,255, 0)');

    this.renderChart(
      this.chartData.datasets.length < 2 ? {
        ...this.chartData,
        datasets: [
          {
            ...this.chartData.datasets[0],
            backgroundColor: this.gradient,
            pointRadius: 2,
            borderWidth: 2,
          },
        ],
      } : {
        ...this.chartData,
        datasets: this.chartData.datasets.map((d) => {
          const gradient = this.$refs.canvas.getContext('2d').createLinearGradient(900, 0, 900, 400);
          // use border color for gradient backround
          const { r: red, g: green, b: blue } = hexToRgb(d.borderColor);
          gradient.addColorStop(0, `rgba(${red}, ${green}, ${blue}, 0.2)`);
          gradient.addColorStop(1, 'rgba(255,255,255, 0)');

          // const lighter = `rgba(${red}, ${green}, ${blue}, 0.075)`;
          return {
            ...d,
            backgroundColor: gradient,
            pointRadius: 2,
            borderWidth: 2,
          };
        }),
      },
      this.options,
    );
  },
  methods: {
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      } : {
        r: 255,
        g: 255,
        b: 255,
      };
    },
  },
};
