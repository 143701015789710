<template>
  <div>
    <div v-if="!saving" class="page">
      <header class="header">
        <div class="top">
          <h1>Compare Data</h1>
          <div class="btn-container">
            <k-button
              variant="tertiary"
              @click="
                reset();
                tab = '1';
              "
              >Reset</k-button
            >
            <k-button negative variant="secondary" @click="$router.back()"> Cancel </k-button>
          </div>
        </div>
        <div class="controls">
          <div class="options">
            <k-input
              v-model="country"
              :optionsDisplay="countries"
              filterInside
              label="Country"
              searchInside="countries"
              type="select"
            />
            <k-input
              v-model="category"
              :optionsDisplay="categories"
              label="Category"
              searchInside="categories"
              type="select"
              @search="(val) => debounce(fetchIndicators, 500)({ name: val })"
            />
            <k-input
              v-model="dataset.indicator"
              :optionsDisplay="indicators"
              label="Indicator"
              searchInside="indicators"
              type="select"
              @search="(val) => debounce(fetchIndicators, 500)({ name: val })"
            />
            <k-input
              v-model="dataset.startYear"
              :optionsDisplay="yearsLessThan"
              label="Start Year"
              searchInside="start year"
              type="select"
            />
            <k-input
              v-model="dataset.endYear"
              :optionsDisplay="yearsGreaterThan"
              label="End Year"
              searchInside="end year"
              type="select"
            />
            <k-input
              v-model="dataset.colour"
              :optionsDisplay="colourOptions"
              label="Colour"
              type="select"
            />
          </div>
          <div class="sep">and</div>
          <div class="options">
            <k-input
              v-model="country2"
              :optionsDisplay="countries"
              label="Country"
              searchInside="countries"
              type="select"
            />
            <k-input
              v-model="category2"
              :optionsDisplay="categories"
              label="Category"
              searchInside="categories"
              type="select"
              @search="(val) => debounce(fetchIndicators, 500)({ name: val })"
            />
            <k-input
              v-model="dataset2.indicator"
              :optionsDisplay="indicators2"
              label="Indicator"
              searchInside="indicators"
              type="select"
              @search="(val) => debounce(fetchIndicators, 500)({ name: val })"
            />
            <k-input
              v-model="dataset2.startYear"
              :optionsDisplay="set2YearsLessThan"
              label="Start Year"
              searchInside="start year"
              type="select"
            />
            <k-input
              v-model="dataset2.endYear"
              :optionsDisplay="set2YearsGreaterThan"
              label="End Year"
              searchInside="end year"
              type="select"
            />
            <k-input
              v-model="dataset2.colour"
              :optionsDisplay="colourOptions"
              label="Colour"
              type="select"
            />
          </div>
        </div>
        <div class="controls mid">
          <div class="tab-container">
            <div :class="['tab', { active: tab === '1' }]" @click="tab = '1'">Data 01</div>
            <div :class="['tab', { active: tab === '2' }]" @click="tab = '2'">Data 02</div>
          </div>

          <div class="options-container">
            <div v-if="tab === '1'" class="options">
              <k-input
                v-model="country"
                :optionsDisplay="countries"
                filterInside
                label="Country"
                searchInside="countries"
                type="select"
              />
              <k-input
                v-model="category"
                :optionsDisplay="categories"
                label="Category"
                searchInside="categories"
                type="select"
                @search="(val) => debounce(fetchIndicators, 500)({ name: val })"
              />
              <k-input
                v-model="dataset.indicator"
                :optionsDisplay="indicators"
                label="Indicator"
                searchInside="indicators"
                type="select"
                @search="(val) => debounce(fetchIndicators, 500)({ name: val })"
              />
              <k-input
                v-model="dataset.startYear"
                :optionsDisplay="yearsLessThan"
                label="Start Year"
                searchInside="start year"
                type="select"
              />
              <k-input
                v-model="dataset.endYear"
                :optionsDisplay="yearsGreaterThan"
                label="End Year"
                searchInside="end year"
                type="select"
              />
              <k-input
                v-model="dataset.colour"
                :optionsDisplay="colourOptions"
                label="Colour"
                type="select"
              />
            </div>
            <div v-else-if="tab === '2'" class="options">
              <k-input
                v-model="country2"
                :optionsDisplay="countries"
                label="Country"
                searchInside="countries"
                type="select"
              />
              <k-input
                v-model="category2"
                :optionsDisplay="categories"
                label="Category"
                searchInside="categories"
                type="select"
                @search="(val) => debounce(fetchIndicators, 500)({ name: val })"
              />
              <k-input
                v-model="dataset2.indicator"
                :optionsDisplay="indicators2"
                label="Indicator"
                searchInside="indicators"
                type="select"
                @search="(val) => debounce(fetchIndicators, 500)({ name: val })"
              />
              <k-input
                v-model="dataset2.startYear"
                :optionsDisplay="set2YearsLessThan"
                label="Start Year"
                searchInside="start year"
                type="select"
              />
              <k-input
                v-model="dataset2.endYear"
                :optionsDisplay="set2YearsGreaterThan"
                label="End Year"
                searchInside="end year"
                type="select"
              />
              <k-input
                v-model="dataset2.colour"
                :optionsDisplay="colourOptions"
                label="Colour"
                type="select"
              />
            </div>
          </div>
        </div>
      </header>
      <div class="main">
        <div ref="chart" :class="['chart', { suspense: isLoading }]">
          <template v-if="!isLoading">
            <div class="chart__wrapper">
              <div
                :class="[
                  'color-box color-box--left',
                  `color-box--${dataset.colour && dataset.colour.slice(1)}`,
                ]"
              ></div>
              <div
                :class="[
                  'color-box color-box--right',
                  `color-box--${dataset.colour && dataset2.colour.slice(1)}`,
                ]"
              ></div>

              <div class="metric metric--left">
                {{ dataset.metric }} {{ dataset.currency }}
                <div class="title">
                  <span>
                    {{ dataset.country }}
                  </span>
                  {{ dataset.indicator }}
                </div>
              </div>
              <template v-if="show">
                <line-wrapper
                  v-if="chartType === 'line'"
                  :datasets="chartDataLocal.datasets"
                  :labels="chartDataLocal.labels"
                  :height="130"
                />
                <bar-chart-wrapper
                  v-else
                  :datasets="chartDataLocal.datasets"
                  :labels="chartDataLocal.labels"
                  :height="130"
                />
              </template>
              <div class="metric metric--right">
                {{ dataset2.metric }} {{ dataset2.currency }}
                <div class="title">
                  <span>
                    {{ dataset2.country }}
                  </span>
                  {{ dataset2.indicator }}
                </div>
              </div>
            </div>
          </template>
          <div
            ref="definition"
            :style="{
              visibility: isLoading || !dataset.indicatorDefinition ? 'hidden' : 'visible',
            }"
            class="hover-helper"
          >
            <!--          <div class='name'>{{ titleCase(dataset.indicator) }}</div>-->
            <template v-if="dataset.data && dataset.data.length">
              <div :class="['definition', { shown: dataset.indicatorDefinition }]">
                {{ dataset.indicatorDefinition }}
              </div>
            </template>
          </div>
          <div
            ref="definition2"
            :style="{
              visibility: isLoading || !dataset2.indicatorDefinition ? 'hidden' : 'visible',
            }"
            class="hover-helper"
          >
            <!--          <div class='name'>{{ titleCase(dataset2.indicator) }}</div>-->
            <template v-if="dataset2.data && dataset2.data.length && dataset2.indicatorDefinition">
              <div :class="['definition', { shown: dataset2.indicatorDefinition }]">
                {{ dataset2.indicatorDefinition }}
              </div>
            </template>
          </div>
        </div>
        <div class="more-info">
          <div class="meta">
            <div class="meta__content legend">
              <h3>LEGEND</h3>
              <div class="label">
                <div
                  :class="['color-box', `color-box--${dataset.colour && dataset.colour.slice(1)}`]"
                ></div>
                <div class="name">
                  {{ titleCase(dataset.country) }}: {{ titleCase(dataset.indicator) }}
                </div>
              </div>
              <div class="label">
                <div
                  :class="[
                    'color-box',
                    `color-box--${dataset2.colour && dataset2.colour.slice(1)}`,
                  ]"
                ></div>
                <div class="name">
                  {{ titleCase(dataset2.country) }}: {{ titleCase(dataset2.indicator) }}
                </div>
              </div>
            </div>
            <div class="meta__content">
              <h3>Source</h3>
              <p>
                {{ dataset.source
                }}<span v-if="dataset2.source && dataset2.source !== dataset.source"
                  >, {{ dataset2.source }}
                </span>
              </p>
              <p style="font-weight: 600">Disclaimer: Graph is not up to scale</p>
              <div class="actions">
                <k-button short variant="tertiary" @click="showShareModal = true">Share</k-button>
                <k-button short variant="tertiary" @click="showEmbedModal = true">Embed</k-button>
                <k-button
                  short
                  variant="tertiary"
                  @click="
                    showDownloadModal = true;
                    generate();
                  "
                >
                  Download
                </k-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="editing" class="overlay" @click="editing = false"></div>
      <transition name="slide-left">
        <div v-if="editing" class="edit-options">
          <div class="close-btn" @click="editing = false">
            <svg
              fill="none"
              height="14"
              viewBox="0 0 14 14"
              width="14"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path :d="xPath" fill="black" />
            </svg>
          </div>

          <div class="tab-container">
            <div :class="['tab', { active: tab === '1' }]" @click="tab = '1'">Data 01</div>
            <div :class="['tab', { active: tab === '2' }]" @click="tab = '2'">Data 02</div>
          </div>

          <div class="options-container">
            <div v-if="tab === '1'" class="options">
              <k-input
                v-model="country"
                :optionsDisplay="countries"
                filterInside
                label="Country"
                searchInside="countries"
                type="select"
              />
              <k-input
                v-model="category"
                :optionsDisplay="categories"
                label="Category"
                searchInside="categories"
                type="select"
                @search="(val) => debounce(fetchIndicators, 500)({ name: val })"
              />
              <k-input
                v-model="dataset.indicator"
                :optionsDisplay="indicators"
                label="Indicator"
                searchInside="indicators"
                type="select"
                @search="(val) => debounce(fetchIndicators, 500)({ name: val })"
              />
              <k-input
                v-model="dataset.startYear"
                :optionsDisplay="yearsLessThan"
                label="Start Year"
                searchInside="start year"
                type="select"
              />
              <k-input
                v-model="dataset.endYear"
                :optionsDisplay="yearsGreaterThan"
                label="End Year"
                searchInside="end year"
                type="select"
              />
              <k-input
                v-model="dataset.colour"
                :optionsDisplay="colourOptions"
                label="Colour"
                type="select"
              />
            </div>
            <div v-else-if="tab === '2'" class="options">
              <k-input
                v-model="country2"
                :optionsDisplay="countries"
                label="Country"
                searchInside="countries"
                type="select"
              />
              <k-input
                v-model="category2"
                :optionsDisplay="categories"
                label="Category"
                searchInside="categories"
                type="select"
                @search="(val) => debounce(fetchIndicators, 500)({ name: val })"
              />
              <k-input
                v-model="dataset2.indicator"
                :optionsDisplay="indicators2"
                label="Indicator"
                searchInside="indicators"
                type="select"
                @search="(val) => debounce(fetchIndicators, 500)({ name: val })"
              />
              <k-input
                v-model="dataset2.startYear"
                :optionsDisplay="set2YearsLessThan"
                label="Start Year"
                searchInside="start year"
                type="select"
              />
              <k-input
                v-model="dataset2.endYear"
                :optionsDisplay="set2YearsGreaterThan"
                label="End Year"
                searchInside="end year"
                type="select"
              />
              <k-input
                v-model="dataset2.colour"
                :optionsDisplay="colourOptions"
                label="Colour"
                type="select"
              />
            </div>
          </div>
        </div>
      </transition>

      <div class="mobile-view-controls">
        <k-button
          mobile
          variant="tertiary"
          @click="
            $router.push({
              name: 'SingleResult',
              params: {
                indicator: initial.indicator,
                country: initial.country,
                id: initial.id,
              },
              query: {
                colour: $route.query.colour,
                startYear: $route.query.startYear,
                endYear: $route.query.endYear,
              },
            })
          "
        >
          Cancel</k-button
        >
        <k-button mobile variant="secondary" @click="editing = true"> Edit settings </k-button>
      </div>

      <!-- Share MODAL -->
      <k-modal :open="showShareModal" middle @close="showShareModal = false">
        <k-card heading="Share with others" padInside smaller variant="in-modal" wrapHeading>
          <p class="modal-paragraph share">
            Anyone with the link can immediately view the page on their web browser.
          </p>
          <k-input :value="shareLink" disabled label="" variant="copyInput"></k-input>
          <div class="btn-container">
            <a
              :href="`https://twitter.com/share?ref_src=twsrc%5Etfw&url=${encodeURIComponent(
                shareLink
              )}`"
              rel="noreferrer"
              target="_blank"
            >
              <k-button variant="tertiary">Twitter</k-button>
            </a>
            <a
              :href="`https://wa.me?text=${encodeURIComponent(shareLink)}`"
              rel="noreferrer"
              target="_blank"
            >
              <k-button variant="tertiary">Whatsapp</k-button>
            </a>
            <a
              :href="`mailto:?body=${encodeURIComponent(shareLink)}`"
              rel="noreferrer"
              target="_blank"
            >
              <k-button variant="tertiary">Email</k-button>
            </a>
          </div>
          <div class="cta">
            <k-button variant="secondary" @click="showShareModal = false">Close</k-button>
          </div>
        </k-card>
      </k-modal>

      <!-- Embed MODAL -->
      <k-modal :open="showEmbedModal" middle @close="showEmbedModal = false">
        <k-card heading="Embed HTML" padInside smaller variant="in-modal" wrapHeading>
          <p class="modal-paragraph">
            Copy and paste the code below to embed this chart on any page.
          </p>
          <k-input :value="embedString" disabled label="" variant="copyInput"> </k-input>
          <div class="btn-container embed">
            <k-input v-model="embedWidth" label="Width"></k-input>
            <k-input v-model="embedHeight" label="Height"></k-input>
          </div>

          <div class="cta">
            <k-button variant="secondary" @click="showEmbedModal = false">Close</k-button>
          </div>
        </k-card>
      </k-modal>

      <!-- Download MODAL -->
      <k-modal :open="showDownloadModal" middle @close="showDownloadModal = false">
        <k-card heading="Save to Your Computer" padInside smaller variant="in-modal">
          <p>Save a copy of this data to your computer in PDF, CSV or PNG.</p>
          <div class="btn-container download">
            <k-button :loading="isSavingCsv" variant="tertiary" @click="saveAsCsv">as CSV</k-button>
            <k-button
              variant="tertiary"
              @click="
                showDownloadModal = false;
                saving = 'pdf';
              "
              >as PDF
            </k-button>
            <k-button
              variant="tertiary"
              @click="
                showDownloadModal = false;
                saving = 'png';
              "
              >as PNG
            </k-button>
          </div>
          <div class="cta">
            <k-button variant="secondary" @click="showDownloadModal = false">Close</k-button>
          </div>
        </k-card>
      </k-modal>

      <!-- Not Found MODAL -->
      <k-modal :open="notFound" middle @close="notFound = false">
        <k-card heading="" padInside smaller variant="in-modal">
          <div class="img-container">
            <img alt="" src="@/assets/attention.svg" />
          </div>
          <h1 class="modal__heading">Sorry! There’s no dataset available for your selection.</h1>
          <k-button size="full" @click="notFound = false">Go Back </k-button>
        </k-card>
      </k-modal>
    </div>
    <template v-else-if="saving === 'png'">
      <save-as-png :options="{ ...dataset, dataset2 }" @back="cancelSaving"></save-as-png>
    </template>
    <template v-else-if="saving === 'pdf'">
      <save-as-pdf
        :options="{
          ...dataset,
          analysis,
          dataset2: { ...dataset2, analysis: set2Analysis },
          chartType,
        }"
        @back="cancelSaving"
      >
      </save-as-pdf>
    </template>
  </div>
</template>

<script>
import CompareData from './CompareData';

export default CompareData;
</script>

<style lang="scss" scoped src="./CompareData.scss"></style>
