import { saveAs } from 'file-saver';
import { required } from 'vuelidate/lib/validators';
import BarChartWrapper from '@/components/Charts/BarChartWrapper.vue';
// eslint-disable-next-line
import { KButton, KInput, KModal, KCard } from '@/components';
// eslint-disable-next-line
import { downloadDataset, fetchCountryData, sendCustomRequest, updateFoundCountry } from '@/api';
// eslint-disable-next-line
import { dashboardTemplate, getYear } from '@/views/Search/Save/downloadTemplate';
import GetLatestWithValue from '@/mixins/GetLatestWithValue';
import countries from '@/utils/countries';
import stringHelpers from '@/utils/string-helpers';
import frequency from '@/utils/frequency';
import numberOr from '@/utils/numberOr';

export default {
  name: 'Dashboard',
  components: {
    BarChartWrapper,
    KButton,
    KInput,
    KModal,
    KCard,
  },
  mixins: [GetLatestWithValue],
  data: () => ({
    country: '',
    countries,
    openModal: false,
    request: {
      fullName: '',
      email: '',
      country: '',
      extraNotes: '',
    },
    isLoading: false,
    resources: {},
    dashboard: {},
    hovering: {},
    isFetching: true,
    mobileRequest: false,
    sendingRequest: false,
    notFound: false,
    isDownloading: false,
    touched: {},
    error: {},
    general_error: '',
  }),
  validations() {
    return {
      request: {
        fullName: { required },
        email: { required },
        country: { required },
        extraNotes: { required },
      },
    };
  },
  async created() {
    const { country } = this.$route.params;
    this.country = country;
  },
  watch: {
    async country(val) {
      await this.getCountryData({ country: val });
      if (val && val !== this.$route.params.country) {
        this.$router.push({ name: 'CountryDashboard', params: { country: val } });
      }
    },
  },
  computed: {
    latest() {
      if (!this.hovering.values) return { period: '', value: '' };
      return this.periodWithValue(this.hovering.values.yearly, this.hovering.values.yearly.length);
    },
  },
  methods: {
    ...stringHelpers,
    getYear(val) {
      return getYear(val);
    },
    follow({ clientY: y, clientX: x }) {
      const { charts } = this.$refs;
      const hoverHelper = charts.querySelector('.hover-helper');

      hoverHelper.style.top = `${y}px`;
      hoverHelper.style.left = `${x}px`;
    },
    async getCountryData({ country: paramCountry = '' }) {
      const { country } = this;
      try {
        this.isFetching = true;
        const response = await fetchCountryData({ country: paramCountry || country });
        console.log('this is the response', response);
        if (response.data.data.name) updateFoundCountry(response.data.data.visitId);
        this.dashboard = response.data.data;
        if (!response.data.data.name) {
          this.notFound = true;
          this.dashboard = {};
        }
      } catch (error) {
        this.$toast.show({ message: error });
      } finally {
        this.isFetching = false;
      }
    },
    async sendRequest() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        this.sendingRequest = true;
        const { fullName, country, extraNotes } = this.request;
        const response = await sendCustomRequest({
          fullName,
          country,
          extraNotes,
        });
        this.$toast.show({ message: response.data.message });
        this.mobileRequest = false;
        this.reset();
      } catch (e) {
        if (e.response) {
          this.$toast.show({ message: e.response.data.message });
        } else {
          this.$toast.show({ message: e.message });
        }
      } finally {
        this.reset();
        this.sendingRequest = false;
        this.openModal = false;
        this.mobileRequest = false;
      }
    },
    reset() {
      this.$v.$reset();
      const { request } = this;
      request.fullName = '';
      request.email = '';
      request.country = '';
      request.extraNotes = '';
      this.openModal = false;
      this.mobileRequest = false;
    },
    getChartData(dataset) {
      let values = dataset.data.map((item) => ({
        ...item,
        value: item.value.replace(/,|\s/g, ''),
      }));
      values = values.filter((item) => {
        const moreThan = getYear(item.period) >= getYear(dataset.startYear);
        const lessThan = getYear(item.period) <= getYear(dataset.endYear);
        return moreThan && lessThan;
      });

      return {
        labels: values && values.map((item) => item.period),
        datasets: [
          {
            data: values && values.map(numberOr()),
            backgroundColor: '#900F7B',
            pointBackgroundColor: '#900F7B',
            borderColor: '#900F7B',
            pointHitRadius: 4,
            small: true,
          },
        ],
      };
    },
    async download() {
      const { charts: chartsRef } = this.$refs;
      const {
        name,
        resource,
        selectedIndicators: indicators,
        selectedCharts: charts,
      } = this.dashboard;
      const options = {};
      options.title = `${this.titleCase(name)}: Country Dashboard`;
      options.selectedIndicators = indicators.map((i) => {
        const chosen = this.periodWithValue(i.data, i.data.length);
        return {
          ...i,
          data: { ...chosen, value: this.withUnit(chosen.value, i) },
        };
      });
      const canvases = chartsRef.querySelectorAll('canvas');
      options.selectedCharts = await Promise.all(
        charts.map(async (c, index) => ({
          ...c,
          // eslint-disable-next-line
          image: await canvases[index].toDataURL(),
          // eslint-disable-next-line
        }))
      );
      options.resource = resource;

      const template = dashboardTemplate(options);
      const htmlBlob = new Blob([template], { type: 'text/plain' });
      const htmlFile = new File([htmlBlob], 'template.txt');

      const formData = new FormData();
      formData.append('file', htmlFile);
      try {
        this.isDownloading = true;
        const response = await downloadDataset({ data: formData, type: 'pdf' });
        const responseBlob = new Blob([response.data], { type: 'application/pdf' });
        const fileName = `${this.titleCase(name)}_Country Dashboard.pdf`;
        saveAs(await responseBlob, fileName);
        this.$toast.show({ message: `Downloaded ${fileName}` });
      } catch (e) {
        this.$toast.show({ message: e.message });
      } finally {
        this.isDownloading = false;
      }
    },
    setHovering(item) {
      const values = frequency(item.data, item.frequency);
      this.hovering = { ...item, values };
    },
  },
};
