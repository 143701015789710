const getYear = (val) => val.match(/\d+$/)[0];

const twoDecimal = (val) => parseFloat(val.toFixed(2));

const getKey = (period, f) => {
  const q1 = /jan|feb|mar|q1/i;
  const q2 = /apr|may|jun|q2/i;
  const q3 = /jul|aug|sep|q3/i;
  const q4 = /oct|nov|dec|q4/i;
  let key = period;

  if (f === 'quarterly') {
    key = `${(period.match(q1) && 'Q1')
    || (period.match(q2) && 'Q2')
    || (period.match(q3) && 'Q3')
    || (period.match(q4) && 'Q4')
    }-${getYear(period)}`;
    return key;
  }

  if (f === 'yearly') {
    key = `${getYear(period)}`;
    return key;
  }

  return key;
};

export default (data, freq = 'yearly', percent = false) => {
  let frequencies = ['monthly', 'quarterly', 'yearly'];
  const container = {};
  const startIndex = frequencies.indexOf(freq);

  frequencies = frequencies.slice(startIndex);

  frequencies.forEach((f) => {
    let key = '';
    let prevKey = '';
    let total = 0;
    let count = 0;

    data.forEach(({
      period,
      value,
    }, i) => {
      key = getKey(period, f);
      total = twoDecimal(total);
      const parsedVal = parseFloat(value.replaceAll(',', '')) || 0;

      if (!container[f]) container[f] = [];

      if (i !== data.length - 1) {
        if (key !== prevKey) {
          if (prevKey) {
            container[f] = [...container[f], {
              period: prevKey,
              value: percent ? total / count : total,
            }];
          }
          total = parsedVal;
          count = 1;
          prevKey = key;
        } else {
          total += parsedVal;
          count += 1;
        }
      } else if (key !== prevKey) {
        container[f].push({
          period: prevKey,
          value: percent ? total / count : total,
        });
        container[f].push({
          period: key,
          value: parsedVal,
        });
      } else {
        total += parsedVal;
        count += 1;
        container[f].push({
          period: prevKey,
          value: percent ? total / count : total,
        });
      }
    });
  });

  return container;
};
