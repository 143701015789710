import KFooter from '../../WebsiteFooter.vue';
import { storyblok } from '@/config';
import DateFormat from '@/mixins/DateFormat';
import convert from '@/utils/RichtextToHTML';
import { getSinglePost } from '@/api/blog';

export default {
  name: 'SingleBlog',
  components: {
    KFooter,
  },
  mixins: [DateFormat],
  data: () => ({
    post: {},
    author: {},
    isLoading: true,
  }),
  computed: {
    title() {
      return this.post.name && this.post.name;
    },
    image() {
      return `https${this.post.content && this.post.content.image}`;
    },
    description() {
      return `${this.post.body && this.post.body.textContent.slice(0, 140)}...`;
    },
  },
  head: {
    title() {
      return { inner: this.title || 'Blog Post' };
    },
    meta() {
      if (!this.post.content) return [];
      return [
        { property: 'og:url', content: window.location.href },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description },
        { property: 'og:image', content: this.image },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:domain', content: window.location.hostname },
        { property: 'twitter:url', content: window.location.href },
        { name: 'twitter:title', content: this.title },
        { name: 'twitter:description', content: this.description },
        { name: 'twitter:image', content: this.image },
      ];
    },
  },
  watch: {
    post: {
      deep: true,
      handler() {
        this.$emit('updateHead');
      },
    },
  },
  async mounted() {
    const { $route: { params } } = this;
    const { slug } = params;
    this.isLoading = true;
    const post = await getSinglePost({ slug });
    const uuid = post.content.author;
    const author = {};
    if (uuid && typeof uuid === 'string') {
      await storyblok.get(`/stories/${uuid}?find_by=uuid`).then(({ data: { story } }) => {
        author.name = story.content.name;
        author.image = story.content.image;
        post.content.author = author;
      });
    }
    this.isLoading = false;

    post.body = this.convert(post.content.long_text);
    this.post = post;
  },
  methods: {
    convert,
  },
};
