<template>
  <div class="container content" ref="mainContainer">
    <section class="why" ref="container">
      <h2 ref="text">Why we exist</h2>
      <p ref="subText">
        The world is in need of data services, accompanied by fluid and seamless experience to make
        core decisions.
      </p>
    </section>

    <section class="mission space-above" ref="mission">
      <h2 ref="text1">Our Mission</h2>
      <p ref="text2">
        Kwerty is focused on building the world’s largest public data engine, layered with
        artificial intelligence, thereby enabling improved user experiences and actionable analysis.
        The platform leverages multiple global open datasets and uses a clean interactive view to
        provide actionable data to users.
      </p>
      <p ref="text3">
        Kwerty’s mission includes focus on the ecosystem which includes strengthening institutions
        especially their statistical capabilities and also improving their abilities to accurately
        analyse, collect and visualize data. This is enabled through world class training for
        leaders in both public and private sectors and also defining abilities to adapt their data
        stacks to machine learning and artificial intelligence. We also deliver world class training
        pipelines for data analysts, engineers and visualization professionals to deepen use of data
        for public good and private opportunities.
      </p>
    </section>

    <section class="team space-above" ref="theTeam">
      <h2 ref="teamHeader">The Team</h2>
      <p ref="teamSubHeader">
        We’re a small team of data professionals, designers, product managers and marketers.
      </p>
      <ul class="team__list" ref="Member">
        <li v-for="member in team" :key="member.name" ref="eachMember">
          <img :alt="member.name" :src="member.image" ref="memberImage" />
          <p class="name" ref="memberName">{{ member.name }}</p>
          <div class="position" ref="memberPosition">{{ member.position }}</div>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import founderImg from '@/assets/Oluseun Onigbinde_Founder.jpg';
import coFounderImg from '@/assets/Kehinde Oseni_Co-founder.jpg';
import dataLeadImg from '@/assets/Oluwaseun Bukky Afolabi_Data Lead.jpg';
import dataAnalyst1 from '@/assets/Mayowa Agunloye_Data Analyst.jpg';
import dataAnalyst2 from '@/assets/Oludiran Akinkunmi_Data Analyst.jpg';
import dataAnalyst3 from '@/assets/Mariam Adeyela_Data Analyst.jpg';
import dataAnalyst4 from '@/assets/Damilola Alex-Aina_ Data Analyst.jpg';
import dataAnalyst5 from '@/assets/Benedict Opara_Data Analyst.jpg';
import dataAnalyst6 from '@/assets/Oluseyi Onigbinde_Data Analyst.jpg';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'AboutContent',
  data() {
    return {
      team: [
        {
          name: 'Oluseun Onigbinde',
          position: 'CEO / Founder',
          image: founderImg,
        },
        {
          name: 'Kehinde Oseni',
          position: 'Co-founder',
          image: coFounderImg,
        },
        {
          name: 'Oluwaseun Bukky Afolabi',
          position: 'Data Lead',
          image: dataLeadImg,
        },
        {
          name: 'Mayowa Agunloye',
          position: 'Data Analyst',
          image: dataAnalyst1,
        },
        {
          name: 'Oludiran Akinkunmi',
          position: 'Data Analyst',
          image: dataAnalyst2,
        },
        {
          name: 'Maria Adetunji',
          position: 'Data Analyst',
          image: dataAnalyst3,
        },
        {
          name: 'Damilola Alex-Aina',
          position: 'Data Analyst',
          image: dataAnalyst4,
        },
        {
          name: 'Benedict Opara',
          position: 'Data Analyst',
          image: dataAnalyst5,
        },
        {
          name: 'Oluseyi Onigbinde',
          position: 'Data Analyst',
          image: dataAnalyst6,
        },
      ],
    };
  },
  mounted() {
    const {
      text,
      subText,
      text1,
      text2,
      text3,
      mainContainer,
      container,
      mission,
      theTeam,
      teamHeader,
      teamSubHeader,
      Member,
    } = this.$refs;

    const enterEffect = {
      opacity: 0,
      y: 50,
      duration: 0.5,
      ease: 'ease.in',
    };

    const Content = gsap.timeline({
      scrollTrigger: {
        trigger: mainContainer,
        start: '0 95%',
        toggleActions: 'play none none none',
      },
    });

    const ContentMission = gsap.timeline({
      scrollTrigger: {
        trigger: mission,
        start: '0 95%',
        toggleActions: 'play none none none',
      },
    });

    const Team = gsap.timeline({
      scrollTrigger: {
        trigger: theTeam,
        start: '0 95%',
        toggleActions: 'play none none none',
      },
    });

    const Members = gsap.timeline({
      scrollTrigger: {
        trigger: Member,
        start: '0 95%',
        toggleActions: 'play none none none',
      },
    });

    Content.from(container, { ...enterEffect }, '-=0.3');
    Content.from(text, { ...enterEffect });
    Content.from(subText, { ...enterEffect });
    ContentMission.from(text1, { ...enterEffect });
    ContentMission.from(text2, { ...enterEffect });
    ContentMission.from(text3, { ...enterEffect });
    Team.from(teamHeader, { ...enterEffect });
    Team.from(teamSubHeader, { ...enterEffect });
    const members = Member.querySelectorAll('li');
    Array.from(members).forEach((member) => {
      Members.from(member, {
        opacity: 0,
        y: 60,
        ease: 'ease.out',
      }, '<+0.1');
    });
  },
};
</script>

<style lang='scss' scoped src='./About.scss'>
</style>
